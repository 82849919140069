import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import PropTypes from 'prop-types'

const EventTypeSelectField = ({
  label,
  options,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
  changeHandler,
}) => (
  <TextField
    select
    label={label}
    value={value}
    onChange={(x) => {
      onChange(x)
      changeHandler(x.target.value)
    }}
    error={invalid}
    helperText={invalid ? errorMessage : ' '}
    InputLabelProps={{
      className: required ? 'required-label' : '',
      required: required || false,
    }}
    fullWidth={true}
  >
    {!required && (
      <MenuItem key={''} value={''}>
        &nbsp;
      </MenuItem>
    )}
    {options.map((option) => (
      <MenuItem key={option.id} value={option.id}>
        {option.name}
      </MenuItem>
    ))}
  </TextField>
)

EventTypeSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  changeHandler: PropTypes.func,
}

const FormEventTypeSelect = ({
  name,
  label,
  options,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = '',
  changeHandler,
}) => {
  const { control } = useFormContext()
  return (
    <>
      <Controller
        render={({ onChange, value }, { invalid }) => (
          <EventTypeSelectField
            label={label}
            options={options}
            value={value}
            onChange={onChange}
            required={required}
            invalid={invalid}
            errorMessage={errorMessage}
            changeHandler={changeHandler}
          />
        )}
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
      />
    </>
  )
}

FormEventTypeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  changeHandler: PropTypes.func,
}

export default FormEventTypeSelect

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormTable from './FormTable'
import { Button, makeStyles } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const columns = [
  {
    header: 'Issue',
    accessor: 'issue',
  },
  {
    header: 'Plate',
    accessor: 'plateNumber',
  },
  {
    header: 'State',
    accessor: 'state',
  },
  {
    header: 'Make',
    accessor: 'make',
  },
  {
    header: 'Model',
    accessor: 'model',
  },
  {
    header: 'Stickered',
    accessor: 'stickered',
  },
]

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const VehiclesFields = ({ openDialog, list = [] }) => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader sectionName='Vehicles' />
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={12}>
          <FormTable
            name={'event.vehicles'}
            columns={columns}
            data={list}
            handleOpen={openDialog}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => openDialog()} startIcon={<AddCircleIcon />}>
            Add a vehicle
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

VehiclesFields.propTypes = {
  openDialog: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      issue: PropTypes.string,
      plateNumber: PropTypes.string,
      state: PropTypes.number,
      year: PropTypes.string,
      make: PropTypes.string,
      style: PropTypes.style,
      vinNumber: PropTypes.string,
      stickered: PropTypes.bool,
      timeStickered: PropTypes.instanceOf(Date),
      towed: PropTypes.bool,
      timeTowed: PropTypes.instanceOf(Date),
      legallyParked: PropTypes.bool,
      illegalParkingDescription: PropTypes.string,
    })
  ).isRequired,
}

export default VehiclesFields

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as sectionApi from '../../apiCalls/section'

const initialState = {
  status: apiStatus.idle,
  error: null,
  sections: [],
}

const sectionSlice = createSlice({
  name: 'sections',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadSectionsSucceeded(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, sections: payload }
    },
    updateSectionSucceeded(state, { payload }) {
      let updatedSections = state.sections.map((section) => {
        if (section.id != payload.id) {
          return section
        }
        return {
          ...section,
          ...payload,
        }
      })

      return {
        ...state,
        sections: updatedSections,
        status: apiStatus.succeeded,
      }
    },
    addSectionSucceeded(state, { payload }) {
      let updatedSections = [...state.sections, payload]
      return {
        ...state,
        sections: updatedSections,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadSectionsSucceeded,
  updateSectionSucceeded,
  addSectionSucceeded,
} = sectionSlice.actions

export const loadSectionsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await sectionApi
    .getSections()
    .then((sections) => {
      dispatch(loadSectionsSucceeded(sections))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const updateSectionAsync = (section) => async (dispatch) => {
  dispatch(beginApiCall())
  return await sectionApi
    .updateSection(section)
    .then(() => dispatch(updateSectionSucceeded(section)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const addSectionAsync = (section) => async (dispatch) => {
  dispatch(beginApiCall())
  return await sectionApi
    .addSection(section)
    .then((sectionId) =>
      dispatch(addSectionSucceeded({ id: sectionId, ...section }))
    )
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const selectSections = (state) => state.sections

export const selectSectionsWithFilter = (
  active,
  propertyId,
  selectedSectionId = null
) => (state) => {
  var { sections } = selectSections(state)
  return {
    ...selectSections(state),
    sections: sections
      .filter(
        (s) =>
          (s.isActive == active && s.propertyId == propertyId) ||
          (s.propertyId == propertyId && s.id == selectedSectionId)
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export const selectSectionsWithMultiplePropFilter = (propertyIds) => (
  state
) => {
  var { sections } = selectSections(state)
  var filteredSections = new Array()
  propertyIds.map((propertyId) => {
    if (sections.find((s) => s.propertyId == propertyId) !== undefined) {
      if (filteredSections.length > 0) {
        filteredSections = filteredSections.concat(
          sections.filter((s) => s.propertyId == propertyId)
        )
      } else {
        filteredSections = sections.filter((s) => s.propertyId == propertyId)
      }
    }
  })
  return {
    ...selectSections(state),
    sections: filteredSections.sort((a, b) => {
      var x = a.name.toLowerCase()
      var y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    }),
  }
}

export const selectAllActiveSections = () => (state) => {
  var { sections } = selectSections(state)
  return {
    ...selectSections(state),
    sections: sections
      .filter((s) => s.isActive === true)
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default sectionSlice.reducer

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectSecondaryThemeColor } from '../../../admin/slices/themeColorSlice'

const PageHeader = ({ pageName }) => {
  const useStyles = makeStyles({
    pageHeader: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
      width: '100%',
      padding: '0.5em',
      fontSize: '1.5em',
    },
  })
  const classes = useStyles()

  return <div className={classes.pageHeader}>{pageName}</div>
}

PageHeader.propTypes = {
  pageName: PropTypes.string.isRequired,
}

export default PageHeader

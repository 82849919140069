import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

const InputField = ({
  label,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
  disabled,
}) => (
  <TextField
    label={label}
    value={value}
    onChange={onChange}
    error={invalid}
    helperText={invalid ? errorMessage : ' '}
    InputLabelProps={{
      className: required ? 'required-label' : '',
      required: required || false,
    }}
    fullWidth={true}
    disabled={disabled}
  />
)

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

const FormInput = ({
  name,
  label,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = '',
  disabled = false,
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ onChange, value }, { invalid }) => (
        <InputField
          label={label}
          value={value}
          onChange={onChange}
          required={required}
          invalid={invalid}
          errorMessage={errorMessage}
          disabled={disabled}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
}

export default FormInput

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/tenant'

export async function getTenants() {
  return fetch(baseUrl, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export async function importTenantsFromExcelFile(propertyId, file) {
  const formData = new FormData()
  formData.append('tenantFile', file)
  return fetch(baseUrl + '/import/' + propertyId, {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
    },
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError)
}

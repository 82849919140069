import React from 'react'
import PageHeader from '../../../common/components/organisms/PageHeader'
import { useSelector } from 'react-redux'
import ManageColorsAndImages from '../organisms/ManageColorsAndImages'
import { selectCurrentUserRole } from '../../../admin/slices/currentUserSlice'
import { roles } from '../../../common/constants'
import NoAccess from '../../../common/components/templates/NoAccess'

const ManageTheme = () => {
  const isAdmin = useSelector(selectCurrentUserRole()) === roles.admin
  return (
    <>
      <PageHeader pageName='Manage Theme' />
      {isAdmin ? (
        <ManageColorsAndImages />
      ) : (
        <NoAccess reason='(Role Access)' />
      )}
    </>
  )
}

export default ManageTheme

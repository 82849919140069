import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'
import FormEventTypeMultiSelect from '../atoms/FormEventTypeMultiSelect'
import DatePickerField from '../atoms/DatePickerField'
import { format, parseISO } from 'date-fns'
import {
  dateFormat,
  dateTimeFormat,
  timeFormat,
  days,
  dateTimeFormatForFileNames,
} from '../../../common/constants'
import OrderedTable from '../molecules/OrderedTable'
import {
  eventTypes,
  eventTypeEnum,
  apiStatusEnum as apiStatus,
} from '../../../common/enums'
import {
  MenuItem,
  TextField,
  Grid,
  makeStyles,
  Button,
  CircularProgress,
} from '@material-ui/core'

import { useDispatch, useSelector } from 'react-redux'
import {
  loadPropertiesAsync,
  selectPropertiesActiveFilter,
} from '../../../admin/slices/propertySlice'
import {
  selectSectionsWithMultiplePropFilter as selectSectionsWithFilter,
  loadSectionsAsync,
} from '../../../admin/slices/sectionSlice'
import {
  selectBuildingsWithMultipleSectionsFilter as selectBuildingsWithFilter,
  loadBuildingsAsync,
} from '../../../admin/slices/buildingSlice'
import {
  selectLocationsWithMultipleBuildingsFilter as selectLocationsWithFilter,
  loadLocationsAsync,
} from '../../../admin/slices/locationSlice'
import {
  loadCallTypesAsync,
  selectOrderedCallTypes,
} from '../../slices/callTypesSlice'
import {
  loadEventPlatoonsAsync,
  selectEventPlatoons,
} from '../../slices/eventPlatoonsSlice'
import * as SearchEventsApi from '../../../apiCalls/searchEvents'
import { useHistory, withRouter } from 'react-router-dom'
import {
  selectPrimaryThemeColor,
  selectTertiaryThemeColor,
} from '../../../admin/slices/themeColorSlice'
import { GetApp as DownloadIcon } from '@material-ui/icons'
import {
  selectCurrentUserDisplayName,
  selectCurrentUser,
  selectCurrentUserProperties,
} from '../../../admin/slices/currentUserSlice'
import ErrorDialog from '../../../common/components/molecules/ErrorDialog'

const generateStringFromArray = (array) => {
  if (array !== null) {
    return array.join(',')
  } else return ''
}

const SearchEvents = () => {
  const useStyles = makeStyles({
    grid: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      width: '95%',
    },
    button: {
      float: 'right',
      marginLeft: 16,
    },
    searchButton: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      color: 'white',
      float: 'right',
      marginLeft: 16,
    },
    downloadButton: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      float: 'right',
    },
    buttonProgress: {
      color: useSelector(selectPrimaryThemeColor()),
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })

  const history = useHistory()
  const classes = useStyles()
  const [searchButtonClickedForFirstTime, setSearchButtonClickedForFirstTime] =
    useState(false)
  const [selectedEventType, setEventType] = useState(1)
  const [selectedProperties, setProperties] = useState([])
  const [selectedSections, setSections] = useState([])
  const [selectedBuildings, setBuildings] = useState([])
  const [selectedLocations, setLocations] = useState([])
  const [error, setError] = useState(undefined)

  const setPorpertiesFilter = (properties) => {
    setLocations([])
    setBuildings([])
    setSections([])
    setProperties(properties)
  }

  const setSectionsFilter = (sections) => {
    setLocations([])
    setBuildings([])
    setSections(sections)
  }

  const setBuildingsFilter = (buildings) => {
    setLocations([])
    setBuildings(buildings)
  }

  const clearFilters = () => {
    setLocations([])
    setBuildings([])
    setSections([])
    setProperties([])
    setFromDate(null)
    setToDate(null)
    setCallTypes([])
    setSctFilter('All')
    setPreparedBy('')
    setSupervisorSignedFilter('All')
  }

  const [fromDate, setFromDate] = useState(new Date())
  const [toDate, setToDate] = useState(new Date())

  const [results, setResults] = useState([])

  const [selectedCallTypes, setCallTypes] = useState([])
  const [selectedPlatoons, setPlatoons] = useState([])

  const [sctFilter, setSctFilter] = useState('All')
  const sctOptions = ['All', 'Yes', 'No']

  const [supervisorSignedFilter, setSupervisorSignedFilter] = useState('All')
  const supervisorSignedOptions = ['All', 'Yes', 'No']

  const [preparedBy, setPreparedBy] = useState('')

  const currentUserSelector = useSelector(selectCurrentUser())
  const currentUserDisplayName = useSelector(selectCurrentUserDisplayName())

  let eventTypeList = {}
  if (currentUserSelector.currentUser.role === 'General User')
    eventTypeList = eventTypes.filter((t) => t.id != 3)
  else eventTypeList = eventTypes

  const propertySelector = useSelector(selectPropertiesActiveFilter(true))
  const currentUserProperties = useSelector(selectCurrentUserProperties())
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(selectedProperties)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(selectedSections)
  )
  const locationsSelector = useSelector(
    selectLocationsWithFilter(selectedBuildings)
  )
  const callTypes = useSelector(selectOrderedCallTypes)
  const platoons = useSelector(selectEventPlatoons)

  const [filterValues, setFilterValues] = useState({})

  const [loaded, setLoaded] = useState(true)
  const [reportDownloading, setReportDownloading] = useState(false)

  const search = () => {
    setSearchButtonClickedForFirstTime(true)
    setResults([])
    setLoaded(false)

    var filter = {}
    if (fromDate != null) {
      filter = { ...filter, fromDate: fromDate.toJSON() }
    }
    if (toDate != null) {
      filter = { ...filter, toDate: toDate.toJSON() }
    }
    filter = {
      ...filter,
      callTypeIds: generateStringFromArray(selectedCallTypes),
      propertyIds: generateStringFromArray(
        selectedProperties.length > 0
          ? selectedProperties
          : currentUserProperties.map((p) => p.id)
      ),
      sectionIds: generateStringFromArray(selectedSections),
      buildingIds: generateStringFromArray(selectedBuildings),
      locationIds: generateStringFromArray(selectedLocations),
      platoonIds: generateStringFromArray(selectedPlatoons),
      initiadedBySCT: sctFilter,
      preparedBy: preparedBy,
      approverSigned: supervisorSignedFilter,
    }

    setFilterValues(filter)
    if (selectedEventType == eventTypeEnum.callLog) {
      SearchEventsApi.getCallLogsByFilter(filter)
        .then((response) => {
          setResults(response)
        })
        .catch(() =>
          setError(
            'An error occured while searching. Please contact support if this issue persists.'
          )
        )
        .finally(() => setLoaded(true))
    } else if (selectedEventType == eventTypeEnum.incident) {
      SearchEventsApi.getIncidentsByFilter(filter)
        .then((response) => {
          setResults(response)
        })
        .catch(() =>
          setError(
            'An error occured while searching. Please contact support if this issue persists.'
          )
        )
        .finally(() => setLoaded(true))
    } else if (selectedEventType == eventTypeEnum.unusualIncident) {
      SearchEventsApi.getUnusualIncidentsByFilter(filter)
        .then((response) => {
          setResults(response)
        })
        .catch(() =>
          setError(
            'An error occured while searching. Please contact support if this issue persists.'
          )
        )
        .finally(() => setLoaded(true))
    } else if (selectedEventType == eventTypeEnum.accident) {
      SearchEventsApi.getAccidentsByFilter(filter)
        .then((response) => {
          setResults(response)
        })
        .catch(() =>
          setError(
            'An error occured while searching. Please contact support if this issue persists.'
          )
        )
        .finally(() => setLoaded(true))
    }
  }

  const select = (issueType, id) => {
    switch (issueType) {
      case 'Call Log':
        history.push('/event/' + eventTypeEnum.callLog + '/' + id)
        break
      case 'Incident':
        history.push('/event/' + eventTypeEnum.incident + '/' + id)
        break
      case 'Unusual Incident':
        history.push('/event/' + eventTypeEnum.unusualIncident + '/' + id)
        break
      case 'Accident':
        history.push('/event/' + eventTypeEnum.accident + '/' + id)
        break
    }
  }

  const downloadIncidentsReport = async () => {
    let incidentIds = [
      results.map((result) => {
        return result.id
      }),
    ]

    var filter = {}
    if (fromDate != null) {
      filter = { ...filter, fromDate: fromDate.toJSON() }
    }
    if (toDate != null) {
      filter = { ...filter, toDate: toDate.toJSON() }
    }
    filter = {
      ...filter,
      callTypeIds: generateStringFromArray(selectedCallTypes),
      propertyIds: generateStringFromArray(
        selectedProperties.length > 0
          ? selectedProperties
          : currentUserProperties.map((p) => p.id)
      ),
      sectionIds: generateStringFromArray(selectedSections),
      buildingIds: generateStringFromArray(selectedBuildings),
      locationIds: generateStringFromArray(selectedLocations),
      platoonIds: generateStringFromArray(selectedPlatoons),
      initiadedBySCT: sctFilter,
      preparedBy: preparedBy,
      approverSigned: supervisorSignedFilter,
    }

    SearchEventsApi.getIncidentsReport(
      {
        ...filter,
        title: 'Department of Public Safety',
        reportFromDate: fromDate.toJSON(),
        reportToDate: toDate.toJSON(),
        currentUser: currentUserDisplayName,
        dateTimeOffsetInMinutes: new Date().getTimezoneOffset(),
      },
      'Incident Report - ' +
        format(new Date(), dateTimeFormatForFileNames) +
        '.xlsx'
    )
      .then()
      .catch(() =>
        setError(
          'An error occured while generating the report. Please contact support if this issue persists.'
        )
      )
      .finally(() => setReportDownloading(false))
  }

  const downloadCallLogsReport = async () => {
    var filter = {}
    if (fromDate != null) {
      filter = { ...filter, fromDate: fromDate.toJSON() }
    }
    if (toDate != null) {
      filter = { ...filter, toDate: toDate.toJSON() }
    }
    filter = {
      ...filter,
      callTypeIds: generateStringFromArray(selectedCallTypes),
      propertyIds: generateStringFromArray(
        selectedProperties.length > 0
          ? selectedProperties
          : currentUserProperties.map((p) => p.id)
      ),
      sectionIds: generateStringFromArray(selectedSections),
      buildingIds: generateStringFromArray(selectedBuildings),
      locationIds: generateStringFromArray(selectedLocations),
      platoonIds: generateStringFromArray(selectedPlatoons),
      initiadedBySCT: sctFilter,
      preparedBy: preparedBy,
      approverSigned: supervisorSignedFilter,
    }

    SearchEventsApi.getCallLogsReportData(filter)
      .then((reportResults) => {
        let mappedReportResults = reportResults.map((result) => {
          return mapResultToReportTemplate(result)
        })

        const callLogsWorkSheet = XLSX.utils.json_to_sheet(mappedReportResults)
        const filterValuesWorkSheet = XLSX.utils.json_to_sheet([filterValues])
        const workBook = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(workBook, callLogsWorkSheet, 'Call Logs')
        XLSX.utils.book_append_sheet(
          workBook,
          filterValuesWorkSheet,
          'Filter Values'
        )
        XLSX.writeFile(
          workBook,
          'Call Log Report - ' +
            format(new Date(), dateTimeFormatForFileNames) +
            '.xlsx'
        )
      })
      .catch(() =>
        setError(
          'An error occured while generating the report. Please contact support if this issue persists.'
        )
      )
      .finally(() => setReportDownloading(false))
  }

  const mapResultToReportTemplate = (result) => {
    return {
      'Call Control No.': result.callLogControlNumber,
      'Linked Incident Control No.': result.linkedIncidentControlNumber,
      'Linked Accident Control No.': result.linkedAccidentControlNumber,
      'Prepared By': result.preparedBy,
      'Proactive/Dispatched': result.proactiveOrDispatched,
      'Event Condition': result.eventCondition,
      'Call Date': format(parseISO(result.callDate), dateFormat),
      'Call Day': days[parseISO(result.callDate).getDay()],
      'Call Time': format(parseISO(result.callTimeResponded), timeFormat),
      Platoon: result.platoon,
      Dispatcher: result.dispatcher,
      'Is Anonymous': result.anonymous ? 'Yes' : 'No',
      'Is Resident': result.isSct ? 'Yes' : 'No',
      Property: result.property,
      Section: result.section,
      Building: result.building,
      Location: result.location,
      'Floor No.': result.floor,
      'Apt No.': result.apartment,
      Store: result.store,
      'Parking Spot': result.parkingSpot,
      'Origin of Call': result.originOfCall,
      'Caller Name': result.callerName,
      'Phone No.': result.phoneNo,
      'Responding Officer': result.respondingOfficer,
      'Type of Call': result.typeOfCall,
      Disposition: result.disposition,
      'Disposition Time': result.dispositionTime
        ? format(parseISO(result.dispositionTime), timeFormat)
        : '',
      Notes: result.notes,
      'Linked Incident Report?': result.linkedIncidentReport ? 'Yes' : 'No',
      'Unusual Incident Filed?': result.unusualIncidentFiled ? 'Yes' : 'No',
      'Include In Reporting?': result.includeInReporting ? 'Yes' : 'No',
      'Call Logged Date Time': format(
        parseISO(result.callLoggedDateTime),
        dateTimeFormat
      ),
      'Last Modified Date Time': result.lastModifiedDateTime
        ? format(parseISO(result.lastModifiedDateTime), dateTimeFormat)
        : '',
    }
  }
  const dispatch = useDispatch()
  useEffect(async () => {
    if (propertySelector.status === apiStatus.idle) {
      dispatch(loadPropertiesAsync())
    }
    if (sectionsSelector.status === apiStatus.idle) {
      dispatch(loadSectionsAsync())
    }
    if (buildingsSelector.status === apiStatus.idle) {
      dispatch(loadBuildingsAsync())
    }
    if (locationsSelector.status === apiStatus.idle) {
      dispatch(loadLocationsAsync())
    }
    if (callTypes.status === apiStatus.idle) {
      dispatch(loadCallTypesAsync())
    }
    if (platoons.length === 0) {
      dispatch(loadEventPlatoonsAsync())
    }
  }, [])

  const selectEventType = (e) => {
    setEventType(e.target.value),
      setResults([]),
      setSearchButtonClickedForFirstTime(false)
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={2}>
          <TextField
            select
            label={'Event Type'}
            helperText=' '
            value={selectedEventType}
            onChange={(e) => selectEventType(e)}
            InputLabelProps={{
              className: 'required-label',
              required: true,
            }}
            fullWidth={true}
            defaultValue={1}
          >
            {eventTypeList.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <TextField
            label='Prepared By'
            value={preparedBy}
            onChange={(e) => setPreparedBy(e.target.value)}
            fullWidth={true}
            helperText=' '
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <DatePickerField
            label={'Events From Date'}
            value={fromDate}
            onChange={setFromDate}
            required={false}
            showDay={false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <DatePickerField
            label={'Events To Date'}
            value={toDate}
            onChange={setToDate}
            required={false}
            showDay={false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormEventTypeMultiSelect
            label='Property'
            eventTypes={currentUserProperties}
            updateEventTypes={setPorpertiesFilter}
            selectedValues={selectedProperties}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormEventTypeMultiSelect
            label='Section'
            eventTypes={sectionsSelector.sections}
            updateEventTypes={setSectionsFilter}
            selectedValues={selectedSections}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormEventTypeMultiSelect
            label='Building'
            eventTypes={buildingsSelector.buildings}
            updateEventTypes={setBuildingsFilter}
            selectedValues={selectedBuildings}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormEventTypeMultiSelect
            label='Location'
            eventTypes={locationsSelector.locations}
            updateEventTypes={setLocations}
            selectedValues={selectedLocations}
          />
        </Grid>
        <Grid item xs={2}>
          <FormEventTypeMultiSelect
            label='Call Type / Classification'
            eventTypes={callTypes.callTypes}
            updateEventTypes={setCallTypes}
            selectedValues={selectedCallTypes}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormEventTypeMultiSelect
            label='Platoons'
            eventTypes={platoons}
            updateEventTypes={setPlatoons}
            selectedValues={selectedPlatoons}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <TextField
            select
            label={'Initiated by Resident'}
            helperText=' '
            value={sctFilter}
            onChange={(e) => setSctFilter(e.target.value)}
            fullWidth={true}
            defaultValue={'None'}
          >
            {sctOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <TextField
            select
            label={'Supervisor Signed'}
            helperText=' '
            value={supervisorSignedFilter}
            onChange={(e) => setSupervisorSignedFilter(e.target.value)}
            fullWidth={true}
            defaultValue={'None'}
          >
            {supervisorSignedOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.searchButton}
            variant='contained'
            onClick={search}
          >
            Search
          </Button>
          <Button
            className={classes.button}
            variant='contained'
            onClick={clearFilters}
          >
            Clear
          </Button>
          {selectedEventType == eventTypeEnum.callLog && (
            <Button
              className={classes.downloadButton}
              variant='contained'
              startIcon={<DownloadIcon />}
              disabled={
                currentUserSelector.currentUser.role === 'General User' ||
                reportDownloading
              }
              onClick={() => {
                setReportDownloading(true), downloadCallLogsReport()
              }}
            >
              <div style={{ position: 'relative' }}>
                Download Call Logs Report
                {reportDownloading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Button>
          )}
          {selectedEventType == eventTypeEnum.incident && (
            <Button
              className={classes.downloadButton}
              variant='contained'
              startIcon={<DownloadIcon />}
              disabled={
                (currentUserSelector.currentUser.role === 'General User') |
                (fromDate === null) |
                (toDate === null)
              }
              onClick={() => {
                setReportDownloading(true), downloadIncidentsReport()
              }}
            >
              Download Incidents Report
              {reportDownloading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} style={{ marginTop: '1%' }}>
          {searchButtonClickedForFirstTime && (
            <OrderedTable
              rows={results}
              selectItemFunction={select}
              loaded={loaded}
            />
          )}
        </Grid>
      </Grid>
      <ErrorDialog
        open={error !== undefined}
        handleClose={() => setError(undefined)}
        message={error}
      />
    </>
  )
}

export default withRouter(SearchEvents)

import { createSlice } from '@reduxjs/toolkit'
import * as callerOriginsApi from '../../apiCalls/callerOrigin'

const callerOriginsSlice = createSlice({
  name: 'callerOrigins',
  initialState: [],
  reducers: {
    loadCallerOrigins(_, action) {
      return action.payload
    },
  },
})

const { loadCallerOrigins } = callerOriginsSlice.actions

export const loadCallerOriginsAsync = () => async (dispatch) => {
  return await callerOriginsApi
    .getCallerOrigins()
    .then((callerOrigins) => {
      dispatch(loadCallerOrigins(callerOrigins))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectCallerOrigins = (state) => state.callerOrigins

export default callerOriginsSlice.reducer

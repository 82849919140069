import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const CheckboxField = ({ label, value, onChange, changeHandler, disabled }) => (
  <FormControlLabel
    label={label}
    control={
      <Checkbox
        checked={value}
        onChange={(e) => {
          onChange(e.target.checked)
          if (changeHandler) changeHandler(e.target.checked)
        }}
        defaultValue={false}
        disabled={disabled}
      />
    }
  />
)

CheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  changeHandler: PropTypes.func,
  disabled: PropTypes.bool,
}

const FormCheckbox = ({
  name,
  label,
  defaultValue = false,
  changeHandler,
  disabled = false,
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ onChange, value = false }) => (
        <CheckboxField
          label={label}
          value={value}
          onChange={onChange}
          changeHandler={changeHandler}
          disabled={disabled}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  )
}

FormCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  changeHandler: PropTypes.func,
  disabled: PropTypes.bool,
}

export default FormCheckbox

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus, apiStatusEnum } from '../../common/enums'
import * as locationApi from '../../apiCalls/location'
import { selectBuildings } from './buildingSlice'

const initialState = {
  status: apiStatus.idle,
  error: null,
  locations: [],
}

const locationSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadLocationsSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, locations: payload }
    },
    updateLocationSuccess(state, { payload }) {
      let updatedLocations = state.locations.map((location) => {
        if (location.id != payload.id) return location
        return {
          ...location,
          ...payload,
        }
      })

      return {
        ...state,
        locations: updatedLocations,
        status: apiStatus.succeeded,
      }
    },
    addLocationSuccess(state, { payload }) {
      let updatedLocations = [...state.locations, payload]
      return {
        ...state,
        locations: updatedLocations,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadLocationsSuccess,
  updateLocationSuccess,
  addLocationSuccess,
} = locationSlice.actions

export const loadLocationsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await locationApi
    .getLocations()
    .then((locations) => {
      dispatch(loadLocationsSuccess(locations))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateLocationAsync = (location) => async (dispatch) => {
  dispatch(beginApiCall())
  return await locationApi
    .updateLocation(location)
    .then(() => dispatch(updateLocationSuccess(location)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const addLocationAsync = (location) => async (dispatch) => {
  dispatch(beginApiCall())
  return await locationApi
    .addLocation(location)
    .then((locationId) =>
      dispatch(addLocationSuccess({ id: locationId, ...location }))
    )
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectLocations = (state) => state.locations

export const selectLocationsWithFilter = (
  active,
  buildingId,
  selectedLocationId = null
) => (state) => {
  var { locations } = selectLocations(state)
  return {
    ...selectLocations(state),
    locations: locations
      .filter(
        (l) =>
          (l.isActive == active && l.buildingId == buildingId) ||
          (l.id == selectedLocationId && l.buildingId == buildingId)
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export const selectLocationsWithMultipleBuildingsFilter = (buildingIds) => (
  state
) => {
  if (
    selectLocations(state).status == apiStatusEnum.succeeded &&
    selectBuildings(state).status == apiStatusEnum.succeeded
  ) {
    var { locations } = selectLocations(state)
    var { buildings } = selectBuildings(state)
    var modifiedLocations = locations.map((location) => {
      return {
        ...location,
        name:
          buildings.find((b) => b.id == location.buildingId).name +
          ' - ' +
          location.name,
      }
    })
    var filteredLocations = new Array()
    buildingIds.map((buildingId) => {
      if (locations.find((l) => l.buildingId == buildingId) !== undefined) {
        if (filteredLocations.length > 0) {
          filteredLocations = filteredLocations.concat(
            modifiedLocations.filter((l) => l.buildingId == buildingId)
          )
        } else {
          filteredLocations = modifiedLocations.filter(
            (l) => l.buildingId == buildingId
          )
        }
      }
    })
    return {
      ...selectLocations(state),
      locations: filteredLocations.sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
    }
  } else return selectLocations(state)
}

export const selectLocationIdForApartmentWithBuildingFilter = (buildingId) => (
  state
) => {
  var { locations } = selectLocations(state)
  if (buildingId)
    return {
      location:
        locations
          .filter((l) => l.buildingId == buildingId)
          .find((fl) => fl.name === 'Apartment') || {},
    }
  else return {}
}

export const selectAllActiveApartmentLocations = () => (state) => {
  const { locations } = selectLocations(state)
  return {
    ...selectLocations(state),
    locations: locations
      .filter((l) => l.isActive === true && l.name === 'Apartment')
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default locationSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as apartmentApi from '../../apiCalls/apartment'

const initialState = {
  status: apiStatus.idle,
  error: null,
  apartments: [],
}

const apartmentSlice = createSlice({
  name: 'apartments',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadApartmentsSuccess(status, { payload }) {
      return { ...status, status: apiStatus.succeeded, apartments: payload }
    },
    updateApartmentSuccess(state, { payload }) {
      let updatedApartments = state.apartments.map((apartment) => {
        if (apartment.id != payload.id) {
          return apartment
        }
        return {
          ...apartment,
          ...payload,
        }
      })

      return {
        ...state,
        apartments: updatedApartments,
        status: apiStatus.succeeded,
      }
    },
    addApartmentSuccess(state, { payload }) {
      let updatedApartments = [...state.apartments, payload]
      return {
        ...state,
        apartments: updatedApartments,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadApartmentsSuccess,
  updateApartmentSuccess,
  addApartmentSuccess,
} = apartmentSlice.actions

export const loadApartmentsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await apartmentApi
    .getApartments()
    .then((apartments) => {
      dispatch(loadApartmentsSuccess(apartments))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateApartmentAsync = (apartment) => async (dispatch) => {
  dispatch(beginApiCall())
  return await apartmentApi
    .updateApartment(apartment)
    .then(() => dispatch(updateApartmentSuccess(apartment)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const addApartmentAsync = (apartment) => async (dispatch) => {
  dispatch(beginApiCall())
  return await apartmentApi
    .addApartment(apartment)
    .then((apartmentId) =>
      dispatch(addApartmentSuccess({ id: apartmentId, ...apartment }))
    )
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectApartments = (state) => state.apartments

export const selectApartmentsWithFilter = (
  active,
  locationId,
  selectedApartmentId = null
) => (state) => {
  var apartmentsSelector = selectApartments(state)
  return {
    ...apartmentsSelector,
    apartments: apartmentsSelector.apartments
      .filter(
        (a) =>
          (a.isActive == active && a.locationId == locationId) ||
          (a.id == selectedApartmentId && a.locationId == locationId)
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export const selectAllActiveApartments = () => (state) => {
  const { apartments } = selectApartments(state)
  return {
    ...selectApartments(state),
    apartments: apartments
      .filter((a) => a.isActive === true)
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default apartmentSlice.reducer

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  TableHead,
  Typography,
  Toolbar,
  TableRow,
  TableSortLabel,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'

import { format } from 'date-fns'
import { dateFormat, spinnerSize } from '../../../common/constants'
import { useSelector } from 'react-redux'
import {
  selectPrimaryThemeColor,
  selectSecondaryThemeColor,
} from '../../../admin/slices/themeColorSlice'
import { grey } from '@material-ui/core/colors'
import CircularProgress from '@material-ui/core/CircularProgress'

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) return order
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

const headCells = [
  {
    id: 'controlNo',
    numeric: false,
    disablePadding: false,
    label: 'Control No.',
  },
  {
    id: 'lastModified',
    numeric: false,
    disablePadding: false,
    label: 'Last Modified',
  },
  {
    id: 'issueType',
    numeric: false,
    disablePadding: false,
    label: 'Issue Type',
  },
  {
    id: 'classification',
    numeric: false,
    disablePadding: false,
    label: 'Classification',
  },
  { id: 'building', numeric: false, disablePadding: false, label: 'Building' },
  { id: 'location', numeric: false, disablePadding: false, label: 'Location' },
  {
    id: 'incidentAvailable',
    numeric: false,
    disablePadding: false,
    label: 'Incident Available',
  },
  {
    id: 'approverSigned',
    numeric: false,
    disablePadding: false,
    label: 'Supervisor Signed',
  },
  {
    id: 'completed',
    numeric: false,
    disablePadding: false,
    label: 'Completed',
  },
]

const EnhancedTableHead = (props) => {
  const { classes, order, orderBy, onRequestSort } = props
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
}

const EnhancedTableToolbar = ({
  resultsCount,
  loaded,
  showNestedRows,
  setShowNestedRows,
}) => {
  // const classes = useToolbarStyles()

  const useStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: useSelector(selectPrimaryThemeColor()),
    },
    title: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      flex: '1 1 100%',
    },
  }))
  const classes = useStyles()

  return (
    <Toolbar className={clsx(classes.root)}>
      <Grid container justify='space-between' alignItems='center'>
        <Grid item xs={4}>
          <Typography
            className={classes.title}
            variant='h6'
            id='tableTitle'
            component='div'
          >
            Results {loaded && '(' + resultsCount + ')'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            style={{ color: 'white', float: 'right' }}
            control={
              <Checkbox
                style={{ color: 'white' }}
                value={showNestedRows}
                checked={showNestedRows}
                onChange={() => setShowNestedRows(!showNestedRows)}
              />
            }
            label='Show linked events'
            labelPlacement='end'
          />
        </Grid>
      </Grid>
    </Toolbar>
  )
}

EnhancedTableToolbar.propTypes = {
  resultsCount: PropTypes.number.isRequired,
  loaded: PropTypes.bool.isRequired,
  showNestedRows: PropTypes.bool.isRequired,
  setShowNestedRows: PropTypes.func.isRequired,
}

const NestedTable = ({ rows, selectItemFunction, classes }) => {
  if (rows != null) {
    {
      return rows.map((row, index) => {
        const labelId = `enhanced-table-${index}`
        return (
          <TableRow
            key={row.controlNo}
            onClick={() => selectItemFunction(row.issueType, row.id)}
            hover
            role='button'
            tabIndex={-1}
            className={classes.nestedRow}
          >
            <TableCell
              component='th'
              id={labelId}
              scope='row'
              style={{ paddingLeft: '30px' }}
            >
              {row.controlNo}
            </TableCell>
            <TableCell align='left'>
              {format(new Date(row.lastModified), dateFormat)}
            </TableCell>
            <TableCell align='left'>{row.issueType}</TableCell>
            <TableCell align='left'>{row.classification}</TableCell>
            <TableCell align='left'>{row.building}</TableCell>
            <TableCell align='left'>{row.location}</TableCell>
            <TableCell align='left'>{row.incidentAvailable}</TableCell>
            <TableCell align='left'>
              {row.issueType == 'Accident' || row.issueType == 'Incident'
                ? row.approverSigned
                  ? 'Yes'
                  : 'No'
                : '-'}
            </TableCell>
            <TableCell align='left'>
              {row.issueType != 'Unusual Incident'
                ? row.completed
                  ? 'Yes'
                  : 'No'
                : '-'}
            </TableCell>
          </TableRow>
        )
      })
    }
  }
}

const OrderedTable = ({ rows, selectItemFunction, loaded }) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableHead: {
      minWidth: 750,
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    nestedRow: {
      backgroundColor: grey[200],
    },
  }))

  const classes = useStyles()
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('calories')
  const [showNestedRows, setShowNestedRows] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          resultsCount={rows.length}
          loaded={loaded}
          showNestedRows={showNestedRows}
          setShowNestedRows={setShowNestedRows}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={'small'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-${index}`

                  return (
                    <>
                      <TableRow
                        hover
                        onClick={() =>
                          selectItemFunction(row.issueType, row.id)
                        }
                        role='button'
                        tabIndex={-1}
                        key={row.controlNo}
                      >
                        <TableCell component='th' id={labelId} scope='row'>
                          {row.controlNo}
                        </TableCell>
                        <TableCell align='left'>
                          {format(new Date(row.lastModified), dateFormat)}
                        </TableCell>
                        <TableCell align='left'>{row.issueType}</TableCell>
                        <TableCell align='left'>{row.classification}</TableCell>
                        <TableCell align='left'>{row.building}</TableCell>
                        <TableCell align='left'>{row.location}</TableCell>
                        <TableCell align='left'>
                          {row.incidentAvailable}
                        </TableCell>
                        <TableCell align='left'>
                          {row.issueType == 'Accident' ||
                          row.issueType == 'Incident'
                            ? row.approverSigned
                              ? 'Yes'
                              : 'No'
                            : '-'}
                        </TableCell>
                        <TableCell align='left'>
                          {row.issueType != 'Unusual Incident'
                            ? row.completed
                              ? 'Yes'
                              : 'No'
                            : '-'}
                        </TableCell>
                      </TableRow>
                      {showNestedRows && row.nestedEvents != null && (
                        <NestedTable
                          key={labelId + 'nest'}
                          rows={row.nestedEvents}
                          selectItemFunction={selectItemFunction}
                          classes={classes}
                        />
                      )}
                    </>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!loaded && (
        <>
          <CircularProgress
            size={spinnerSize}
            style={{
              position: 'fixed',
              left: window.innerWidth / 2 - spinnerSize / 2,
            }}
          />
        </>
      )}
    </div>
  )
}

export default OrderedTable

//React imports
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
//Component imports
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkedReports from '../molecules/LinkedReports'
import IncidentEventDetailsFields from '../molecules/IncidentEventDetailsFields'
import LocationFields from '../molecules/LocationFields'
import ComplainantsWitnessesFields from '../molecules/ComplainantsWitnessesFields'
import PerpetratorsFields from '../molecules/PerpetratorsFields'
import VehiclesFields from '../molecules/VehiclesFields'
import FireFields from '../molecules/FireFields'
import FloodFields from '../molecules/FloodFields'
import EvictionFields from '../molecules/EvictionFields'
import MoveInOutFields from '../molecules/MoveInOutFields'
import PropertyFields from '../molecules/PropertyFields'
import IncidentDetailsFields from '../molecules/IncidentDetailsFields'
import EventFormButtons from '../molecules/EventFormButtons'
import ComplainantsWitnessesDialog from '../molecules/ComplainantsWitnessesDialog'
import PerpetratorsDialog from '../molecules/PerpetratorsDialog'
import ApprovalFields from '../molecules/ApprovalFields'
import FormDetails from '../molecules/FormDetails'
import VehicleDialog from '../molecules/VehicleDialog'
import PropertyDialog from '../molecules/PropertyDialog'
//Store imports
import { useDispatch, useSelector } from 'react-redux'
import {
  loadEventConditionsAsync,
  selectFilteredEventConditions,
} from '../../slices/eventConditionsSlice'
import {
  loadCallerOriginsAsync,
  selectCallerOrigins,
} from '../../slices/callerOriginsSlice'
import {
  loadPropertiesAsync,
  selectActiveUserProperties,
} from '../../../admin/slices/propertySlice'
import {
  selectSectionsWithFilter,
  loadSectionsAsync,
} from '../../../admin/slices/sectionSlice'
import {
  selectBuildings,
  selectBuildingsWithFilter,
  loadBuildingsAsync,
} from '../../../admin/slices/buildingSlice'
import {
  selectLocationsWithFilter,
  loadLocationsAsync,
} from '../../../admin/slices/locationSlice'
import {
  selectApartments,
  selectApartmentsWithFilter,
  loadApartmentsAsync,
} from '../../../admin/slices/apartmentSlice'
import {
  selectFloorsWithFilter,
  loadFloorsAsync,
} from '../../../admin/slices/floorSlice'
import {
  selectStoresWithFilter,
  loadStoresAsync,
} from '../../../admin/slices/storeSlice'
import {
  selectParkingSpotsWithFilter,
  loadParkingSpotsAsync,
} from '../../../admin/slices/parkingSpotSlice'
import {
  selectControlNumbers,
  loadControlNumbersAsync,
} from '../../slices/callLogControlNumberSlice'
import {
  eventTypes,
  eventTypeEnum,
  apiStatusEnum as apiStatus,
} from '../../../common/enums'
import * as attachmentApi from '../../../apiCalls/attachment'
import DeleteAttachmentAlert from '../molecules/DeleteAttachmentAlert'
import * as incidentApi from '../../../apiCalls/incident'
import { useHistory, withRouter } from 'react-router-dom'
import {
  loadIncidentClassificationCodesAsync,
  selectIncidentClassificationCodes,
} from '../../slices/incidentClassificationCodesSlice'
import {
  loadCallTypesAsync,
  selectOrderedCallTypes,
} from '../../slices/callTypesSlice'
import {
  roles,
  propertyIssueTypes,
  belongingUses,
  spinnerSize,
} from '../../../common/constants'
import {
  selectCurrentUserDisplayName,
  selectCurrentUserProperties,
  selectCurrentUserRole,
} from '../../../admin/slices/currentUserSlice'
import {
  loadTenantsAsync,
  selectTenantsByPropertyId,
} from '../../../admin/slices/tenantSlice'

import { getCallLogById } from '../../../apiCalls/callLog'
import { Typography } from '@material-ui/core'
import ErrorAlertDialog from '../molecules/ErrorAlertDialog'
import { loadStatesAsync, selectStates } from '../../slices/stateCodesSlice'
import PageHeader from '../../../common/components/organisms/PageHeader'
import FormValidationMessage from '../molecules/FormValidationMessage'

const incidentTypeList = [
  'Perpetrators',
  'Vehicles',
  'Fire',
  'Flood',
  'Eviction',
  'Move In / Out',
  'Property',
]

const IncidentEvent = ({
  eventTypeChange,
  incidentEvent = null,
  callLog = null,
  createAccidentFromIncident,
  setRecordEventLoaded,
}) => {
  const [header, setHeader] = useState('Record New Incident')
  const currentUserName = useSelector(selectCurrentUserDisplayName())
  const currentUserActiveProperties = useSelector(
    selectCurrentUserProperties()
  ).filter((p) => p.isActive)
  const isGeneralUser =
    useSelector(selectCurrentUserRole()) === roles.generalUser
  const defaultIncident = {
    event: {
      type: 2,
      preparedBy: currentUserName,
      condition: 1,
      dateReported: ' ',
      location: {
        propertyId: currentUserActiveProperties.length
          ? currentUserActiveProperties[0].id
          : null,
      },
    },
    attachments: {
      isLoading: false,
      list: [],
    },
  }
  let currentIncident = {}
  if (incidentEvent !== null) currentIncident = incidentEvent
  else currentIncident = defaultIncident

  if (incidentEvent == null && callLog !== null) {
    currentIncident.event = {
      ...currentIncident.event,
      callLogControlNumber: callLog.event.callLogControlNumber,
      dateReported: callLog.event.date,
      timeReported: callLog.event.timeResponded,
      location: callLog.event.location,
      callTypeId: callLog.event.responseDetails.callTypeId,
    }
  }

  const showDownload = incidentEvent != null

  let eventTypeList = {}
  if (isGeneralUser) eventTypeList = eventTypes.filter((t) => t.id != 3)
  else eventTypeList = eventTypes

  const methods = useForm({ defaultValues: currentIncident })
  const { handleSubmit, reset, setValue, watch, formState } = methods
  const dispatch = useDispatch()

  const [incidentTypes, setIncidentTypes] = useState({
    perpetrators: false,
    vehicles: false,
    fire: false,
    flood: false,
    eviction: false,
    moveInOut: false,
    property: false,
    string: '',
  })
  const updateIncidentTypes = async (l = []) => {
    const current = {
      perpetrators: false,
      vehicles: false,
      fire: false,
      flood: false,
      eviction: false,
      moveInOut: false,
      property: false,
      string: '',
    }
    l.map((t) => {
      switch (t) {
        case 'Perpetrators':
          current.perpetrators = true
          break
        case 'Vehicles':
          current.vehicles = true
          break
        case 'Fire':
          current.fire = true
          break
        case 'Flood':
          current.flood = true
          break
        case 'Eviction':
          current.eviction = true
          break
        case 'Move In / Out':
          current.moveInOut = true
          break
        case 'Property':
          current.property = true
          break
      }
    })
    current.string = l.join(',')
    setIncidentTypes(current)
  }

  const incidentClassificationCodesSelector = useSelector(
    selectIncidentClassificationCodes
  )
  const callTypesSelector = useSelector(selectOrderedCallTypes)

  //This is for adding a spinner/show loading
  const [dataLoaded, setDataLoaded] = useState(false)
  const loading = () => setDataLoaded(false)
  const loaded = () => setDataLoaded(true)

  //Event Details Dropdown lists
  const watchSupervisorSigned = watch(
    'event.incidentApproval.approverSignature'
  )

  const supervisorSignature = () => {
    return watchSupervisorSigned
      ? watchSupervisorSigned.signature.length > 0 &&
          watchSupervisorSigned.date.length > 0
      : false
  }

  const eventConditions = useSelector(
    selectFilteredEventConditions(supervisorSignature())
  )

  const callerOrigins = useSelector(selectCallerOrigins)

  const callLogControlNumbers = useSelector(selectControlNumbers)

  //Location fields
  const savedProperty = incidentEvent
    ? incidentEvent.event.location.propertyId
    : null
  const savedSection = incidentEvent
    ? incidentEvent.event.location.sectionId
    : null
  const savedBuilding = incidentEvent
    ? incidentEvent.event.location.buildingId
    : null
  const savedLocation = incidentEvent
    ? incidentEvent.event.location.locationId
    : null
  const savedApartment = incidentEvent
    ? incidentEvent.event.location.apartmentId
    : null
  const savedFloor = incidentEvent ? incidentEvent.event.location.floorId : null
  const savedStore = incidentEvent ? incidentEvent.event.location.storeId : null
  const savedParkingSpot = incidentEvent
    ? incidentEvent.event.location.parkingSpotId
    : null

  const [propertyFilterValue, setPropertyFilterValue] = useState(null)
  const [sectionFilterValue, setSectionFilterValue] = useState(null)
  const clearSectionFilter = () => setSectionFilterValue(null)
  const [buildingFilterValue, setBuildingFilterValue] = useState(null)
  const clearBuildingFilter = () => setBuildingFilterValue(null)
  const [locationFilterValue, setLocationFilterValue] = useState(null)
  const clearLocationFilter = () => setLocationFilterValue(null)
  const [locationType, setLocationType] = useState('')
  const propertySelector = useSelector(
    selectActiveUserProperties(currentUserActiveProperties, savedProperty)
  )
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(true, propertyFilterValue, savedSection)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(true, sectionFilterValue, savedBuilding)
  )
  const allBuildingsSelector = useSelector(selectBuildings)
  const locationsSelector = useSelector(
    selectLocationsWithFilter(true, buildingFilterValue, savedLocation)
  )
  const apartmentsSelector = useSelector(
    selectApartmentsWithFilter(true, locationFilterValue, savedApartment)
  )
  const allApartmentsSelector = useSelector(selectApartments)
  const floorSelector = useSelector(
    selectFloorsWithFilter(true, locationFilterValue, savedFloor)
  )
  const storesSelector = useSelector(
    selectStoresWithFilter(true, locationFilterValue, savedStore)
  )
  const parkingSpotsSelector = useSelector(
    selectParkingSpotsWithFilter(true, locationFilterValue, savedParkingSpot)
  )

  const [sectionDisabled, setSectionDisabled] = useState(true)
  const [buildingDisabled, setBuildingDisabled] = useState(true)
  const [locationDisabled, setLocationDisabled] = useState(true)

  const resetSelect = (fieldName) => setValue(fieldName, '')

  const setPropertyAsFilter = (item) => {
    setPropertyFilterValue(item)
    resetSelect('event.location.sectionId')
    resetSelect('event.location.buildingId')
    resetSelect('event.location.locationId')
    clearSectionFilter()
    clearBuildingFilter()
    clearLocationFilter()
    setSectionDisabled(false)
    setBuildingDisabled(true)
    setLocationDisabled(true)
    setLocationType('')
  }

  const selectSectionAsFilter = (item) => {
    setSectionFilterValue(item)
    resetSelect('event.location.buildingId')
    resetSelect('event.location.locationId')
    clearBuildingFilter()
    clearLocationFilter()
    setBuildingDisabled(false)
    setLocationDisabled(true)
    setLocationType('')
  }
  const selectBuildingAsFilter = (item) => {
    setBuildingFilterValue(item)
    resetSelect('event.location.locationId')
    clearLocationFilter()
    setLocationDisabled(false)
    setLocationType('')
  }
  const selectLocationAsFilter = (item) => {
    setLocationFilterValue(item)
    setLocationType(locationsSelector.locations.find((e) => e.id === item).name)
  }

  const callLogControlNumberChange = (controlNumber) => {
    getCallLogById(controlNumber.callLogId).then((callLog) => {
      setValue('event.dateReported', callLog.date)
      setValue('event.timeReported', callLog.timeResponded)
      setValue('event.callTypeId', callLog.responseDetails.callTypeId)
    })
  }

  //Complainant/Witnesses Controls
  const [complainantsWitnessesList, setComplainantsWitnessesList] = useState([])
  const [complainantWitnessDialog, setComplainantWitnessDialog] = useState({
    open: false,
    index: -1,
    person: {},
  })
  const closeComplainantWitnessDialog = () =>
    setComplainantWitnessDialog({ open: false, index: -1, person: {} })
  const openComplainantsWitnessesDialog = (i = -1, p = {}) =>
    setComplainantWitnessDialog({ open: true, index: i, person: p })
  const updateComplainantsWitnessesList = (list) => {
    let listUpdated = list.map((data) => {
      var building = ''
      if (
        data.buildingId !== undefined &&
        data.buildingId !== null &&
        allBuildingsSelector.buildings.find((b) => b.id === data.buildingId) !==
          undefined
      ) {
        building = allBuildingsSelector.buildings.find(
          (b) => b.id === data.buildingId
        ).name
      }

      var apartment = ''
      if (
        data.apartmentId !== undefined &&
        data.apartmentId !== null &&
        allApartmentsSelector.apartments.find(
          (a) => a.id == data.apartmentId
        ) !== undefined
      ) {
        apartment = allApartmentsSelector.apartments.find(
          (a) => a.id == data.apartmentId
        ).name
      }
      return {
        ...data,
        building: building,
        apartment: apartment,
      }
    })
    setComplainantsWitnessesList(listUpdated)
    setValue('event.complainanantsAndWitnesses', complainantsWitnessesList)
  }

  const handleOpenComplainantsWitnessesDialog = (i = -1, p = {}) => {
    openComplainantsWitnessesDialog(i, p)
  }
  const handleCloseComplainantsWitnessesDialog = () => {
    closeComplainantWitnessDialog()
  }
  const handleRemoveComplainantsWitnessesDialog = () => {
    if (complainantWitnessDialog.index !== -1) {
      let updatedList = complainantsWitnessesList
      updatedList.splice(complainantWitnessDialog.index, 1)
      updateComplainantsWitnessesList(updatedList)
    }
    closeComplainantWitnessDialog()
  }
  const handleSaveComplainantsWitnessesDialog = (data) => {
    let updatedList = complainantsWitnessesList
    if (complainantWitnessDialog.index === -1) {
      updatedList.push(data)
    } else {
      updatedList[complainantWitnessDialog.index] = data
    }
    updateComplainantsWitnessesList(updatedList)
    closeComplainantWitnessDialog()
  }

  //Perpetrators Controls
  const [perpetratorsList, setPerpetratorsList] = useState([])
  const [perpetratorsDialog, setPerpetratorsDialog] = useState({
    open: false,
    index: -1,
    person: {},
  })
  const closePerpetratorsDialog = () =>
    setPerpetratorsDialog({ open: false, index: -1, person: {} })
  const openPerpetratorsDialog = (i = -1, p = {}) =>
    setPerpetratorsDialog({ open: true, index: i, person: p })
  const updatePerpetratorsList = (list) => {
    let listUpdated = list.map((data) => {
      var building = ''
      if (
        data.buildingId !== undefined &&
        data.buildingId !== null &&
        allBuildingsSelector.buildings.find((b) => b.id === data.buildingId) !==
          undefined
      ) {
        building = allBuildingsSelector.buildings.find(
          (b) => b.id === data.buildingId
        ).name
      }

      var apartment = ''
      if (
        data.apartmentId !== undefined &&
        data.apartmentId !== null &&
        allApartmentsSelector.apartments.find(
          (a) => a.id == data.apartmentId
        ) !== undefined
      ) {
        apartment = allApartmentsSelector.apartments.find(
          (a) => a.id == data.apartmentId
        ).name
      }
      return {
        ...data,
        building: building,
        apartment: apartment,
      }
    })
    setPerpetratorsList(listUpdated)
    setValue('event.perpetrators', perpetratorsList)
  }

  const handleOpenPerpetratorsDialog = (i = -1, p = {}) => {
    openPerpetratorsDialog(i, p)
  }
  const handleClosePerpetratorsDialog = () => {
    closePerpetratorsDialog()
  }
  const handleRemovePerpetratorsDialog = () => {
    if (perpetratorsDialog.index !== -1) {
      let updatedList = perpetratorsList
      updatedList.splice(perpetratorsDialog.index, 1)
      updatePerpetratorsList(updatedList)
    }
    closePerpetratorsDialog()
  }
  const handleSavePerpetratorsDialog = (data) => {
    let updatedList = perpetratorsList
    if (perpetratorsDialog.index === -1) {
      updatedList.push(data)
    } else {
      updatedList[perpetratorsDialog.index] = data
    }
    updatePerpetratorsList(updatedList)
    closePerpetratorsDialog()
  }

  //Vehicles Controls
  const [vehiclesList, setVehiclesList] = useState([])
  const [vehicleDialog, setVehicleDialog] = useState({
    open: false,
    index: -1,
    vehicle: {},
  })
  const closeVehicleDialog = () =>
    setVehicleDialog({ open: false, index: -1, vehicle: {} })
  const openVehicleDialog = (i = -1, p = {}) =>
    setVehicleDialog({ open: true, index: i, vehicle: p })
  const updateVehiclesList = (list) => {
    setVehiclesList(
      list.map((item) => {
        return {
          ...item,
          issue: item.vehicleIssues,
          state: item.stateId
            ? statesSelector.find((s) => s.id == item.stateId).name
            : null,
        }
      })
    )
    setValue('event.vehicles', vehiclesList)
  }

  const handleOpenVehicleDialog = (i = -1, v = {}) => {
    openVehicleDialog(i, v)
  }
  const handleCloseVehicleDialog = () => {
    closeVehicleDialog()
  }
  const handleRemoveVehicleDialog = () => {
    if (vehicleDialog.index !== -1) {
      let updatedList = vehiclesList
      updatedList.splice(vehicleDialog.index, 1)
      updateVehiclesList(updatedList)
    }
    closeVehicleDialog()
  }
  const handleSaveVehicleDialog = (data) => {
    let updatedList = vehiclesList
    if (vehicleDialog.index === -1) updatedList.push(data)
    else updatedList[vehicleDialog.index] = data
    updateVehiclesList(updatedList)
    closeVehicleDialog()
  }

  //Property Controls
  const [propertyList, setPropertyList] = useState([])
  const [propertyDialog, setPropertyDialog] = useState({
    open: false,
    index: -1,
    property: {},
  })
  const closePropertyDialog = () =>
    setPropertyDialog({ open: false, index: -1, property: {} })
  const openPropertyDialog = (i = -1, p = {}) =>
    setPropertyDialog({ open: true, index: i, property: p })
  const updatePropertyList = (list) => {
    let listUpdated = list.map((data) => {
      return {
        ...data,
        issueType: propertyIssueTypes.find(
          (i) => i.id == data.belongingIncidentTypeId
        ).name,
        useType: belongingUses.find((u) => u.id == data.belongingUseId).name,
      }
    })
    setPropertyList(listUpdated)
    setValue('property.list', propertyList)
  }

  const handleOpenPropertyDialog = (i = -1, v = {}) => {
    openPropertyDialog(i, v)
  }
  const handleClosePropertyDialog = () => {
    closePropertyDialog()
  }
  const handleRemovePropertyDialog = () => {
    if (propertyDialog.index !== -1) {
      let updatedList = propertyList
      updatedList.splice(propertyDialog.index, 1)
      updatePropertyList(updatedList)
    }
    closePropertyDialog()
  }
  const handleSavePropertyDialog = (data) => {
    let updatedList = propertyList
    if (propertyDialog.index === -1) updatedList.push(data)
    else updatedList[propertyDialog.index] = data
    updatePropertyList(updatedList)
    closePropertyDialog()
  }

  // attachments
  const [attachments, setAttachments] = useState([])
  const [attachmentsIsLoading, setAttachmentsIsLoading] = useState(false)
  const [deleteAttachmentAlert, setDeleteAttachmentAlert] = useState({
    open: false,
  })
  const [showAttachmentUploadError, setShowAttachmentUploadError] =
    useState(false)
  const [attachmentUploadError, setAttachmentUploadError] = useState({
    title: '',
    content: '',
  })

  const closeAttachmentErrorAlert = () => {
    setShowAttachmentUploadError(false)
    setAttachmentUploadError({ title: '', content: '' })
  }

  const handleAddAttachments = async (e) => {
    let attachmentsToAdd = e.target.files
    let updatedAttachments = attachments

    for (var i = 0; i < attachmentsToAdd.length; i++) {
      await attachmentApi
        .uploadFile(attachmentsToAdd[i])
        .then((attachment) => updatedAttachments.push(attachment))
        .catch((error) => {
          console.log(error)
          setShowAttachmentUploadError(true)
          setAttachmentUploadError({
            title: 'Error encountered while uploading an attachment.',
            content: error.name + ': ' + error.message,
          })
        })
    }

    setAttachments(updatedAttachments)
    setValue('attachments.list', updatedAttachments)
    setAttachmentsIsLoading(false)
  }

  const handleDeleteAttachment = async (attachmentId) => {
    setAttachmentsIsLoading(true)
    let attachment = attachments.find((a) => a.attachmentId == attachmentId)
    setDeleteAttachmentAlert({ open: true, attachment: attachment })
  }

  const closeDeleteAttachmentAlert = async () => {
    setDeleteAttachmentAlert({ open: false })
  }

  const handleCancelDeleteAttachmentAlert = async () => {
    closeDeleteAttachmentAlert()
    setAttachmentsIsLoading(false)
  }

  const handleConfirmDeleteAttachmentAlert = async (attachmentId) => {
    let updatedAttachments = attachments.filter(
      (a) => a.attachmentId != attachmentId
    )
    setAttachments(updatedAttachments)
    setValue('attachments.list', updatedAttachments)
    setDeleteAttachmentAlert({ open: false, attachment: {} })
    setAttachmentsIsLoading(false)
  }

  const handleDownloadAttachment = async (attachmentId) => {
    await attachmentApi.downloadAttachment(
      attachmentId,
      attachments.find((a) => a.attachmentId == attachmentId).name
    )
  }

  //Form control functions
  const [showCreateAccidentButton, setShowCreateAccidentButton] =
    useState(false)

  const createAccident = async () => {
    setRecordEventLoaded(false)
    createAccidentFromIncident(currentIncident)
  }

  const resetForm = () => {
    setRecordEventLoaded(false)
    if (currentIncident.event.incidentId != undefined) {
      history.push(
        '/event/' +
          eventTypeEnum.incident +
          '/' +
          currentIncident.event.incidentId
      )
    } else {
      history.push('/recordEvent/' + eventTypeEnum.incident)
    }
  }

  const [firstLoadOfExistingCallLog, setFirstLoadOfExistingCallLog] =
    useState(false)
  const [secondLoadOfExistingCallLog, setSecondLoadOfExistingCallLog] =
    useState(false)

  const ensureCorrectDataModel = (data) => {
    data.event.incidentTypes = incidentTypes.string
    data.event.incidentDetail.attachments = attachments
    data.event.perpetrators = perpetratorsList
    data.event.complainanantsAndWitnesses = complainantsWitnessesList
    data.event.belongings = propertyList
    if (data.event.eviction !== undefined) {
      data.event.eviction.marshalRemainedOnScene =
        data.event.eviction.marshalRemainedOnScene === null
          ? false
          : data.event.eviction.marshalRemainedOnScene

      data.event.eviction.managementPresentAtScene =
        data.event.eviction.managementPresentAtScene === null
          ? false
          : data.event.eviction.managementPresentAtScene

      data.event.eviction.maintenancePresentAtScene =
        data.event.eviction.maintenancePresentAtScene === null
          ? false
          : data.event.eviction.maintenancePresentAtScene
    }

    if (data.event.flood !== undefined) {
      data.event.flood.tenantAdvisedToSubmitDamages =
        data.event.flood.tenantAdvisedToSubmitDamages === null
          ? false
          : data.event.flood.tenantAdvisedToSubmitDamages

      data.event.flood.maintenancePersonnelAtScene =
        data.event.flood.maintenancePersonnelAtScene === null
          ? false
          : data.event.flood.maintenancePersonnelAtScene
    }

    if (data.event.fire !== undefined) {
      data.event.fire.fireMarshalOnScene =
        data.event.fire.fireMarshalOnScene === null
          ? false
          : data.event.fire.fireMarshalOnScene

      data.event.fire.bodilyInjuries =
        data.event.fire.bodilyInjuries === null
          ? false
          : data.event.fire.bodilyInjuries

      data.event.fire.housingCompanyPropertyDamaged =
        data.event.fire.housingCompanyPropertyDamaged === null
          ? false
          : data.event.fire.housingCompanyPropertyDamaged

      data.event.fire.personalPropertyDamaged =
        data.event.fire.personalPropertyDamaged === null
          ? false
          : data.event.fire.personalPropertyDamaged
    }

    return data
  }

  //tenants
  const tenantSelector = useSelector(
    selectTenantsByPropertyId(propertyFilterValue)
  )

  const statesSelector = useSelector(selectStates)

  useEffect(async () => {
    if (
      currentIncident.event.incidentId != undefined &&
      !firstLoadOfExistingCallLog
    ) {
      if (currentIncident.event.incidentTypes != undefined) {
        updateIncidentTypes(currentIncident.event.incidentTypes.split(','))
      }
      if (currentIncident.event.controlNumberReferenceTree.accidentId == null) {
        setShowCreateAccidentButton(true)
      }
      setHeader('Incident: ' + currentIncident.event.incidentControlNumber)
      updatePropertyList(currentIncident.event.belongings)
      setAttachments(currentIncident.event.incidentDetail.attachments)
      setPropertyAsFilter(currentIncident.event.location.propertyId)
      selectSectionAsFilter(currentIncident.event.location.sectionId)
      selectBuildingAsFilter(currentIncident.event.location.buildingId)
      selectLocationAsFilter(currentIncident.event.location.locationId)
      setFirstLoadOfExistingCallLog(true)
    }
    if (
      currentIncident.event.incidentId != undefined &&
      locationsSelector.status === apiStatus.succeeded &&
      buildingsSelector.status == apiStatus.succeeded &&
      apartmentsSelector.status == apiStatus.succeeded &&
      statesSelector.length &&
      firstLoadOfExistingCallLog &&
      !secondLoadOfExistingCallLog
    ) {
      updatePerpetratorsList(currentIncident.event.perpetrators)
      updateComplainantsWitnessesList(
        currentIncident.event.complainanantsAndWitnesses
      )
      updateVehiclesList(currentIncident.event.vehicles)
      updatePropertyList(currentIncident.event.belongings)
      selectLocationAsFilter(currentIncident.event.location.locationId)
      setSecondLoadOfExistingCallLog(true)
    }

    if (incidentClassificationCodesSelector.length === 0) {
      dispatch(loadIncidentClassificationCodesAsync())
    }
    if (callTypesSelector.status === apiStatus.idle) {
      dispatch(loadCallTypesAsync())
    }
    if (callLogControlNumbers.status != apiStatus.pending)
      dispatch(loadControlNumbersAsync())
    if (eventConditions.length === 0) {
      dispatch(loadEventConditionsAsync())
    }
    if (callerOrigins.length === 0) {
      dispatch(loadCallerOriginsAsync())
    }
    if (propertySelector.status === apiStatus.idle) {
      dispatch(loadPropertiesAsync())
    }
    if (sectionsSelector.status === apiStatus.idle) {
      dispatch(loadSectionsAsync())
    }
    if (buildingsSelector.status === apiStatus.idle) {
      dispatch(loadBuildingsAsync())
    }
    if (locationsSelector.status === apiStatus.idle) {
      dispatch(loadLocationsAsync())
    }
    if (floorSelector.status === apiStatus.idle) {
      dispatch(loadFloorsAsync())
    }
    if (apartmentsSelector.status === apiStatus.idle) {
      dispatch(loadApartmentsAsync())
    }
    if (storesSelector.status === apiStatus.idle) {
      dispatch(loadStoresAsync())
    }
    if (parkingSpotsSelector.status === apiStatus.idle) {
      dispatch(loadParkingSpotsAsync())
    }
    if (tenantSelector.status === apiStatus.idle) {
      dispatch(loadTenantsAsync())
    }
    if (statesSelector.length === 0) {
      dispatch(loadStatesAsync())
    } else {
      loaded()
    }

    if (
      propertySelector.status === apiStatus.succeeded &&
      sectionsSelector.status === apiStatus.succeeded &&
      buildingsSelector.status === apiStatus.succeeded &&
      locationsSelector.status === apiStatus.succeeded
    ) {
      if (callLog !== null) {
        if (propertyFilterValue == null) {
          setPropertyAsFilter(callLog.event.location.propertyId)
          setValue(
            'event.location.propertyId',
            callLog.event.location.propertyId
          )
        }
        if (propertyFilterValue !== null && sectionFilterValue == null) {
          selectSectionAsFilter(callLog.event.location.sectionId)
          setValue('event.location.sectionId', callLog.event.location.sectionId)
        }
        if (sectionFilterValue !== null && buildingFilterValue == null) {
          selectBuildingAsFilter(callLog.event.location.buildingId)
          setValue(
            'event.location.buildingId',
            callLog.event.location.buildingId
          )
        }
        if (buildingFilterValue !== null && locationFilterValue == null) {
          selectLocationAsFilter(callLog.event.location.locationId)
          setValue(
            'event.location.locationId',
            callLog.event.location.locationId
          )
        }
      }
      if (
        currentIncident.event.incidentId === undefined &&
        callLog === null &&
        currentIncident.event.location.propertyId !== undefined &&
        propertyFilterValue === null
      ) {
        setPropertyAsFilter(currentIncident.event.location.propertyId)
      }
    }
  }, [
    dispatch,
    firstLoadOfExistingCallLog,
    secondLoadOfExistingCallLog,
    buildingsSelector.status,
    apartmentsSelector.status,
    locationsSelector.status,
    tenantSelector.status,
    statesSelector.length,
    propertyFilterValue,
    sectionFilterValue,
    buildingFilterValue,
    locationFilterValue,
  ])

  const watchEventType = watch('event.type')
  const history = useHistory()

  const canEdit = () => {
    if (!isGeneralUser) return true
    if (isGeneralUser && defaultIncident.event.conditionId != 2) return true
    return false
  }

  const [showSaveError, setShowSaveError] = useState(false)
  const [saveError, setSaveError] = useState({
    title: '',
    content: '',
  })

  const closeSaveErrorAlert = () => {
    setShowSaveError(false)
    setSaveError({
      title: '',
      content: '',
    })
    loaded()
  }

  const { isValid } = formState
  const saveForm = () => {
    if (!isValid) {
      setOpenValidationMessage(true)
    }
  }

  const [openValidationMessage, setOpenValidationMessage] = useState(false)
  const handelCloseValidationMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationMessage(false)
  }

  return (
    <>
      <PageHeader pageName={header} />
      {dataLoaded ? (
        <>
          {canEdit() ? (
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(async (data) => {
                  setOpenValidationMessage(false)
                  loading()
                  data = ensureCorrectDataModel(data)
                  if (currentIncident.event.incidentId != undefined) {
                    incidentApi
                      .updateIncident(data, currentIncident.event.incidentId)
                      .then((incidentId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' + eventTypeEnum.incident + '/' + incidentId
                        )
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  } else {
                    incidentApi
                      .addIncident(data)
                      .then((incidentId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' + eventTypeEnum.incident + '/' + incidentId
                        )
                        setShowCreateAccidentButton(true)
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  }
                })}
              >
                {currentIncident.event.controlNumberReferenceTree && (
                  <LinkedReports
                    controlNumberReferenceTree={
                      currentIncident.event.controlNumberReferenceTree
                    }
                  />
                )}
                <div id='incident'>
                  {propertySelector.status === apiStatus.succeeded &&
                    propertySelector.properties.length == 0 && (
                      <Typography color='secondary' align='center' variant='h5'>
                        Please note: You currently do not have any properties
                        assigned to you. You can contact a CHIEF system admin
                        for your property access to be updated.
                      </Typography>
                    )}
                  <IncidentEventDetailsFields
                    defaultValues={{
                      ...currentIncident.event,
                      dateReported: new Date(
                        currentIncident.event.dateReported
                      ),
                      timeReported: new Date(
                        currentIncident.event.timeReported
                      ),
                      dateIncident: new Date(
                        currentIncident.event.dateOfIncident
                      ),
                      timeIncident: new Date(
                        currentIncident.event.timeOfIncident
                      ),
                    }}
                    //Lists for selects
                    eventTypes={eventTypeList}
                    eventConditions={eventConditions}
                    incidentClassificationCodes={
                      incidentClassificationCodesSelector
                    }
                    callClassificationCodes={callTypesSelector.callTypes}
                    callLogControlNumbers={
                      currentIncident.event.controlNumberReferenceTree
                        ? [
                            ...callLogControlNumbers.controlNumbers,
                            {
                              id: currentIncident.event
                                .controlNumberReferenceTree
                                .callLogControlNumber,
                              name: currentIncident.event
                                .controlNumberReferenceTree
                                .callLogControlNumber,
                            },
                          ]
                        : callLogControlNumbers.controlNumbers
                    }
                    incidentTypes={incidentTypeList}
                    setIncidentTypes={updateIncidentTypes}
                    eventTypesChangeHandler={eventTypeChange}
                    selectedEventTypes={incidentTypes.string}
                    callLogControlNumberChange={callLogControlNumberChange}
                    disableEventConditionField={isGeneralUser}
                  />
                  <LocationFields
                    defaultValues={currentIncident.event.location}
                    properties={propertySelector.properties}
                    propertyChangeHandler={setPropertyAsFilter}
                    sections={sectionsSelector.sections}
                    sectionChangeHandler={selectSectionAsFilter}
                    sectionDisabled={sectionDisabled}
                    buildings={buildingsSelector.buildings}
                    buildingChangeHandler={selectBuildingAsFilter}
                    buildingDisabled={buildingDisabled}
                    locations={locationsSelector.locations}
                    locationChangeHandler={selectLocationAsFilter}
                    locationDisabled={locationDisabled}
                    locationType={locationType}
                    apartments={apartmentsSelector.apartments}
                    floors={floorSelector.floors}
                    stores={storesSelector.stores}
                    parkingSpots={parkingSpotsSelector.parkingSpots}
                    disablePropertyChange={
                      complainantsWitnessesList.length > 0 ||
                      perpetratorsList.length > 0
                    }
                    propertyHelperText={
                      complainantsWitnessesList.length > 0 ||
                      perpetratorsList.length > 0
                        ? 'Complainants, Witnesses or Perpetrators must be removed to enable this field for edit.'
                        : null
                    }
                  />
                  <ComplainantsWitnessesFields
                    openDialog={handleOpenComplainantsWitnessesDialog}
                    list={complainantsWitnessesList}
                  />
                  {incidentTypes.perpetrators && (
                    <PerpetratorsFields
                      openDialog={handleOpenPerpetratorsDialog}
                      list={perpetratorsList}
                    />
                  )}
                  {incidentTypes.vehicles && (
                    <VehiclesFields
                      openDialog={handleOpenVehicleDialog}
                      list={vehiclesList}
                    />
                  )}
                  {incidentTypes.fire && (
                    <FireFields
                      defaultValues={currentIncident.event.fire || {}}
                    />
                  )}
                  {incidentTypes.flood && <FloodFields />}
                  {incidentTypes.eviction && <EvictionFields />}
                  {incidentTypes.moveInOut && <MoveInOutFields />}
                  {incidentTypes.property && (
                    <PropertyFields
                      openDialog={handleOpenPropertyDialog}
                      list={propertyList}
                    />
                  )}
                  <IncidentDetailsFields
                    addAttachments={handleAddAttachments}
                    attachments={attachments}
                    attachmentsIsLoading={attachmentsIsLoading}
                    setAttachmentsIsLoading={setAttachmentsIsLoading}
                    deleteAttachment={handleDeleteAttachment}
                    downloadAttachment={handleDownloadAttachment}
                  />
                  <ApprovalFields
                    defaultValues={currentIncident.event.incidentApproval}
                  />
                  {currentIncident.event.incidentId != undefined && (
                    <FormDetails
                      createdBy={currentIncident.event.insertedBy}
                      createdOn={currentIncident.event.insertedDate}
                      lastModifiedBy={currentIncident.event.updatedBy}
                      lastModifiedOn={currentIncident.event.updatedDate}
                    />
                  )}
                </div>
                <EventFormButtons
                  eventType={watchEventType}
                  reset={resetForm}
                  save={saveForm}
                  create={createAccident}
                  showCreateButton={showCreateAccidentButton}
                  download={() =>
                    incidentApi.getPdfReport(
                      currentIncident.event.incidentId,
                      currentIncident.event.incidentControlNumber
                    )
                  }
                  showDownload={showDownload}
                  disableDownload={isGeneralUser}
                />
              </form>
            </FormProvider>
          ) : (
            <>
              This event has been completed. Please contact a Super User or
              Admin.
            </>
          )}
          <ComplainantsWitnessesDialog
            open={complainantWitnessDialog.open}
            handleClose={handleCloseComplainantsWitnessesDialog}
            handleRemove={handleRemoveComplainantsWitnessesDialog}
            handleSave={handleSaveComplainantsWitnessesDialog}
            person={complainantWitnessDialog.person}
            propertyFilterValue={propertyFilterValue}
            tenantList={tenantSelector.tenants}
            stateList={statesSelector}
          />
          <PerpetratorsDialog
            open={perpetratorsDialog.open}
            handleClose={handleClosePerpetratorsDialog}
            handleRemove={handleRemovePerpetratorsDialog}
            handleSave={handleSavePerpetratorsDialog}
            person={perpetratorsDialog.person}
            propertyFilterValue={propertyFilterValue}
            tenantList={tenantSelector.tenants}
          />
          <VehicleDialog
            open={vehicleDialog.open}
            handleClose={handleCloseVehicleDialog}
            handleRemove={handleRemoveVehicleDialog}
            handleSave={handleSaveVehicleDialog}
            vehicle={vehicleDialog.vehicle}
            stateList={statesSelector}
          />
          <PropertyDialog
            open={propertyDialog.open}
            handleClose={handleClosePropertyDialog}
            handleRemove={handleRemovePropertyDialog}
            handleSave={handleSavePropertyDialog}
            property={propertyDialog.property}
          />
          <DeleteAttachmentAlert
            open={deleteAttachmentAlert.open}
            cancel={handleCancelDeleteAttachmentAlert}
            confirm={handleConfirmDeleteAttachmentAlert}
            attachment={deleteAttachmentAlert.attachment}
          />
          <ErrorAlertDialog
            open={showAttachmentUploadError}
            title={attachmentUploadError.title}
            content={attachmentUploadError.content}
            close={closeAttachmentErrorAlert}
          />
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
      <ErrorAlertDialog
        open={showSaveError}
        title={saveError.title}
        content={saveError.content}
        close={closeSaveErrorAlert}
      />
      <FormValidationMessage
        open={openValidationMessage}
        handleClose={handelCloseValidationMessage}
      />
    </>
  )
}

IncidentEvent.propTypes = {
  eventTypeChange: PropTypes.func.isRequired,
  incidentEvent: PropTypes.shape(),
  callLog: PropTypes.shape({
    event: {
      callLogNumber: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Date).isRequired,
      timeResponded: PropTypes.instanceOf(Date).isRequired,
    },
  }),
  setRecordEventLoaded: PropTypes.func,
  createAccidentFromIncident: PropTypes.func,
}

export default withRouter(IncidentEvent)

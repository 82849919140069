import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Popover, MenuItem, Grid, makeStyles } from '@material-ui/core'
import {
  Person as PersonIcon,
  ExpandMore as ExpandMoreIcon,
} from '@material-ui/icons'
import { selectTertiaryThemeColor } from '../../../admin/slices/themeColorSlice'
import { useSelector } from 'react-redux'

const ProfileIcon = ({ userName, logOutFunction }) => {
  const useStyles = makeStyles({
    menu: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
    },
    button: {
      color: 'white',
      textTransform: 'none',
      '&:hover': {
        background: 'none',
      },
    },
  })
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickLogout = () => {
    logOutFunction()
    handleClose()
  }

  return (
    <>
      <Button
        className={classes.button}
        onClick={handleClick}
        disableRipple={true}
        disableFocusRipple={true}
      >
        <Grid container justify='flex-end' spacing={0}>
          <Grid item xs={11}>
            <PersonIcon fontSize='large' />
          </Grid>
          <Grid item xs={1}>
            <ExpandMoreIcon fontSize='large' />
          </Grid>
          <Grid item xs={11}>
            {userName}
          </Grid>
          <Grid item xs={1} />
        </Grid>
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        elevation={2}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{ paper: classes.menu }}
      >
        <MenuItem onClick={handleClickLogout} className={classes.menu}>
          Sign out
        </MenuItem>{' '}
      </Popover>
    </>
  )
}

ProfileIcon.propTypes = {
  userName: PropTypes.string.isRequired,
  logOutFunction: PropTypes.func.isRequired,
}

export default ProfileIcon

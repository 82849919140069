import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  Drawer,
  makeStyles,
} from '@material-ui/core'
import {
  Home as HomeIcon,
  Menu as MenuIcon,
  Create as CreateIcon,
  Search as SearchIcon,
  SupervisorAccount as AdminIcon,
} from '@material-ui/icons'
import { useHistory, withRouter } from 'react-router-dom'
import {
  selectPrimaryThemeColor,
  selectSecondaryThemeColor,
  selectTertiaryThemeColor,
} from '../../../admin/slices/themeColorSlice'
import { selectCurrentUserRole } from '../../../admin/slices/currentUserSlice'

const NavigationMenu = () => {
  const useStyles = makeStyles({
    list: {
      width: 250,
      color: 'white',
    },
    listItem: {
      '&:hover': {
        background: useSelector(selectSecondaryThemeColor()),
      },
    },
    subListItem: {
      '&:hover': {
        background: useSelector(selectSecondaryThemeColor()),
      },
      paddingLeft: '20%',
    },
    menuIcon: {
      fill: 'white',
    },
    drawerPaper: {
      background: useSelector(selectPrimaryThemeColor()),
    },
    expandable: {
      '&:hover': {
        background: useSelector(selectTertiaryThemeColor()),
      },
    },
    button: {
      '&:hover': {
        background: 'none',
      },
    },
  })

  const classes = useStyles()
  const history = useHistory()
  const [state, setState] = useState({
    left: false,
  })
  const [subMenuExpanded, setSubMenuExpended] = useState('')

  var navItem1 = {
    key: '/',
    text: 'Home',
    icon: <HomeIcon className={classes.menuIcon} />,
  }

  var navItem2 = {
    key: '/recordEvent',
    text: 'Record New Event',
    icon: <CreateIcon className={classes.menuIcon} />,
  }

  var navItem3 = {
    key: '/searchEvents',
    text: 'Search Events',
    icon: <SearchIcon className={classes.menuIcon} />,
  }

  var navItem4 = {
    key: '/admin',
    text: 'Admin',
    icon: <AdminIcon className={classes.menuIcon} />,
    subMenu: [
      { key: '/manageLocations', text: 'Manage Locations' },
      { key: '/manageUsers', text: 'Manage Users' },
      { key: '/manageTenants', text: 'Manage Tenants' },
      { key: '/manageTheme', text: 'Manage Theme' },
    ],
  }

  const navigationItems = [navItem1, navItem2, navItem3]

  const userRole = useSelector(selectCurrentUserRole())
  if (userRole === 'Admin') {
    navigationItems.push(navItem4)
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setSubMenuExpended('')
    setState({ ...state, [anchor]: open })
  }

  const handleNavigationClick = (anchor, key) => {
    setState({ ...state, [anchor]: false })
    setSubMenuExpended('')
    history.push(key)
  }

  const list = (anchor) => (
    <>
      <List className={classes.list}>
        {navigationItems.map((item) => (
          <div key={item.key + '_container'}>
            {item.subMenu == undefined ? (
              <ListItem
                button
                key={item.key}
                onClick={() => handleNavigationClick(anchor, item.key)}
                className={classes.listItem}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            ) : (
              <>
                <ListItem
                  button
                  key={item.key}
                  onClick={() => {
                    subMenuExpanded != item.key
                      ? setSubMenuExpended(item.key)
                      : setSubMenuExpended('')
                  }}
                  className={classes.expandable}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
                {subMenuExpanded == item.key && (
                  <>
                    {item.subMenu.map((subItem) => (
                      <ListItem
                        button
                        key={subItem.key}
                        onClick={() =>
                          handleNavigationClick(anchor, subItem.key)
                        }
                        className={classes.subListItem}
                      >
                        <ListItemText primary={subItem.text} />
                      </ListItem>
                    ))}{' '}
                  </>
                )}
              </>
            )}
          </div>
        ))}
      </List>
    </>
  )

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            onClick={toggleDrawer(anchor, true)}
            className={classes.button}
          >
            <MenuIcon fontSize='large' className={classes.menuIcon} />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            classes={{ paper: classes.drawerPaper }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  )
}

export default withRouter(NavigationMenu)

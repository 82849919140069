import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/locations'

export function getApartments() {
  return fetch(baseUrl + '/apartments', {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateApartment(apartment) {
  const location = { apartment: apartment }
  const body = JSON.stringify(location)
  return fetch(baseUrl + '/apartments', {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function addApartment(apartment) {
  const location = { apartment: apartment }
  const body = JSON.stringify(location)
  return fetch(baseUrl + '/apartments', {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/locations'

export function getStores() {
  return fetch(baseUrl + '/stores', {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateStore(store) {
  const location = { store: store }
  const body = JSON.stringify(location)
  return fetch(baseUrl + '/stores', {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function addStore(store) {
  const location = { store: store }
  const body = JSON.stringify(location)
  return fetch(baseUrl + '/stores', {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as themeApi from '../../apiCalls/theme'

const initialState = {
  status: apiStatus.idle,
  error: null,
  themeColors: [],
}

const themeColorSlice = createSlice({
  name: 'themeColors',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadThemeColorsSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, themeColors: payload }
    },
    updateThemeColorSuccess(state, { payload }) {
      let updatedThemeColors = state.themeColors.map((themeColor) => {
        if (themeColor.themeColorId != payload.themeColorId) {
          return themeColor
        }
        return {
          ...themeColor,
          ...payload,
        }
      })

      return {
        ...state,
        themeColors: updatedThemeColors,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadThemeColorsSuccess,
  updateThemeColorSuccess,
} = themeColorSlice.actions

export const loadThemeColorsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await themeApi
    .getThemeColors()
    .then((themeColors) => {
      dispatch(loadThemeColorsSuccess(themeColors))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateThemeColorAsync = (themeColor) => async (dispatch) => {
  dispatch(beginApiCall())
  return await themeApi
    .updateThemeColor(themeColor)
    .then(() => dispatch(updateThemeColorSuccess(themeColor)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectThemeColors = () => (state) => state.themeColors

export const selectPrimaryThemeColor = () => (state) =>
  state.themeColors.themeColors.find((c) => c.name == 'primary').hexValue

export const selectSecondaryThemeColor = () => (state) =>
  state.themeColors.themeColors.find((c) => c.name == 'secondary').hexValue

export const selectTertiaryThemeColor = () => (state) =>
  state.themeColors.themeColors.find((c) => c.name == 'tertiary').hexValue

export default themeColorSlice.reducer

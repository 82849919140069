import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/call-log'

export function addCallLog(request) {
  const callLog = { callLog: request.event }
  const body = JSON.stringify(callLog)
  return fetch(baseUrl, {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateCallLog(request, callLogId) {
  const callLog = { callLog: request.event }
  callLog.callLog.callLogId = callLogId
  const body = JSON.stringify(callLog)
  return fetch(baseUrl, {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getCallLogById(id) {
  return fetch(baseUrl + '/' + id, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getUnreferencedCallLogControlNumbersForIncident(date) {
  return fetch(baseUrl + '/ControlNumbers/ForIncident/' + date, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getUnreferencedCallLogControlNumbersForUnusualIncident(date) {
  return fetch(baseUrl + '/ControlNumbers/ForUnusualIncident/' + date, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL

export function getCallLogsByFilter(filter) {
  var url = new URL(baseUrl + '/call-log')
  var params = filter

  url.search = new URLSearchParams(params).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getIncidentsByFilter(filter) {
  var url = new URL(baseUrl + '/incident')
  var params = filter

  url.search = new URLSearchParams(params).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getUnusualIncidentsByFilter(filter) {
  var url = new URL(baseUrl + '/unusualIncident')
  var params = filter

  url.search = new URLSearchParams(params).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getAccidentsByFilter(filter) {
  var url = new URL(baseUrl + '/accident')
  var params = filter

  url.search = new URLSearchParams(params).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getCallLogsReportData(filter) {
  var url = new URL(baseUrl + '/call-log/report')
  var params = filter

  url.search = new URLSearchParams(params).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getIncidentsReport(parameters, fileName) {
  var url = new URL(baseUrl + '/incident/report')
  url.search = new URLSearchParams(parameters).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  }).then((response) => {
    response.blob().then((blob) => {
      const link = document.createElement('a')
      const blobUrl = URL.createObjectURL(blob)
      link.href = blobUrl
      link.download = fileName
      link.click()
      link.remove()
    })
  })
}

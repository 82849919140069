import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import SectionHeader from '../../../common/components/molecules/SectionHeader'

import FormSelect from '../atoms/FormSelect'
import FormInput from '../atoms/FormInput'
import FormMaskedInput from '../atoms/FormMaskedInput'
import FormCheckbox from '../atoms/FormCheckbox'

import { phoneNumberFormat } from '../../../common/constants'

const CallerFields = ({
  callerOrigins,
  onAnonymousChange,
  onSctChange,
  disableAnonymous,
  disableSCT,
}) => (
  <>
    <SectionHeader sectionName='Caller' />
    <Grid
      container
      spacing={0}
      direction='row'
      alignItems='center'
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        width: '95%',
      }}
    >
      <Grid item xs={2}>
        <FormCheckbox
          name='event.caller.anonymous'
          label='Is Anonymous?'
          changeHandler={onAnonymousChange}
          disabled={disableAnonymous}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <FormCheckbox
          name='event.caller.sct'
          label='Is Resident?'
          changeHandler={onSctChange}
          disabled={disableSCT}
        />
      </Grid>
      <Grid item xs={7} />
      <Grid item xs={2}>
        <FormSelect
          name='event.caller.callOriginId'
          label='Origin of Call'
          options={callerOrigins}
          required={true}
          errorMessage={'Origin of Call field is required'}
          rules={{ required: true }}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <FormInput
          name='event.caller.name'
          label='Caller Name'
          required={true}
          errorMessage={'Caller Name field is required'}
          rules={{ required: true }}
          defaultValue={''}
        />
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={2}>
        <FormMaskedInput
          name='event.caller.phone'
          label='Phone No.'
          mask={phoneNumberFormat.mask}
          maskChar='_'
          rules={{
            validate: (value) => {
              value = value.replace('_', '')
              return (
                value.length === 0 || value.length === phoneNumberFormat.length
              )
            },
          }}
          defaultValue={''}
        />
      </Grid>
    </Grid>
  </>
)

CallerFields.propTypes = {
  callerOrigins: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onAnonymousChange: PropTypes.func.isRequired,
  onSctChange: PropTypes.func.isRequired,
  disableAnonymous: PropTypes.bool.isRequired,
  disableSCT: PropTypes.bool.isRequired,
}

export default CallerFields

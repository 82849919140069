import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

const TextArea = ({
  label,
  rows,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
}) => (
  <TextField
    label={label}
    multiline
    rows={rows}
    value={value}
    onChange={onChange}
    error={invalid}
    helperText={invalid ? errorMessage : ' '}
    InputLabelProps={{
      className: required ? 'required-label' : '',
      required: required || false,
    }}
    fullWidth={true}
    variant='outlined'
  />
)

TextArea.propTypes = {
  label: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
}

const FormInput = ({
  name,
  label,
  rows,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = '',
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ onChange, value }, { invalid }) => (
        <TextArea
          label={label}
          rows={rows}
          value={value}
          onChange={onChange}
          required={required}
          invalid={invalid}
          errorMessage={errorMessage}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

FormInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  rows: PropTypes.number.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
}

export default FormInput

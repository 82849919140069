export const dateFormat = 'MM/dd/yyyy'

export const dateTimeFormat = 'MM/dd/yyyy hh:mm:ss'

export const dateTimeFormatForFileNames = 'MM.dd.yyyy hh.mm.ss'

export const timeFormat = 'hh:mm:ss'

export const phoneNumberFormat = { mask: '(###) ###-####', length: 14 }

export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const locationTypes = ['Apartment', 'Floor', 'Garage', 'Store']

export const eventTypes = [
  { id: 1, name: 'Call Log' },
  { id: 2, name: 'Incident' },
  { id: 3, name: 'Unusual Incident' },
  { id: 4, name: 'Accident' },
]

export const vehicleIssueTypes = [
  { id: 1, name: 'Larceny' },
  { id: 2, name: 'Mischief' },
  { id: 3, name: 'Used in a crime' },
  { id: 4, name: 'Illegally Parked' },
  { id: 5, name: 'Abandoned' },
]

export const races = [
  { id: 1, name: 'African American' },
  { id: 2, name: 'American Indian / Alaska Native' },
  { id: 3, name: 'Arab' },
  { id: 4, name: 'Asian' },
  { id: 5, name: 'Hispanic / Latino' },
  { id: 6, name: 'White' },
  { id: 7, name: 'Mixed / Other Group' },
]

export const sexes = [
  { id: 1, name: 'Male' },
  { id: 2, name: 'Female' },
]

export const propertyIssueTypes = [
  { id: 1, name: 'Lost' },
  { id: 2, name: 'Stolen' },
  { id: 3, name: 'Vouchered' },
  { id: 4, name: 'Recovered' },
]

export const belongingUses = [
  { id: 1, name: 'Business' },
  { id: 2, name: 'Personal' },
  { id: 3, name: 'Both' },
]

export const roles = {
  admin: 'Admin',
  generalUser: 'General User',
  superUser: 'Super User',
}

export const personInvolvedTypes = [
  { id: 1, name: 'Injured Person' },
  { id: 2, name: 'Witness' },
  { id: 3, name: 'Owner of Damaged Property' },
]

export const spinnerSize = 75

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormCheckbox from '../atoms/FormCheckbox'
import FormInput from '../atoms/FormInput'
import FormSelect from '../atoms/FormSelect'
import FormTextArea from '../atoms/FormTextArea'
import Attachments from './Attachments'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const AccidentDetailsFields = ({
  defaultValues = {},
  jobReceivedByList = [],
  addAttachments,
  attachments,
  attachmentsIsLoading,
  setAttachmentsIsLoading,
  deleteAttachment,
  downloadAttachment,
}) => {
  const classes = useStyles()
  const { watch } = useFormContext()

  const watchReportingOfficerWasWitness = watch(
    'event.accidentDetail.reportingOfficerWasWitness',
    defaultValues.reportingOfficerWasWitness || false
  )
  const watchPoliceOnScene = watch(
    'event.accidentDetail.policeOnScene',
    defaultValues.policeOnScene || false
  )

  const watchAdditionalInformationSource = watch(
    'event.accidentDetail.additionalInformationSource',
    defaultValues.additionalInformationSource || ''
  )

  const watchAdditionalReports = watch('event.accidentDetail.attachReports')

  return (
    <>
      <SectionHeader sectionName='Details' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={4}>
          <FormCheckbox
            name='event.accidentDetail.reportingOfficerWasWitness'
            label='Reporting Officer Witnessed Accident'
            defaultValue={defaultValues.reportingOfficerWasWitness || false}
          />
        </Grid>
        <Grid item xs={8}>
          {watchReportingOfficerWasWitness && (
            <FormInput
              name='event.accidentDetail.reportingOfficerWasWitnessExplanation'
              label='Explanation'
              required={true}
              errorMessage='Explanation field is required.'
              rules={{ required: true }}
              defaultValue={
                defaultValues.reportingOfficerWasWitnessExplanation || ''
              }
            />
          )}
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name='event.accidentDetail.jobReceivedById'
            label='Job Received By'
            options={jobReceivedByList}
            required={true}
            errorMessage='Job Received By fields is required.'
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.accidentDetail.aidedNumber'
            label='Aided No.(s) (if any)'
            required={true}
            errorMessage='Explanation field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.aidedNumber || ''}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.accidentDetail.photographsTaken'
            label='Photographs Taken'
            defaultValue={defaultValues.photographsTaken || false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.accidentDetail.photographsAttached'
            label='Photographs Attached'
            defaultValue={defaultValues.photographsAttached || false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.accidentDetail.maintenanceNotified'
            label='Maintenance Notified'
            defaultValue={defaultValues.maintenanceNotified || false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.accidentDetail.policeOnScene'
            label='Police on Scene'
            defaultValue={defaultValues.policeOnScene || false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          {watchPoliceOnScene && (
            <FormInput
              name='event.accidentDetail.policeIdentification'
              label='Identify'
              required={true}
              errorMessage='Identification field is required.'
              rules={{ required: true }}
              defaultValue={defaultValues.policeIdentification || ''}
            />
          )}
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={7}>
          <FormInput
            name='event.accidentDetail.informationSource'
            label='Where was information obtained (at scene, apartment, etc.)'
            defaultValue={defaultValues.informationSource || ''}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <FormInput
            name='event.accidentDetail.sceneExaminator'
            label='Scene Examined By'
            defaultValue={defaultValues.sceneExaminator || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.accidentDetail.sceneExaminatorRank'
            label='Rank'
            defaultValue={defaultValues.sceneExaminatorRank || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.accidentDetail.sceneExaminatorName'
            label='Name'
            defaultValue={defaultValues.sceneExaminatorName || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <FormInput
            name='event.accidentDetail.sceneExaminatorShieldNumber'
            label='Shield No.'
            defaultValue={defaultValues.sceneExaminatorShieldNumber || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='event.accidentDetail.additionalInformationSource'
            label='Source of information if other than from injured'
            defaultValue={defaultValues.additionalInformationSource || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={5}>
          {watchAdditionalInformationSource != '' && (
            <FormInput
              name='event.accidentDetail.additionalSourceRelationshipWithInjured'
              label={"Source's relationship to injured"}
              required={true}
              errorMessage={"Source's Relation field is required."}
              rules={{ required: true }}
              defaultValue={
                defaultValues.additionalSourceRelationshipWithInjured || ''
              }
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <FormTextArea
            name='event.accidentDetail.comments'
            label='Comments (include observations and also record all other pertinent information not recorded elsewhere on this report)'
            rows={10}
            defaultValue={defaultValues.comments || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckbox
            name='event.accidentDetail.attachReports'
            label='Attach additional reports'
            // defaultValue={false}
          />
        </Grid>
        {watchAdditionalReports && (
          <Grid item xs={12}>
            <Attachments
              attachments={attachments}
              addAttachments={addAttachments}
              isLoading={attachmentsIsLoading}
              setIsLoading={setAttachmentsIsLoading}
              deleteAttachment={deleteAttachment}
              downloadAttachment={downloadAttachment}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

AccidentDetailsFields.propTypes = {
  defaultValues: PropTypes.shape(),
  jobReceivedByList: PropTypes.array.isRequired,
  addAttachments: PropTypes.func,
  attachments: PropTypes.array,
  attachmentsIsLoading: PropTypes.bool,
  setAttachmentsIsLoading: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
}

export default AccidentDetailsFields

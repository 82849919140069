import React from 'react'
import PropTypes from 'prop-types'
import { Paper, Button, makeStyles } from '@material-ui/core'
import { selectSecondaryThemeColor } from '../../../admin/slices/themeColorSlice'
import { useSelector } from 'react-redux'

const ImageUploader = ({ label, image, setImageFunction }) => {
  const useStyles = makeStyles({
    center: {
      textAlign: 'center',
      padding: 15,
    },
    button: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
  })

  const classes = useStyles()

  const loadImage = (e) => {
    let file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded.bind(this)
      reader.readAsBinaryString(file)
    }
  }

  const handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result
    setImageFunction('data:image/png;base64,' + btoa(binaryString))
  }

  return (
    <Paper>
      <label>{label}</label>
      <div className={classes.center}>
        <img style={{ width: '50%' }} src={image} />
      </div>
      <div className={classes.center}>
        <Button
          variant='contained'
          component='label'
          className={classes.button}
        >
          Select File
          <input accept='.png' type='file' onChange={loadImage} hidden />
        </Button>
      </div>
    </Paper>
  )
}

ImageUploader.propTypes = {
  label: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  setImageFunction: PropTypes.func.isRequired,
}

export default ImageUploader

import { createSlice } from '@reduxjs/toolkit'
import * as eventPlatoonsApi from '../../apiCalls/eventPlatoon'

const eventPlatoonsSlice = createSlice({
  name: 'eventPlatoons',
  initialState: [],
  reducers: {
    loadEventPlatoons(_, action) {
      return action.payload
    },
  },
})

const { loadEventPlatoons } = eventPlatoonsSlice.actions

export const loadEventPlatoonsAsync = () => async (dispatch) => {
  return await eventPlatoonsApi
    .getEventPlatoons()
    .then((eventPlatoons) => {
      dispatch(loadEventPlatoons(eventPlatoons))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectEventPlatoons = (state) => state.eventPlatoons

export default eventPlatoonsSlice.reducer

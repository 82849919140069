import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

import FormInput from '../atoms/FormInput'
import FormEventTypeSelect from '../atoms/FormEventTypeSelect'
import FormSelect from '../atoms/FormSelect'
import FormDatePicker from '../atoms/FormDatePicker'
import FormTimePicker from '../atoms/FormTimePicker'
import FormSectionMultiSelect from '../atoms/FormSectionMultiSelect'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const EventFields = ({
  defaultValues,
  eventTypes,
  eventConditions,
  incidentClassificationCodes,
  callClassificationCodes,
  callLogControlNumbers,
  incidentTypes,
  setIncidentTypes,
  eventTypesChangeHandler,
  selectedEventTypes,
  callLogControlNumberChange,
  disableEventConditionField = false,
}) => {
  const classes = useStyles()

  return (
    <>
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={2}>
          <FormEventTypeSelect
            name='event.type'
            label='Event Type'
            options={eventTypes}
            required={true}
            errorMessage={'Event Type field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.type || ''}
            changeHandler={eventTypesChangeHandler}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.preparedBy'
            label='Prepared By'
            required={true}
            errorMessage='Prepared By field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.preparedBy || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.shieldNumber'
            label='Shield No.'
            required={true}
            errorMessage='Shield No. field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.shieldNumber || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.conditionId'
            label='Event Condition'
            options={eventConditions}
            required={true}
            errorMessage={'Event Condition field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.condition || ''}
            disabled={disableEventConditionField}
          />
        </Grid>
        <Grid item xs={2}>
          <FormDatePicker
            name='event.dateReported'
            label='Date Reported'
            required={true}
            errorMessage='A valid date is required'
            showDay={true}
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeReported'
            label='Time Reported'
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={2}>
          <FormDatePicker
            name='event.dateOfIncident'
            label='Date of Incident'
            required={true}
            errorMessage='A valid date is required'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeOfIncident'
            label='Time of Incident'
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={2}>
          <FormSelect
            name='event.incidentClassificationCodeId'
            label='Incident Code'
            options={incidentClassificationCodes}
            required={true}
            errorMessage={'Incident Code field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.incidentCode || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormSelect
            name='event.callTypeId'
            label='Classification'
            options={callClassificationCodes}
            required={true}
            errorMessage={'Classification field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.callClassificationCode || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.callLogControlNumber'
            label='Call Log Control Number'
            options={callLogControlNumbers}
            required={true}
            errorMessage={'Call Log Control Number field is required'}
            rules={{ required: true }}
            defaultValue={''}
            onChangeFunction={callLogControlNumberChange}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={6}>
          <FormSectionMultiSelect
            label='Type of Incident(s)'
            incidentTypes={incidentTypes}
            updateIncidentTypes={setIncidentTypes}
            selectedSections={
              selectedEventTypes !== '' ? selectedEventTypes.split(',') : []
            }
          />
        </Grid>
      </Grid>
    </>
  )
}

EventFields.propTypes = {
  defaultValues: PropTypes.shape({
    type: PropTypes.number,
    preparedBy: PropTypes.string,
    shieldNumber: PropTypes.string,
    condition: PropTypes.number,
    dateReported: PropTypes.instanceOf(Date),
    timeReported: PropTypes.instanceOf(Date),
    dateIncident: PropTypes.instanceOf(Date),
    timeIncident: PropTypes.instanceOf(Date),
    incidentCode: PropTypes.number,
    callClassificationCode: PropTypes.number,
    callLogControlNumber: PropTypes.string,
  }),
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  eventConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  incidentClassificationCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  timeChangeHandler: PropTypes.func,
  callClassificationCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  callLogControlNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  incidentTypes: PropTypes.array.isRequired,
  setIncidentTypes: PropTypes.func,
  eventTypesChangeHandler: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  callLogControlNumberChange: PropTypes.func.isRequired,
  disableEventConditionField: PropTypes.bool,
}

export default EventFields

import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import FormSelect from '../atoms/FormSelect'
import FormInput from '../atoms/FormInput'
import FormMaskedInput from '../atoms/FormMaskedInput'
import { propertyIssueTypes, belongingUses } from '../../../common/constants'
import { useSelector } from 'react-redux'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'

const PropertyDialog = ({
  open,
  handleClose,
  handleRemove,
  handleSave,
  property = {},
}) => {
  const useStyles = makeStyles({
    closeButton: {
      position: 'absolute',
      right: '0px',
    },
    buttonSave: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      float: 'right',
    },
  })
  const classes = useStyles()

  const methods = useForm()
  const { watch, handleSubmit } = methods

  const watchIssueType = watch('issueType')
  //TODO: be better
  const requireUseType = watchIssueType === 2

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>
        Property
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={0} direction='row' alignItems='center'>
              <Grid item xs={2}>
                <FormInput
                  name='item'
                  label='Item'
                  required={true}
                  errorMessage={'Item field is required'}
                  rules={{ required: true }}
                  defaultValue={property.item || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormMaskedInput
                  name='quantity'
                  label='Quantity'
                  required={true}
                  errorMessage={'Quantity field is required'}
                  rules={{ required: true }}
                  defaultValue={property.quantity || '1'}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormMaskedInput
                  name='estimatedValue'
                  label='Estimated Value'
                  prefix='$'
                  required={true}
                  errorMessage={'Estimated Value field is required'}
                  rules={{ required: true }}
                  defaultValue={property.estimatedValue || ''}
                />
              </Grid>
              <Grid item xs={4} />
              <Grid item xs={2}>
                <FormSelect
                  name='belongingIncidentTypeId'
                  label='Issue Type'
                  options={propertyIssueTypes}
                  required={true}
                  errorMessage={'Issue Type field is required'}
                  rules={{ required: true }}
                  defaultValue={property.belongingIncidentTypeId || null}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormSelect
                  name='belongingUseId'
                  label='Use Type'
                  options={belongingUses}
                  required={requireUseType}
                  errorMessage={requireUseType ? 'Use field is required' : ''}
                  rules={{ required: requireUseType }}
                  defaultValue={property.belongingUseId || null}
                />
              </Grid>
              <Grid item xs={7} />
              <Grid item xs={8}>
                <FormInput
                  name='description'
                  label='Description (Brand, Model, Serial No.)'
                  defaultValue={property.description || ''}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    startIcon={<RemoveCircleOutlineIcon />}
                    fullWidth={true}
                    onClick={() => {
                      handleRemove()
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={2}>
                  <Button
                    className={classes.buttonSave}
                    variant='contained'
                    startIcon={<AddIcon />}
                    fullWidth={true}
                    onClick={handleSubmit((data) => {
                      handleSave(data)
                    })}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

PropertyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  property: PropTypes.shape({
    itemName: PropTypes.string,
    quantity: PropTypes.string,
    issueType: PropTypes.number,
    useType: PropTypes.number,
    description: PropTypes.string,
    estimatedValue: PropTypes.string,
  }),
}

export default PropertyDialog

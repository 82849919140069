import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import {
  makeStyles,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import FormSelect from '../atoms/FormSelect'
import FormTenantSelect from '../atoms/FormTenantSelect'
import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'
import FormDatePicker from '../atoms/FormDatePicker'
import FormMaskedInput from '../atoms/FormMaskedInput'
import FormTextArea from '../atoms/FormTextArea'
import { parseISO } from 'date-fns'
import { useSelector } from 'react-redux'

import { selectSectionsWithFilter } from '../../../admin/slices/sectionSlice'
import { selectBuildingsWithFilter } from '../../../admin/slices/buildingSlice'
import { selectLocationIdForApartmentWithBuildingFilter } from '../../../admin/slices/locationSlice'
import { selectApartmentsWithFilter } from '../../../admin/slices/apartmentSlice'

import FormLocationSelect from '../atoms/FormLocationSelect'

import { phoneNumberFormat, races, sexes } from '../../../common/constants'
import { selectStates } from '../../slices/stateCodesSlice'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'

const PerpetratorsDialog = ({
  open,
  handleClose,
  handleRemove,
  handleSave,
  person = {},
  propertyFilterValue,
  tenantList,
}) => {
  const useStyles = makeStyles({
    closeButton: {
      position: 'absolute',
      right: '0px',
    },
    buttonSave: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      float: 'right',
    },
  })
  const classes = useStyles()

  const methods = useForm()
  const [loaded, setLoaded] = useState(false)
  const { watch, handleSubmit, setValue } = methods

  const watchIsTenant = watch('isTenant', person.isTenant || false)

  const savedSection = person.isTenant ? person.sectionId : null
  const savedBuilding = person.isTenant ? person.buildingId : null
  const savedApartment = person.isTenant ? person.apartmentId : null

  const [sectionFilterValue, setSectionFilterValue] = useState(person.sectionId)
  const [buildingFilterValue, setBuildingFilterValue] = useState(
    person.buildingId
  )
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(true, propertyFilterValue, savedSection)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(true, sectionFilterValue, savedBuilding)
  )
  const locationSelector = useSelector(
    selectLocationIdForApartmentWithBuildingFilter(buildingFilterValue)
  )
  const apartmentSelector = useSelector(
    selectApartmentsWithFilter(
      true,
      locationSelector.location ? locationSelector.location.id : null,
      savedApartment
    )
  )

  const statesSelector = useSelector(selectStates)

  const setTenantInfo = (tenant) => {
    if (tenant) {
      setSectionFilterValue(tenant.sectionId)
      setBuildingFilterValue(tenant.buildingId)
      setValue('sectionId', tenant.sectionId)
      setValue('buildingId', tenant.buildingId)
      setValue('apartmentId', tenant.apartmentId)
      setValue('firstName', tenant.firstName)
      setValue('lastName', tenant.lastName)
      if (tenant.mi) setValue('mi', tenant.mi)
      if (tenant.telephoneNumber)
        setValue('telephoneNumber', tenant.telephoneNumber)

      let addressString = ''
      if (tenant.address) addressString += tenant.address + ', '
      if (tenant.city) addressString += tenant.city + ', '
      if (tenant.stateId)
        addressString +=
          statesSelector.find((s) => s.id == tenant.stateId).name + ', '
      if (tenant.zip) addressString += tenant.zip + ', '
      if (addressString)
        setValue(
          'address',
          addressString.substring(0, addressString.length - 2)
        )
    }
  }

  useEffect(async () => {
    if (!loaded && (watchIsTenant || person.isTenant)) {
      setSectionFilterValue(person.sectionId)
      setBuildingFilterValue(person.buildingId)
      setLoaded(true)
    }
  }, [watchIsTenant])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>
        Perpetrators
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={0} direction='row' alignItems='center'>
              <Grid item xs={2}>
                <FormCheckbox
                  name='isTenant'
                  label='Is Tenant?'
                  defaultValue={person.isTenant || false}
                />
              </Grid>
              <Grid item xs={3}>
                <FormInput
                  name='arrestNumber'
                  label='Arrest No.'
                  defaultValue={person.arrestNumber || ''}
                />
              </Grid>
              <Grid item xs={7} />
              {watchIsTenant && (
                <>
                  <Grid item xs={3}>
                    <FormTenantSelect
                      name='tenantId'
                      label='Tenant'
                      defaultValue=''
                      options={tenantList}
                      onChangeHandler={setTenantInfo}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <FormLocationSelect
                      name='sectionId'
                      label='Section'
                      options={sectionsSelector.sections}
                      required={watchIsTenant}
                      errorMessage={'Section field is required.'}
                      rules={{ required: watchIsTenant }}
                      defaultValue={person.sectionId || ''}
                      onChangeHandler={setSectionFilterValue}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <FormLocationSelect
                      name='buildingId'
                      label='Building'
                      options={buildingsSelector.buildings}
                      required={watchIsTenant}
                      errorMessage={'Building field is required.'}
                      rules={{ required: watchIsTenant }}
                      defaultValue={person.buildingId || ''}
                      onChangeHandler={setBuildingFilterValue}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <FormSelect
                      name='apartmentId'
                      label='Apartment'
                      options={apartmentSelector.apartments}
                      defaultValue={person.apartmentId || null}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={3}>
                <FormInput
                  name='firstName'
                  label='First Name'
                  required={true}
                  errorMessage={'First Name field is required'}
                  rules={{ required: true }}
                  defaultValue={person.firstName || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormInput
                  name='lastName'
                  label='Last Name'
                  required={true}
                  errorMessage={'Last Name field is required'}
                  rules={{ required: true }}
                  defaultValue={person.lastName || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormInput
                  name='mi'
                  label='MI'
                  defaultValue={person.mi || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormDatePicker
                  name='dateOfBirth'
                  label='Date of Birth'
                  errorMessage='A valid date is required'
                  rules={{
                    validate: (value) => !value || !isNaN(Date.parse(value)),
                  }}
                  defaultValue={
                    person.dateOfBirth
                      ? typeof person.dateOfBirth == 'string'
                        ? parseISO(person.dateOfBirth)
                        : person.dateOfBirth
                      : null
                  }
                  showDay={false}
                />
              </Grid>
              <Grid item xs={9}>
                <FormInput
                  name='address'
                  label='Address (include City, State, Zip)'
                  required={true}
                  errorMessage={'Address field is required'}
                  rules={{ required: true }}
                  defaultValue={person.address || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormMaskedInput
                  name='telephoneNumber'
                  label='Telephone No.'
                  mask={phoneNumberFormat.mask}
                  maskChar='_'
                  required={false}
                  rules={{
                    validate: (value) => {
                      value = value.replace('_', '')
                      return (
                        value.length === 0 ||
                        value.length === phoneNumberFormat.length
                      )
                    },
                  }}
                  defaultValue={person.telephoneNumber || ''}
                />
              </Grid>
              <Grid item xs={2}>
                <FormSelect
                  name='sexId'
                  label='Sex'
                  options={sexes}
                  required={true}
                  errorMessage={'Sex field is required.'}
                  rules={{ required: true }}
                  defaultValue={person.sexId || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormSelect
                  name='raceId'
                  label='Race'
                  options={races}
                  defaultValue={person.raceId || null}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormMaskedInput
                  name='height'
                  label='Height'
                  suffix={' ft'}
                  defaultValue={person.height || null}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={1}>
                <FormMaskedInput
                  name='weight'
                  label='Weight'
                  suffix={' lb'}
                  defaultValue={person.weight || null}
                />
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={2}>
                <FormInput
                  name='eyes'
                  label='Eyes'
                  defaultValue={person.eyes || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormInput
                  name='hair'
                  label='Hair'
                  defaultValue={person.hair || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormInput
                  name='hairLength'
                  label='Hair Length'
                  defaultValue={person.hairLength || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormInput
                  name='facialHair'
                  label='Facial Hair'
                  defaultValue={person.facialHair || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormCheckbox
                  name='glasses'
                  label='Glasses'
                  defaultValue={person.glasses || false}
                />
              </Grid>
              <Grid item xs={2}>
                <FormCheckbox
                  name='accent'
                  label='Accent'
                  defaultValue={person.accent || false}
                />
              </Grid>
              <Grid item xs={7} />
              <Grid item xs={12}>
                <FormTextArea
                  name='otherDistinguishingMarks'
                  label='Clothing & Distinguishing Marks (Tatoos, scars, etc.)'
                  rows={2}
                  defaultValue={person.otherDistinguishingMarks || ''}
                />
              </Grid>
            </Grid>
            <DialogActions>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    startIcon={<RemoveCircleOutlineIcon />}
                    fullWidth={true}
                    onClick={() => {
                      handleRemove()
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={2}>
                  <Button
                    className={classes.buttonSave}
                    variant='contained'
                    startIcon={<AddIcon />}
                    fullWidth={true}
                    onClick={handleSubmit((data) => {
                      handleSave(data)
                    })}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

PerpetratorsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  person: PropTypes.shape({
    isTenant: PropTypes.bool,
    arrestNumber: PropTypes.string,
    section: PropTypes.number,
    building: PropTypes.number,
    apartment: PropTypes.any,
    tenantDropDown: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    mi: PropTypes.string,
    dateOfBirth: PropTypes.instanceOf(Date),
    phoneNumber: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    sex: PropTypes.number,
    race: PropTypes.number,
    height: PropTypes.string,
    weight: PropTypes.string,
    eyes: PropTypes.string,
    hair: PropTypes.string,
    hairLength: PropTypes.string,
    facialHair: PropTypes.string,
    glasses: PropTypes.bool,
    accent: PropTypes.bool,
    otherDetails: PropTypes.string,
  }),
  propertyFilterValue: PropTypes.number,
  tenantList: PropTypes.array,
}

export default PerpetratorsDialog

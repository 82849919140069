import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as tenantsApi from '../../apiCalls/tenant'

const initialState = {
  status: apiStatus.idle,
  error: null,
  tenants: [],
}

const tenantsSlice = createSlice({
  name: 'tenants',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadTenantsSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, tenants: payload }
    },
  },
})

const { beginApiCall, apiCallError, loadTenantsSuccess } = tenantsSlice.actions

export const loadTenantsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await tenantsApi
    .getTenants()
    .then((tenants) => {
      dispatch(loadTenantsSuccess(tenants))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

const selectTenants = (state) => state.tenants

export const selectTenantsByPropertyId = (propertyId) => (state) => {
  var { tenants } = selectTenants(state)
  return {
    ...selectTenants(state),
    tenants: tenants
      .filter((t) => t.propertyId === propertyId)
      .sort((a, b) => {
        var x = a.firstName.toLowerCase()
        var y = b.firstName.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default tenantsSlice.reducer

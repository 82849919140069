import React, { useState } from 'react'
import ColorPicker from '../../components/molecules/ColorPicker'
import ImageUploader from '../molecules/ImageUploader'
import { Grid, makeStyles, Button } from '@material-ui/core'
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons'
import { grey } from '@material-ui/core/colors'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateThemeColorAsync,
  selectPrimaryThemeColor,
  selectSecondaryThemeColor,
  selectTertiaryThemeColor,
  selectThemeColors,
} from '../../slices/themeColorSlice'
import {
  updateThemeImageAsync,
  selectHeaderImage,
  selectLandingPageImage,
  selectThemeImages,
} from '../../slices/themeImageSlice'

const ManageColorsAndImages = () => {
  const currentColor = {
    primary: useSelector(selectPrimaryThemeColor()),
    secondary: useSelector(selectSecondaryThemeColor()),
    tertiary: useSelector(selectTertiaryThemeColor()),
  }
  const currentImages = {
    header: useSelector(selectHeaderImage()),
    landingPage: useSelector(selectLandingPageImage()),
  }
  const themeColorSelector = useSelector(selectThemeColors())
  const themeImageSelector = useSelector(selectThemeImages())

  const dispatch = useDispatch()
  const [color, setColor] = useState(currentColor)
  const [images, setImages] = useState(currentImages)

  const useStyles = makeStyles({
    root: {
      width: '90%',
      margin: 'auto',
      padding: 30,
    },
    center: {
      textAlign: 'center',
      padding: 15,
    },
    header: {
      textAlign: 'left',
      color: grey[50],
      backgroundColor: currentColor.primary,
      padding: 15,
    },
    save: {
      textAlign: 'right',
    },
    cancelButton: {
      color: grey[50],
      backgroundColor: grey[800],
      padding: 15,
    },
    saveButton: {
      color: grey[50],
      backgroundColor: currentColor.tertiary,
      padding: 15,
    },
  })
  const classes = useStyles()

  const setPrimaryColor = (newColor) => {
    setColor({ ...color, primary: newColor })
  }

  const setSecondaryColor = (newColor) => {
    setColor({ ...color, secondary: newColor })
  }

  const setTertiaryColor = (newColor) => {
    setColor({ ...color, tertiary: newColor })
  }

  const setHeaderImage = (image) => {
    setImages({ ...images, header: image })
  }

  const setLandingPageImage = (image) => {
    setImages({ ...images, landingPage: image })
  }

  const saveTheme = () => {
    if (currentColor.primary != color.primary) {
      dispatch(
        updateThemeColorAsync({
          ...themeColorSelector.themeColors.find((c) => c.name === 'primary'),
          hexValue: color.primary,
        })
      )
    }
    if (currentColor.secondary != color.secondary) {
      dispatch(
        updateThemeColorAsync({
          ...themeColorSelector.themeColors.find((c) => c.name === 'secondary'),
          hexValue: color.secondary,
        })
      )
    }
    if (currentColor.tertiary != color.tertiary) {
      dispatch(
        updateThemeColorAsync({
          ...themeColorSelector.themeColors.find((c) => c.name === 'tertiary'),
          hexValue: color.tertiary,
        })
      )
    }
    if (currentImages.header != images.header) {
      dispatch(
        updateThemeImageAsync({
          ...themeImageSelector.themeImages.find((i) => i.name == 'header'),
          image: images.header,
        })
      )
    }
    if (currentImages.landingPage != images.landingPage) {
      dispatch(
        updateThemeImageAsync({
          ...themeImageSelector.themeImages.find(
            (i) => i.name == 'landingPage'
          ),
          image: images.landingPage,
        })
      )
    }
  }

  const reset = () => {
    setColor(currentColor)
    setImages(currentImages)
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <h5 className={classes.header}>Color Palette</h5>
        </Grid>
        <Grid item xs={4} className={classes.center}>
          <ColorPicker
            label='Primary Color'
            color={color.primary}
            setColor={setPrimaryColor}
          />
        </Grid>
        <Grid item xs={4} className={classes.center}>
          <ColorPicker
            label='Secondary Color'
            color={color.secondary}
            setColor={setSecondaryColor}
          />
        </Grid>
        <Grid item xs={4} className={classes.center}>
          <ColorPicker
            label='Tertiary Color'
            color={color.tertiary}
            setColor={setTertiaryColor}
          />
        </Grid>

        <Grid item xs={12}>
          <h5 className={classes.header}>Images</h5>
        </Grid>

        <Grid item xs={4} className={classes.center}>
          <ImageUploader
            label='Header Image'
            image={images.header}
            setImageFunction={setHeaderImage}
          />
        </Grid>
        <Grid item xs={4} className={classes.center}>
          <ImageUploader
            label='Landing Page Image'
            image={images.landingPage}
            setImageFunction={setLandingPageImage}
          />
        </Grid>

        <Grid item xs={10} className={classes.save}>
          <Button
            className={classes.cancelButton}
            variant='contained'
            startIcon={<CancelIcon />}
            onClick={() => reset()}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={2} className={classes.save}>
          <Button
            className={classes.saveButton}
            variant='contained'
            startIcon={<SaveIcon />}
            onClick={saveTheme}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

export default ManageColorsAndImages

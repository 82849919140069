//React imports
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
//Component imports
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkedReports from '../molecules/LinkedReports'
import CallLogEventDetailsFields from '../molecules/CallLogEventDetailsFields'
import LocationFields from '../molecules/LocationFields'
import CallerFields from '../molecules/CallerFields'
import ResponseDetailsFields from '../molecules/ResponseDetailsFields'
import FormDetails from '../molecules/FormDetails'
import EventFormButtons from '../molecules/EventFormButtons'
//Store imports
import { useDispatch, useSelector } from 'react-redux'
import {
  loadEventDispatchedStatusAsync,
  selectEventDispatchedStatus,
} from '../../slices/eventDispatchedStatusSlice'
import {
  loadEventConditionsAsync,
  selectEventConditions,
} from '../../slices/eventConditionsSlice'
import {
  loadEventPlatoonsAsync,
  selectEventPlatoons,
} from '../../slices/eventPlatoonsSlice'
import {
  loadCallerOriginsAsync,
  selectCallerOrigins,
} from '../../slices/callerOriginsSlice'
import {
  loadCallTypesAsync,
  selectOrderedCallTypes,
} from '../../slices/callTypesSlice'
import {
  loadDispositionCodesAsync,
  selectDispositionCodes,
} from '../../slices/dispositionCodesSlice'
import {
  loadPropertiesAsync,
  selectActiveUserProperties,
} from '../../../admin/slices/propertySlice'
import {
  selectSectionsWithFilter,
  loadSectionsAsync,
} from '../../../admin/slices/sectionSlice'
import {
  selectBuildingsWithFilter,
  loadBuildingsAsync,
} from '../../../admin/slices/buildingSlice'
import {
  selectLocationsWithFilter,
  loadLocationsAsync,
} from '../../../admin/slices/locationSlice'
import {
  selectApartmentsWithFilter,
  loadApartmentsAsync,
} from '../../../admin/slices/apartmentSlice'
import {
  selectFloorsWithFilter,
  loadFloorsAsync,
} from '../../../admin/slices/floorSlice'
import {
  selectStoresWithFilter,
  loadStoresAsync,
} from '../../../admin/slices/storeSlice'
import {
  selectParkingSpotsWithFilter,
  loadParkingSpotsAsync,
} from '../../../admin/slices/parkingSpotSlice'
import {
  eventTypes,
  eventTypeEnum,
  apiStatusEnum as apiStatus,
} from '../../../common/enums'
import { roles, spinnerSize } from '../../../common/constants'
import * as callLogApi from '../../../apiCalls/callLog'
import { useHistory, withRouter } from 'react-router-dom'
import {
  selectCurrentUserDisplayName,
  selectCurrentUserProperties,
  selectCurrentUserRole,
} from '../../../admin/slices/currentUserSlice'
import { parseISO } from 'date-fns'
import { Typography } from '@material-ui/core'
import FormValidationMessage from '../molecules/FormValidationMessage'
import ErrorAlertDialog from '../molecules/ErrorAlertDialog'
import PageHeader from '../../../common/components/organisms/PageHeader'

const calcTimeRange = (startTime, endTime) => {
  const start = startTime.getHours() * 60 + startTime.getMinutes()

  const end = endTime.getHours() * 60 + endTime.getMinutes()

  return { start, end }
}

const getPlatoonByTime = (platoons, pickerTime) => {
  let platoonShortList = []
  let time = pickerTime.getHours() * 60 + pickerTime.getMinutes()
  platoons.map((platoon) => {
    let platoonRange = calcTimeRange(
      parseISO(platoon.startTime),
      parseISO(platoon.endTime)
    )
    if (
      platoonRange.start > platoonRange.end &&
      (platoonRange.start <= time || time <= platoonRange.end)
    ) {
      platoonShortList.push(platoon)
    } else if (platoonRange.start <= time && time <= platoonRange.end) {
      platoonShortList.push(platoon)
    }
  })

  if (platoonShortList.length == 1) {
    return platoonShortList[0].id
  } else if (platoonShortList.length == 2) {
    platoonShortList.map((potentialPlatoon) => {
      let potentialPlatoonRange = calcTimeRange(
        parseISO(potentialPlatoon.startTime),
        parseISO(potentialPlatoon.endTime)
      )
      if (potentialPlatoonRange.start < potentialPlatoonRange.end) {
        return potentialPlatoon.id
      }
    })
  }
}

const CallLogEvent = ({
  eventTypeChange,
  callLogEvent = null,
  createIncidentFromCall,
  createUnusualIncidentFromCall,
  setRecordEventLoaded,
}) => {
  const [header, setHeader] = useState('Record New Call Log')
  const currentUserName = useSelector(selectCurrentUserDisplayName())
  const currentUserActiveProperties = useSelector(
    selectCurrentUserProperties()
  ).filter((p) => p.isActive)
  const isGeneralUser =
    useSelector(selectCurrentUserRole()) === roles.generalUser
  const defaultCallLog = {
    event: {
      type: 1,
      preparedBy: currentUserName,
      condition: 1,
      dispatchedStatusId: 1,
      date: new Date(),
      timeResponded: new Date(),
      location: {
        propertyId: currentUserActiveProperties.length
          ? currentUserActiveProperties[0].id
          : undefined,
      },
    },
  }

  let currentCallLog = {}

  if (callLogEvent) currentCallLog = callLogEvent
  else currentCallLog = defaultCallLog

  let eventTypeList = {}
  if (isGeneralUser) eventTypeList = eventTypes.filter((t) => t.id != 3)
  else eventTypeList = eventTypes

  const methods = useForm({ defaultValues: currentCallLog })
  const { handleSubmit, reset, setValue, watch, formState } = methods

  const dispatch = useDispatch()

  const eventDispatchedStatus = useSelector(selectEventDispatchedStatus)
  const eventConditions = useSelector(selectEventConditions)
  const eventPlatoons = useSelector(selectEventPlatoons)
  const callerOrigins = useSelector(selectCallerOrigins)
  const dispositionCodes = useSelector(selectDispositionCodes)
  const callTypesSelector = useSelector(selectOrderedCallTypes)

  const [disableCallerAnonymous, setDisableCallerAnonymous] = useState(
    callLogEvent ? callLogEvent.event.caller.sct : false
  )
  const [disableCallerSCT, setDisableCallerSCT] = useState(
    callLogEvent ? callLogEvent.event.caller.anonymous : false
  )

  const onAnonymousChange = (value) => {
    if (value) {
      setValue('event.caller.name', 'Anonymous')
      setDisableCallerSCT(true)
    } else {
      setValue('event.caller.name', '')
      setDisableCallerSCT(false)
    }
  }

  const onSctChange = (value) => {
    if (value) {
      setValue('event.caller.name', 'SCT')
      setDisableCallerAnonymous(true)
    } else {
      setValue('event.caller.name', '')
      setDisableCallerAnonymous(false)
    }
  }

  //This is for adding a spinner/show loading
  const [dataLoaded, setDataLoaded] = useState(false)
  const loading = () => setDataLoaded(false)
  const loaded = () => setDataLoaded(true)
  const [firstLoadOfExistingCallLog, setFirstLoadOfExistingCallLog] =
    useState(false)
  const [secondLoadOfExistingCallLog, setSecondLoadOfExistingCallLog] =
    useState(false)

  const [showCreateIncidentButton, setShowCreateIncidentButton] =
    useState(false)
  const [showCreateUnusualIncidentButton, setShowCreateUnusualIncidentButton] =
    useState(false)

  const handleTimeChange = (time) => {
    var platoonId = getPlatoonByTime(eventPlatoons, time)
    if (platoonId)
      setValue('event.platoonId', platoonId, { shouldValidate: true })
  }

  const handleDispositionChange = async () => {
    setValue(
      'event.responseDetails.dispositionTime',
      currentCallLog.event.responseDetails
        ? currentCallLog.event.responseDetails.dispositionTime
        : new Date()
    )
  }

  const createIncident = async () => {
    setRecordEventLoaded(false)
    createIncidentFromCall(currentCallLog)
  }

  const createUnusualIncident = async () => {
    setRecordEventLoaded(false)
    createUnusualIncidentFromCall(currentCallLog)
  }

  const resetForm = async () => {
    setRecordEventLoaded(false)
    if (currentCallLog.event.callLogId != undefined) {
      history.push(
        '/event/' + eventTypeEnum.callLog + '/' + currentCallLog.event.callLogId
      )
    } else {
      history.push('/recordEvent/' + eventTypeEnum.callLog)
    }
  }

  const savedProperty = callLogEvent
    ? callLogEvent.event.location.propertyId
    : null
  const savedSection = callLogEvent
    ? callLogEvent.event.location.sectionId
    : null
  const savedBuilding = callLogEvent
    ? callLogEvent.event.location.buildingId
    : null
  const savedLocation = callLogEvent
    ? callLogEvent.event.location.locationId
    : null
  const savedApartment = callLogEvent
    ? callLogEvent.event.location.apartmentId
    : null
  const savedFloor = callLogEvent ? callLogEvent.event.location.floorId : null
  const savedStore = callLogEvent ? callLogEvent.event.location.storeId : null
  const savedParkingSpot = callLogEvent
    ? callLogEvent.event.location.parkingSpotId
    : null

  const [propertyFilterValue, setPropertyFilterValue] = useState(null)
  const [sectionFilterValue, setSectionFilterValue] = useState(null)
  const clearSectionFilter = () => setSectionFilterValue(null)
  const [buildingFilterValue, setBuildingFilterValue] = useState(null)
  const clearBuildingFilter = () => setBuildingFilterValue(null)
  const [locationFilterValue, setLocationFilterValue] = useState(null)
  const clearLocationFilter = () => setLocationFilterValue(null)
  const [locationType, setLocationType] = useState('')
  const propertySelector = useSelector(
    selectActiveUserProperties(currentUserActiveProperties, savedProperty)
  )
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(true, propertyFilterValue, savedSection)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(true, sectionFilterValue, savedBuilding)
  )
  const locationsSelector = useSelector(
    selectLocationsWithFilter(true, buildingFilterValue, savedLocation)
  )
  const apartmentsSelector = useSelector(
    selectApartmentsWithFilter(true, locationFilterValue, savedApartment)
  )
  const floorSelector = useSelector(
    selectFloorsWithFilter(true, locationFilterValue, savedFloor)
  )
  const storesSelector = useSelector(
    selectStoresWithFilter(true, locationFilterValue, savedStore)
  )
  const parkingSpotsSelector = useSelector(
    selectParkingSpotsWithFilter(true, locationFilterValue, savedParkingSpot)
  )

  const [sectionDisabled, setSectionDisabled] = useState(true)
  const [buildingDisabled, setBuildingDisabled] = useState(true)
  const [locationDisabled, setLocationDisabled] = useState(true)

  const resetSelect = (fieldName) => setValue(fieldName, '')

  const setPropertyAsFilter = (item) => {
    setPropertyFilterValue(item)
    resetSelect('event.location.sectionId')
    resetSelect('event.location.buildingId')
    resetSelect('event.location.locationId')
    clearSectionFilter()
    clearBuildingFilter()
    clearLocationFilter()
    setSectionDisabled(false)
    setBuildingDisabled(true)
    setLocationDisabled(true)
    setLocationType('')
  }

  const selectSectionAsFilter = (item) => {
    setSectionFilterValue(item)
    resetSelect('event.location.buildingId')
    resetSelect('event.location.locationId')
    clearBuildingFilter()
    clearLocationFilter()
    setBuildingDisabled(false)
    setLocationDisabled(true)
    setLocationType('')
  }
  const selectBuildingAsFilter = (item) => {
    setBuildingFilterValue(item)
    resetSelect('event.location.locationId')
    clearLocationFilter()
    setLocationDisabled(false)
    setLocationType('')
  }
  const selectLocationAsFilter = (item) => {
    setLocationFilterValue(item)
    setLocationType(locationsSelector.locations.find((e) => e.id === item).name)
  }

  useEffect(async () => {
    if (
      currentCallLog.event.callLogId != undefined &&
      !firstLoadOfExistingCallLog
    ) {
      if (currentCallLog.event.controlNumberReferenceTree.incidentId == null) {
        setShowCreateIncidentButton(true)
      }
      if (
        currentCallLog.event.controlNumberReferenceTree.unusualIncidentId ==
        null
      ) {
        setShowCreateUnusualIncidentButton(true)
      }
      setHeader('Call Log: ' + currentCallLog.event.callLogControlNumber)
      setPropertyAsFilter(currentCallLog.event.location.propertyId)
      setSectionFilterValue(currentCallLog.event.location.sectionId)
      setBuildingFilterValue(currentCallLog.event.location.buildingId)
      setLocationFilterValue(currentCallLog.event.location.locationId)
      setSectionDisabled(false)
      setBuildingDisabled(false)
      setLocationDisabled(false)
      setFirstLoadOfExistingCallLog(true)
    }
    if (
      currentCallLog.event.callLogId != undefined &&
      locationsSelector.status === apiStatus.succeeded &&
      firstLoadOfExistingCallLog &&
      !secondLoadOfExistingCallLog
    ) {
      selectLocationAsFilter(currentCallLog.event.location.locationId)
      setSecondLoadOfExistingCallLog(true)
    }

    if (
      currentCallLog.event.callLogId === undefined &&
      defaultCallLog.event.location.propertyId !== undefined &&
      propertyFilterValue === null
    ) {
      setPropertyAsFilter(defaultCallLog.event.location.propertyId)
    }

    if (
      (watchPlatoon === null || watchPlatoon === undefined) &&
      eventPlatoons.length !== 0
    ) {
      handleTimeChange(currentCallLog.event.timeResponded)
    }

    if (eventDispatchedStatus.length === 0) {
      dispatch(loadEventDispatchedStatusAsync())
    }
    if (eventConditions.length === 0) {
      dispatch(loadEventConditionsAsync())
    }
    if (eventPlatoons.length === 0) {
      dispatch(loadEventPlatoonsAsync())
    }
    if (callerOrigins.length === 0) {
      dispatch(loadCallerOriginsAsync())
    }
    if (dispositionCodes.length === 0) {
      dispatch(loadDispositionCodesAsync())
    }
    if (callTypesSelector.status === apiStatus.idle) {
      dispatch(loadCallTypesAsync())
    }
    if (propertySelector.status === apiStatus.idle) {
      dispatch(loadPropertiesAsync())
    }
    if (sectionsSelector.status === apiStatus.idle) {
      dispatch(loadSectionsAsync())
    }
    if (buildingsSelector.status === apiStatus.idle) {
      dispatch(loadBuildingsAsync())
    }
    if (locationsSelector.status === apiStatus.idle) {
      dispatch(loadLocationsAsync())
    }
    if (floorSelector.status === apiStatus.idle) {
      dispatch(loadFloorsAsync())
    }
    if (apartmentsSelector.status === apiStatus.idle) {
      dispatch(loadApartmentsAsync())
    }
    if (storesSelector.status == apiStatus.idle) {
      dispatch(loadStoresAsync())
    }
    if (parkingSpotsSelector.status == apiStatus.idle) {
      dispatch(loadParkingSpotsAsync())
    } else {
      loaded()
    }
  }, [
    eventDispatchedStatus.length,
    eventConditions.length,
    eventPlatoons.length,
    callerOrigins.length,
    dispositionCodes.length,
    propertySelector.properties.length,
    sectionsSelector.sections.length,
    buildingsSelector.length,
    locationsSelector.locations.length,
    floorSelector.floors.length,
    apartmentsSelector.apartments.length,
    storesSelector.stores.length,
    parkingSpotsSelector.parkingSpots.length,
    watchPlatoon,
    dispatch,
  ])

  const watchEventType = watch('event.type')
  const watchEventCondition = watch('event.conditionId')
  const watchPlatoon = watch('event.platoonId')
  const history = useHistory()

  const canEdit = () => {
    if (!isGeneralUser) return true
    if (isGeneralUser && defaultCallLog.event.conditionId != 2) return true
    return false
  }

  const [showSaveError, setShowSaveError] = useState(false)
  const [saveError, setSaveError] = useState({
    title: '',
    content: '',
  })

  const closeSaveErrorAlert = () => {
    setShowSaveError(false)
    setSaveError({
      title: '',
      content: '',
    })
    loaded()
  }

  const { isValid } = formState
  const saveForm = () => {   
    if (!isValid) {
      setOpenValidationMessage(true)
    }
  }

  const [openValidationMessage, setOpenValidationMessage] = useState(false)
  const handelCloseValidationMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationMessage(false)
  }

  return (
    <>
      <PageHeader pageName={header} />
      {dataLoaded ? (
        <>
          {canEdit() ? (
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(async (data) => {                  
                  //set session storage for last dispatcher
                  var dispatcherValue = data.event.dispatcher
                  sessionStorage.setItem('lastDispatcher', JSON.stringify(dispatcherValue));
                  setOpenValidationMessage(false)
                  loading()
                  if (currentCallLog.event.callLogId != undefined) {
                    callLogApi
                      .updateCallLog(data, currentCallLog.event.callLogId)
                      .then((callLogId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' + eventTypeEnum.callLog + '/' + callLogId
                        )
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  } else {
                    callLogApi
                      .addCallLog(data)
                      .then((callLogId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' + eventTypeEnum.callLog + '/' + callLogId
                        )
                        setShowCreateIncidentButton(true)
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  }                  
                })}
              >
                {currentCallLog.event.controlNumberReferenceTree && (
                  <LinkedReports
                    controlNumberReferenceTree={
                      currentCallLog.event.controlNumberReferenceTree
                    }
                  />
                )}
                {propertySelector.status === apiStatus.succeeded &&
                  propertySelector.properties.length == 0 && (
                    <Typography color='secondary' align='center' variant='h5'>
                      Please note: You currently do not have any properties
                      assigned to you. You can contact a CHIEF system admin for
                      your property access to be updated.
                    </Typography>
                  )}
                <CallLogEventDetailsFields
                  defaultValues={{
                    ...currentCallLog.event,
                    date: new Date(currentCallLog.event.date),
                    timeResponded: new Date(currentCallLog.event.timeResponded),
                  }}
                  //Lists for selects
                  eventTypes={eventTypeList}
                  eventDispatchedStatus={eventDispatchedStatus}
                  eventConditions={eventConditions}
                  eventPlatoons={eventPlatoons}
                  //additional handlers
                  eventTypeChangeHandler={eventTypeChange}
                  timeChangeHandler={handleTimeChange}
                  disableEventConditionField={isGeneralUser}
                />
                <LocationFields
                  defaultValues={currentCallLog.location}
                  properties={propertySelector.properties}
                  propertyChangeHandler={setPropertyAsFilter}
                  sections={sectionsSelector.sections}
                  sectionChangeHandler={selectSectionAsFilter}
                  sectionDisabled={sectionDisabled}
                  buildings={buildingsSelector.buildings}
                  buildingChangeHandler={selectBuildingAsFilter}
                  buildingDisabled={buildingDisabled}
                  locations={locationsSelector.locations}
                  locationChangeHandler={selectLocationAsFilter}
                  locationDisabled={locationDisabled}
                  locationType={locationType}
                  apartments={apartmentsSelector.apartments}
                  floors={floorSelector.floors}
                  stores={storesSelector.stores}
                  parkingSpots={parkingSpotsSelector.parkingSpots}
                />
                <CallerFields
                  callerOrigins={callerOrigins}
                  onAnonymousChange={onAnonymousChange}
                  onSctChange={onSctChange}
                  disableAnonymous={disableCallerAnonymous}
                  disableSCT={disableCallerSCT}
                />
                <ResponseDetailsFields
                  callTypes={callTypesSelector.callTypes}
                  dispositionCodes={dispositionCodes}
                  eventCondition={watchEventCondition}
                  dispositionChangeFunction={handleDispositionChange}
                  defaultValues={{
                    dispositionId:
                      currentCallLog.event.responseDetails !== undefined
                        ? currentCallLog.event.responseDetails.despositionId
                        : '',
                  }}
                />
                {currentCallLog.event.callLogId != undefined && (
                  <FormDetails
                    createdBy={currentCallLog.event.insertedBy}
                    createdOn={currentCallLog.event.insertedDate}
                    lastModifiedBy={currentCallLog.event.updatedBy}
                    lastModifiedOn={currentCallLog.event.updatedDate}
                  />
                )}
                <EventFormButtons
                  eventType={watchEventType}
                  reset={resetForm}
                  create={createIncident}
                  createOption2={createUnusualIncident}
                  showCreateButton={showCreateIncidentButton}
                  showCreateOption2Button={showCreateUnusualIncidentButton}
                  save={saveForm}
                />
              </form>
            </FormProvider>
          ) : (
            <>
              This event has been completed. Please contact a Super User or
              Admin.
            </>
          )}
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
      <ErrorAlertDialog
        open={showSaveError}
        title={saveError.title}
        content={saveError.content}
        close={closeSaveErrorAlert}
      />
      <FormValidationMessage
        open={openValidationMessage}
        handleClose={handelCloseValidationMessage}
      />
    </>
  )
}

CallLogEvent.propTypes = {
  eventTypeChange: PropTypes.func.isRequired,
  callLogEvent: PropTypes.shape({
    event: {
      type: PropTypes.number,
      preparedBy: PropTypes.string,
      dispatchedStatus: PropTypes.number,
      condition: PropTypes.number,
      date: PropTypes.instanceOf(Date),
      timeResponded: PropTypes.instanceOf(Date),
      platoon: PropTypes.number,
      dispatcher: PropTypes.string,
    },
    location: {
      property: PropTypes.number,
      section: PropTypes.number,
      building: PropTypes.number,
      location: PropTypes.number,
      apartment: PropTypes.number,
    },
    caller: {
      anonymous: PropTypes.bool,
      sct: PropTypes.bool,
      origin: PropTypes.number,
      name: PropTypes.string,
      phone: PropTypes.string,
    },
    responseDetails: {
      typeOfCall: PropTypes.number,
      respondingOfficers: PropTypes.string,
      disposition: PropTypes.number,
      dispositionTime: PropTypes.instanceOf(Date),
      notes: PropTypes.string,
    },
  }),
  createIncidentFromCall: PropTypes.func,
  createUnusualIncidentFromCall: PropTypes.func,
  setRecordEventLoaded: PropTypes.func,
}

export default withRouter(CallLogEvent)

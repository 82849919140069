import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Link } from '@material-ui/core'
import { eventTypeEnum } from '../../../common/enums'
import { useHistory, withRouter } from 'react-router-dom'

const LinkedReports = ({ controlNumberReferenceTree }) => {
  const history = useHistory()
  const navigate = (e, eventType, id) => {
    e.preventDefault()
    if (id != undefined) history.push('/event/' + eventType + '/' + id)
  }
  return (
    <Typography>
      <span style={{ marginLeft: '2.5%' }}>
        <Link
          href='#'
          onClick={(e) =>
            navigate(
              e,
              eventTypeEnum.callLog,
              controlNumberReferenceTree.callLogId
            )
          }
        >
          {controlNumberReferenceTree.callLogControlNumber
            ? controlNumberReferenceTree.callLogControlNumber
            : ''}
        </Link>
        <Link
          href='#'
          onClick={(e) =>
            navigate(
              e,
              eventTypeEnum.incident,
              controlNumberReferenceTree.incidentId
            )
          }
        >
          {controlNumberReferenceTree.incidentControlNumber
            ? ' | ' + controlNumberReferenceTree.incidentControlNumber
            : ''}
        </Link>
        <Link
          href='#'
          onClick={(e) =>
            navigate(
              e,
              eventTypeEnum.accident,
              controlNumberReferenceTree.accidentId
            )
          }
        >
          {controlNumberReferenceTree.accidentControlNumber
            ? ' | ' + controlNumberReferenceTree.accidentControlNumber
            : ''}
        </Link>
        <Link
          href='#'
          onClick={(e) =>
            navigate(
              e,
              eventTypeEnum.unusualIncident,
              controlNumberReferenceTree.unusualIncidentId
            )
          }
        >
          {controlNumberReferenceTree.unusualIncidentControlNumber
            ? ' | Unusual Incident'
            : ''}
        </Link>
      </span>
    </Typography>
  )
}

LinkedReports.propTypes = { controlNumberReferenceTree: PropTypes.shape() }

export default withRouter(LinkedReports)

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormLocationSelect from '../atoms/FormLocationSelect'

const LocationFields = ({
  properties = [],
  propertyChangeHandler,
  sections = [],
  sectionChangeHandler,
  sectionDisabled = false,
  buildings = [],
  buildingChangeHandler,
  buildingDisabled = false,
  locations = [],
  locationChangeHandler,
  locationDisabled = false,
  locationType,
  apartments = [],
  floors = [],
  stores = [],
  parkingSpots = [],
  disablePropertyChange = false,
  propertyHelperText = null,
}) => (
  <>
    <SectionHeader sectionName='Location' />
    <Grid
      container
      spacing={3}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        width: '95%',
      }}
    >
      <Grid item xs={3}>
        <FormLocationSelect
          name='event.location.propertyId'
          label='Property'
          options={properties}
          required={true}
          errorMessage='Property field is required'
          rules={{ required: true }}
          defaultValue={null}
          onChangeHandler={propertyChangeHandler}
          disabled={disablePropertyChange}
          additionalHelperText={propertyHelperText}
        />
      </Grid>
      <Grid item xs={2}>
        <FormLocationSelect
          name='event.location.sectionId'
          label='Section'
          options={sections}
          required={true}
          errorMessage='Section field is required'
          rules={{ required: true }}
          defaultValue={null}
          onChangeHandler={sectionChangeHandler}
          disabled={sectionDisabled}
        />
      </Grid>
      <Grid item xs={2}>
        <FormLocationSelect
          name='event.location.buildingId'
          label='Building'
          options={buildings}
          required={true}
          errorMessage='Building field is required'
          rules={{ required: true }}
          defaultValue={null}
          onChangeHandler={buildingChangeHandler}
          disabled={buildingDisabled}
        />
      </Grid>
      <Grid item xs={3}>
        <FormLocationSelect
          name='event.location.locationId'
          label='Location'
          options={locations}
          required={true}
          errorMessage='Location field is required'
          rules={{ required: true }}
          defaultValue={null}
          onChangeHandler={locationChangeHandler}
          disabled={locationDisabled}
        />
      </Grid>
      <Grid item xs={2}>
        {locationType === 'Apartment' && apartments.length > 0 && (
          <FormLocationSelect
            name='event.location.apartmentId'
            label='Apartment'
            options={apartments}
            defaultValue={null}
          />
        )}
        {locationType === 'Floor' && floors.length > 0 && (
          <FormLocationSelect
            name='event.location.floorId'
            label='Floor'
            options={floors}
            defaultValue={null}
          />
        )}
        {locationType === 'Store' && stores.length > 0 && (
          <FormLocationSelect
            name='event.location.storeId'
            label='Store'
            options={stores}
            defaultValue={null}
          />
        )}
        {locationType === 'Parking Spot' && parkingSpots.length > 0 && (
          <FormLocationSelect
            name='event.location.parkingSpotId'
            label='Parking Spot'
            options={parkingSpots}
            defaultValue={null}
          />
        )}
      </Grid>
    </Grid>
  </>
)

LocationFields.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  propertyChangeHandler: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  sectionChangeHandler: PropTypes.func.isRequired,
  sectionDisabled: PropTypes.bool.isRequired,
  buildings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  buildingChangeHandler: PropTypes.func.isRequired,
  buildingDisabled: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  locationChangeHandler: PropTypes.func.isRequired,
  locationDisabled: PropTypes.bool.isRequired,
  locationType: PropTypes.string.isRequired,
  apartments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  floors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  parkingSpots: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  disablePropertyChange: PropTypes.bool,
  propertyHelperText: PropTypes.string,
}

export default LocationFields

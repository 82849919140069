import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/unusualIncident'

export function addUnusualIncident(request) {
  const unusualIncident = { unusualIncident: request.event }
  const body = JSON.stringify(unusualIncident)
  return fetch(baseUrl, {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateUnusualIncident(request, unusualIncidentId) {
  const unusualIncident = { unusualIncident: request.event }
  unusualIncident.unusualIncident.unusualIncidentId = unusualIncidentId
  const body = JSON.stringify(unusualIncident)
  return fetch(baseUrl, {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getUnusualIncidentById(id) {
  return fetch(baseUrl + '/' + id, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getPdfReport(unusualIncidentId, unusualIncidentControlNumber) {
  var url = new URL(baseUrl + '/pdfReport/' + unusualIncidentId)
  url.search = new URLSearchParams({
    dateTimeOffsetInMinutes: new Date().getTimezoneOffset(),
  }).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((response) => {
      response.blob().then((blob) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download =
          'Unusual Incident Report ' + unusualIncidentControlNumber + '.pdf'
        link.click()
        link.remove()
      })
    })
    .catch(handleError)
}

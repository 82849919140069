import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'

const Footer = ({setFooterHeight}) => {
  const useStyles = makeStyles({
    footer: {
      position: 'sticky',
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      padding: '0.5em',
      color: 'white',
      width: '100%',
      textAlign: 'center',
      bottom: 0,
    },
  })

  const classes = useStyles()
  const ref = useRef(null)

  useEffect(() => {
    setFooterHeight(ref.current.clientHeight)
  })

  return <div ref={ref} className={classes.footer}>{process.env.FOOTER_LABEL}</div>
}

Footer.propTypes = {
  setFooterHeight: PropTypes.func
}

export default Footer

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/locations'

export function getLocations() {
  return fetch(baseUrl + '/locations', {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateLocation(location) {
  const newLocation = { location: location }
  const body = JSON.stringify(newLocation)
  return fetch(baseUrl + '/locations', {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function addLocation(location) {
  const newLocation = { location: location }
  const body = JSON.stringify(newLocation)
  return fetch(baseUrl + '/locations', {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

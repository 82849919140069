import React from 'react'
import { useSelector } from 'react-redux'
import PageHeader from '../../../common/components/organisms/PageHeader'
import LocationSection from '../organisms/LocationSections'
import { makeStyles } from '@material-ui/core'
import { selectCurrentUserRole } from '../../../admin/slices/currentUserSlice'
import { roles } from '../../../common/constants'
import NoAccess from '../../../common/components/templates/NoAccess'

const useStyles = makeStyles({
  layout: {
    padding: '1em',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
})

const ManageLocations = () => {
  const classes = useStyles()
  const isAdmin = useSelector(selectCurrentUserRole()) === roles.admin
  return (
    <>
      <PageHeader pageName='Admin - Manage Locations' />
      {isAdmin ? (
        <div className={classes.layout}>
          <LocationSection />
        </div>
      ) : (
        <NoAccess reason='(Role Access)' />
      )}
    </>
  )
}

export default ManageLocations

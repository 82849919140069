import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'

const SectionHeader = ({ sectionName }) => {
  const useStyles = makeStyles({
    sectionHeader: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      width: '98%',
      padding: '0.5em',
      fontSize: '1.25em',
      margin: 'auto',
    },
  })
  const classes = useStyles()

  return <div className={classes.sectionHeader}>{sectionName}</div>
}

SectionHeader.propTypes = {
  sectionName: PropTypes.string.isRequired,
}

export default SectionHeader

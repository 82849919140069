import React from 'react'
import { useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'
import SectionHeader from '../../../common/components/molecules/SectionHeader'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const FloodFields = () => {
  const classes = useStyles()

  const { watch } = useFormContext()
  const watchMaintenancePersonnelPresent = watch(
    'event.flood.maintenancePersonnelAtScene'
  )
  return (
    <>
      <SectionHeader sectionName='Flood' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={5}>
          <FormInput
            name='event.flood.locationsObservedInApartment'
            label='Water was observed at which location in apartment'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <FormInput
            name='event.flood.waterEntryIntoApartment'
            label='Water apparently entered apartment by'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={5}>
          <FormInput name='event.flood.cause' label='Cause' defaultValue={''} />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <FormInput
            name='event.flood.locationsObservedInBuilding'
            label='Water was observed at which other locations in building'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={3}>
          <FormCheckbox
            name='event.flood.tenantAdvisedToSubmitDamages'
            label='Tenant Advised to Submit List of Damages'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.flood.maintenancePersonnelAtScene'
            label='Maintenance Personnel Present at Scene'
            defaultValue={false}
          />
        </Grid>
        {watchMaintenancePersonnelPresent && (
          <Grid item xs={3}>
            <FormInput
              name='event.flood.personelName'
              label='Personnel Name'
              required={true}
              errorMessage={'Personnel Name field is required'}
              rules={{ required: true }}
              defaultValue={''}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default FloodFields

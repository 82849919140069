import React from 'react'
import PropTypes from 'prop-types'
import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import FormCards from './FormCards'
import { useSelector } from 'react-redux'
import { selectTertiaryThemeColor } from '../../../admin/slices/themeColorSlice'

const Attachments = ({
  attachments,
  addAttachments,
  isLoading,
  setIsLoading,
  deleteAttachment,
  downloadAttachment,
}) => {
  const useStyles = makeStyles({
    button: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      color: 'white',
    },
  })

  const classes = useStyles()

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <FormCards
            name={'attachments.list'}
            data={attachments}
            deleteCard={deleteAttachment}
            downloadCard={downloadAttachment}
          />
          <Button
            variant='contained'
            component='label'
            className={classes.button}
          >
            Select File(s)
            <input
              name='attachment.files'
              type='file'
              multiple
              onChange={(e) => {
                setIsLoading(true)
                addAttachments(e)
              }}
              hidden
            />
          </Button>
        </>
      )}
    </>
  )
}

Attachments.propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ),
  addAttachments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
}

export default Attachments

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'

import { makeStyles, Typography } from '@material-ui/core'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormInput from '../atoms/FormInput'
import FormMaskedInput from '../atoms/FormMaskedInput'
import FormSelect from '../atoms/FormSelect'
import FormLocationSelect from '../atoms/FormLocationSelect'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const PlaceOfOccurrenceFields = ({
  defaultValues = {},
  placesOfOccurrence = [],
  properties = [],
  propertyChangeHandler,
  sections = [],
  sectionChangeHandler,
  sectionDisabled = false,
  buildings = [],
  buildingChangeHandler,
  buildingDisabled = false,
  locations = [],
  locationChangeHandler,
  locationDisabled = false,
  locationType,
  apartments = [],
  floors = [],
  stores = [],
  parkingSpots = [],
  otherPlacesOfOccurrence = [],
  disablePropertyChange = false,
  propertyHelperText = null,
}) => {
  const classes = useStyles()
  const { watch } = useFormContext()

  const watchOtherPlaceOfOccurrence = watch(
    'event.place.otherPlaceOfOccurrence'
  )
  return (
    <>
      <SectionHeader sectionName='Place of Occurrence' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
        justify='space-evenly'
      >
        <Grid item xs={2}>
          <FormSelect
            name='event.place.placeOfOccurenceId'
            label='Place of Occurrence'
            options={placesOfOccurrence}
            required={true}
            errorMessage='Place of Occurrence is required'
            rules={{ required: true }}
            defaultValue={defaultValues.placeOfOccurrenceId || ''}
          />
        </Grid>
        <Grid item xs={2}>
          <FormLocationSelect
            name='event.place.propertyId'
            label='Property'
            options={properties}
            required={true}
            errorMessage='Property field is required'
            rules={{ required: true }}
            defaultValue={''}
            onChangeHandler={propertyChangeHandler}
            disabled={disablePropertyChange}
            additionalHelperText={propertyHelperText}
          />
        </Grid>
        <Grid item xs={2}>
          <FormLocationSelect
            name='event.place.sectionId'
            label='Section'
            options={sections}
            required={true}
            errorMessage='Section field is required'
            rules={{ required: true }}
            defaultValue={defaultValues.sectionId || ''}
            onChangeHandler={sectionChangeHandler}
            disabled={sectionDisabled}
          />
        </Grid>
        <Grid item xs={2}>
          <FormLocationSelect
            name='event.place.buildingId'
            label='Building'
            options={buildings}
            required={true}
            errorMessage='Building field is required'
            rules={{ required: true }}
            defaultValue={defaultValues.buildingId || ''}
            onChangeHandler={buildingChangeHandler}
            disabled={buildingDisabled}
          />
        </Grid>
        <Grid item xs={2}>
          <FormLocationSelect
            name='event.place.locationId'
            label='Location'
            options={locations}
            required={true}
            errorMessage='Location field is required'
            rules={{ required: true }}
            defaultValue={defaultValues.locationId || ''}
            onChangeHandler={locationChangeHandler}
            disabled={locationDisabled}
          />
        </Grid>
        <Grid item xs={1}>
          {locationType === 'Apartment' && apartments.length > 0 && (
            <FormLocationSelect
              name='event.place.apartmentId'
              label='Apartment'
              options={apartments}
              defaultValue={defaultValues.apartmentId || null}
            />
          )}
          {locationType === 'Floor' && floors.length > 0 && (
            <FormLocationSelect
              name='event.place.floorId'
              label='Floor'
              options={floors}
              defaultValue={defaultValues.floorId || null}
            />
          )}
          {locationType === 'Store' && stores.length > 0 && (
            <FormLocationSelect
              name='event.place.storeId'
              label='Store'
              options={stores}
              defaultValue={defaultValues.storeId || null}
            />
          )}
          {locationType === 'Parking Spot' && parkingSpots.length > 0 && (
            <FormLocationSelect
              name='event.place.parkingSpotId'
              label='Parking Spot'
              options={parkingSpots}
              defaultValue={defaultValues.parkingSpotId || null}
            />
          )}
        </Grid>
        <Grid item xs={5}>
          <Grid container spacing={0} direction='row' alignItems='center'>
            <Grid item xs={2}>
              <Typography>{'In Front of'}</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormInput
                name='event.place.additionalBuildingAddress'
                label='Building Address'
                defaultValue={''}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <Grid container spacing={0} direction='row' alignItems='center'>
            <Grid item xs={5}>
              <FormMaskedInput
                name='event.place.numberOfFeet'
                label='No. of Feet'
                defaultValue={''}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography>ft</Typography>
            </Grid>
            <Grid item xs={5}>
              <FormInput
                name='event.place.direction'
                label='N, S, E, W'
                defaultValue={''}
              />
            </Grid>
            <Grid item xs={1}>
              <Typography>of</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <FormInput
            name='event.place.fixedObject'
            label='Fixed Object (Pole, Hydrant, etc.)'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInput
            name='event.place.notes'
            label='Provide address & building no. or other specific location if inside or other than above'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={4}>
          <FormSelect
            name='event.place.otherPlaceOfOccurrence'
            label='Location officer found aided if other than place of occurrence'
            options={otherPlacesOfOccurrence}
            defaultValue={defaultValues.otherPlaceOfOccurrence || null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={7}>
          {watchOtherPlaceOfOccurrence === 4 && (
            <FormInput
              name='event.place.specifyOtherPlaceOfOccurrence'
              label='Specify'
              required={true}
              errorMessage='Specify field is required.'
              rules={{ required: true }}
              defaultValue={defaultValues.specifyOtherPlaceOfOccurrence || ''}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

PlaceOfOccurrenceFields.propTypes = {
  defaultValues: PropTypes.shape(),
  placesOfOccurrence: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  propertyChangeHandler: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  sectionChangeHandler: PropTypes.func.isRequired,
  sectionDisabled: PropTypes.bool.isRequired,
  buildings: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  buildingChangeHandler: PropTypes.func.isRequired,
  buildingDisabled: PropTypes.bool.isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  locationChangeHandler: PropTypes.func.isRequired,
  locationDisabled: PropTypes.bool.isRequired,
  locationType: PropTypes.string.isRequired,
  apartments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  floors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  stores: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  otherPlacesOfOccurrence: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  disablePropertyChange: PropTypes.bool,
  propertyHelperText: PropTypes.string,
}

export default PlaceOfOccurrenceFields

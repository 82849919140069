import React, { useEffect, useState } from 'react'
import Header from './common/components/templates/Header'
import Footer from './common/components/molecules/Footer'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePage from './common/components/templates/HomePage'
import RecordNewEvent from './eventReports/components/templates/RecordNewEvent'
import SearchIssues from './eventReports/components/templates/SearchIssues'
import ManageUsers from './admin/components/templates/ManageUsers'
import PageNotFound from './common/components/templates/PageNotFound'
import ManageLocations from './admin/components/templates/ManageLocations'
import ManageTheme from './admin/components/templates/ManageTheme'
import ManageTenants from './admin/components/templates/ManageTenants'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch, useSelector } from 'react-redux'
import { apiStatusEnum as apiStatus } from './common/enums'
import {
  selectThemeColors,
  loadThemeColorsAsync,
} from './admin/slices/themeColorSlice'
import {
  selectThemeImages,
  loadThemeImagesAsync,
} from './admin/slices/themeImageSlice'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from './common/auth/authConfig'
import { getAuthToken } from './apiCalls/auth'
import {
  loadCurrentUserAsync,
  selectCurrentUser,
} from './admin/slices/currentUserSlice'
import { spinnerSize } from './common/constants'
import * as authApi from './apiCalls/auth'
import { addMinutes } from 'date-fns'

const App = () => {
  const [intervalTimerExists, setIntervalTimerExists] = useState(false)
  const themeColorSelector = useSelector(selectThemeColors())
  const themeImageSelector = useSelector(selectThemeImages())

  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)

  const [footerHeight, setFooterHeight] = useState(0)

  const { instance, accounts } = useMsal()
  const currentUserSelector = useSelector(selectCurrentUser())

  const silentRenewCHIEFToken = async () => {
    const chiefToken = JSON.parse(sessionStorage.getItem('CHIEFToken'))
    let minTokenExpiryTime = chiefToken.expires
    addMinutes(minTokenExpiryTime, -20)
    if (Date.parse(minTokenExpiryTime) < new Date().getTime()) {
      console.log('CHIEF Token Expired, renewing the token.')
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(async (response) => {
          await authApi
            .getAuthToken(response.accessToken)
            .then(async () => dispatch(loadCurrentUserAsync()))
        })
    }
  }

  useEffect(async () => {
    if (sessionStorage['CHIEFToken'] == undefined) {
      await instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then(async (response) => {
          await getAuthToken(response.accessToken)
        })
    } else if (currentUserSelector.status == apiStatus.idle) {
      dispatch(loadCurrentUserAsync())
    }
    if (themeColorSelector.status == apiStatus.idle)
      dispatch(loadThemeColorsAsync())
    if (themeImageSelector.status == apiStatus.idle)
      dispatch(loadThemeImagesAsync())
    if (
      themeColorSelector.status == apiStatus.succeeded &&
      themeImageSelector.status == apiStatus.succeeded &&
      currentUserSelector.status == apiStatus.succeeded
    )
      setLoaded(true)

    if (!intervalTimerExists) {
      setIntervalTimerExists(true)
      let interval = setInterval(async () => {
        await silentRenewCHIEFToken()
      }, 1199000)

      return () => clearInterval(interval)
    }
  }, [
    dispatch,
    themeColorSelector.status,
    themeImageSelector.status,
    sessionStorage['CHIEFToken'],
    currentUserSelector.status,
    loaded,
  ])
  return (
    <>
      {loaded ? (
        <Router>
          <div style={{ minHeight: window.innerHeight - footerHeight }}>
            <Header />
            <Switch>
              <Route exact path='/' component={HomePage} />
              <Route path='/recordEvent' component={RecordNewEvent}>
                <Route
                  exact
                  path='/:action/:eventTypeId'
                  component={RecordNewEvent}
                />
                <Route exact path='/:action' component={RecordNewEvent} />
              </Route>
              <Route path='/event' component={RecordNewEvent}>
                <Route
                  exact
                  path='/:action/:eventTypeId/:id'
                  component={RecordNewEvent}
                />
              </Route>
              <Route path='/searchEvents' component={SearchIssues} />
              <Route path='/manageLocations' component={ManageLocations} />
              <Route path='/manageUsers' component={ManageUsers} />
              <Route path='/manageTenants' component={ManageTenants} />
              <Route path='/manageTheme' component={ManageTheme} />
              <Route component={PageNotFound} />
            </Switch>
          </div>
          <Footer setFooterHeight={setFooterHeight} />
        </Router>
      ) : (
        <CircularProgress
          size={spinnerSize}
          disableShrink={true}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
    </>
  )
}

export default App

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import { getUnreferencedIncidentControlNumbers } from '../../apiCalls/incident'

const initialState = {
  status: apiStatus.idle,
  error: null,
  controlNumbers: [],
}

const incidentControlNumbersSlice = createSlice({
  name: 'availableIncidentControlNumbers',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadControlNumbersSuccess(state, { payload }) {
      return {
        ...state,
        status: apiStatus.succeeded,
        controlNumbers: payload.map((item) => {
          return {
            id: item.incidentControlNumber,
            name: item.incidentControlNumber,
            ...item,
          }
        }),
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadControlNumbersSuccess,
} = incidentControlNumbersSlice.actions

export const loadControlNumbersAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await getUnreferencedIncidentControlNumbers('2020-01-01')
    .then((controlNumbers) => {
      dispatch(loadControlNumbersSuccess(controlNumbers))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectControlNumbers = (state) =>
  state.availableIncidentControlNumbers

export const selectControlNumberIncidentFilter = (incidentControlNumber) => (
  state
) => {
  var { status, controlNumbers } = selectControlNumbers(state)
  if (
    incidentControlNumber != undefined &&
    incidentControlNumber != '' &&
    status == apiStatus.succeeded
  ) {
    return {
      ...selectControlNumbers(state),
      controlNumber: controlNumbers.find(
        (c) => c.incidentControlNumber == incidentControlNumber
      ).callLogControlNumber,
    }
  } else
    return {
      ...selectControlNumbers(state),
      controlNumber: '',
    }
}

export default incidentControlNumbersSlice.reducer

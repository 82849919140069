import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import AccessTimeIcon from '@material-ui/icons/AccessTime'

const TimePickerField = ({
  label,
  timeChangeHandler,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <KeyboardTimePicker
      label={label}
      name={name}
      value={value}
      onChange={(data) => {
        onChange(data)
        if (timeChangeHandler) timeChangeHandler(data)
      }}
      InputLabelProps={{
        className: required ? 'required-label' : '',
        required: required || false,
      }}
      error={invalid}
      helperText={invalid ? errorMessage : ' '}
      ampm={false}
      variant='inline'
      maskChar='-'
      autoOk={true}
      keyboardIcon={<AccessTimeIcon />}
      fullWidth={true}
    />
  </MuiPickersUtilsProvider>
)

TimePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  timeChangeHandler: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
}

const FormTimePicker = ({
  name,
  label,
  timeChangeHandler,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = new Date(),
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ value, onChange }, { invalid }) => (
        <TimePickerField
          label={label}
          timeChangeHandler={timeChangeHandler}
          value={value}
          onChange={onChange}
          required={required}
          invalid={invalid}
          errorMessage={errorMessage}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
    />
  )
}

FormTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  timeChangeHandler: PropTypes.func,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
}

export default FormTimePicker

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as themeApi from '../../apiCalls/theme'

const initialState = {
  status: apiStatus.idle,
  error: null,
  themeImages: [],
}

const themeImageSlice = createSlice({
  name: 'themeImages',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadThemeImagesSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, themeImages: payload }
    },
    updateThemeImageSuccess(state, { payload }) {
      let updatedThemeImages = state.themeImages.map((themeImage) => {
        if (themeImage.themeImageId != payload.themeImageId) {
          return themeImage
        }
        return {
          ...themeImage,
          ...payload,
        }
      })

      return {
        ...state,
        themeImages: updatedThemeImages,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadThemeImagesSuccess,
  updateThemeImageSuccess,
} = themeImageSlice.actions

export const loadThemeImagesAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await themeApi
    .getThemeImages()
    .then((themeImages) => {
      dispatch(loadThemeImagesSuccess(themeImages))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateThemeImageAsync = (themeImage) => async (dispatch) => {
  dispatch(beginApiCall())
  return await themeApi
    .updateThemeImage(themeImage)
    .then(() => dispatch(updateThemeImageSuccess(themeImage)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectThemeImages = () => (state) => state.themeImages

export const selectHeaderImage = () => (state) =>
  state.themeImages.themeImages.find((i) => i.name == 'header').image

export const selectLandingPageImage = () => (state) =>
  state.themeImages.themeImages.find((i) => i.name == 'landingPage').image

export default themeImageSlice.reducer

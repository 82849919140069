import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as buildingApi from '../../apiCalls/building'

const initialState = {
  status: apiStatus.idle,
  error: null,
  buildings: [],
}

const buildingSlice = createSlice({
  name: 'buildings',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadBuildingsSuccess(status, { payload }) {
      return { ...status, status: apiStatus.succeeded, buildings: payload }
    },
    updateBuildingSuccess(state, { payload }) {
      let updatedBuildings = state.buildings.map((building) => {
        if (building.id != payload.id) {
          return building
        }
        return {
          ...building,
          ...payload,
        }
      })

      return {
        ...state,
        buildings: updatedBuildings,
        status: apiStatus.succeeded,
      }
    },
    addBuildingSuccess(state, { payload }) {
      let updatedBuildings = [...state.buildings, payload]
      return {
        ...state,
        buildings: updatedBuildings,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadBuildingsSuccess,
  updateBuildingSuccess,
  addBuildingSuccess,
} = buildingSlice.actions

export const loadBuildingsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await buildingApi
    .getBuildings()
    .then((buildings) => {
      dispatch(loadBuildingsSuccess(buildings))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateBuildingAsync = (building) => async (dispatch) => {
  dispatch(beginApiCall())
  return await buildingApi
    .updateBuilding(building)
    .then(() => dispatch(updateBuildingSuccess(building)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const addBuildingAsync = (building) => async (dispatch) => {
  dispatch(beginApiCall())
  return await buildingApi
    .addBuilding(building)
    .then((buildingId) =>
      dispatch(addBuildingSuccess({ id: buildingId, ...building }))
    )
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectBuildings = (state) => state.buildings

export const selectBuildingsWithFilter = (
  active,
  sectionId,
  selectedBuildingId = null
) => (state) => {
  var { buildings } = selectBuildings(state)
  return {
    ...selectBuildings(state),
    buildings: buildings
      .filter(
        (b) =>
          (b.sectionId == sectionId && b.isActive == active) ||
          (b.id == selectedBuildingId && b.sectionId == sectionId)
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export const selectBuildingsWithMultipleSectionsFilter = (sectionIds) => (
  state
) => {
  var { buildings } = selectBuildings(state)
  var filteredBuildings = new Array()
  sectionIds.map((sectionId) => {
    if (buildings.find((b) => b.sectionId == sectionId) !== undefined) {
      if (filteredBuildings.length > 0) {
        filteredBuildings = filteredBuildings.concat(
          buildings.filter((b) => b.sectionId == sectionId)
        )
      } else {
        filteredBuildings = buildings.filter((b) => b.sectionId == sectionId)
      }
    }
  })
  return {
    ...selectBuildings(state),
    buildings: filteredBuildings.sort((a, b) => {
      var x = a.name.toLowerCase()
      var y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    }),
  }
}

export const getBuildingById = (buildingId) => (state) => {
  var { buildings } = selectBuildings(state)
  if (buildings) {
    return buildings.find((b) => b.id === buildingId)
  }
  return {}
}

export const selectAllActiveBuildings = () => (state) => {
  var { buildings } = selectBuildings(state)
  return {
    ...selectBuildings(state),
    buildings: buildings
      .filter((b) => b.isActive === true)
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default buildingSlice.reducer

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { format } from 'date-fns'
import { dateFormat } from '../../../common/constants'

const SignatureField = ({
  name,
  label,
  value,
  setValue,
  required,
  invalid,
  errorMessage,
  saved = false,
}) => (
  <TextField
    label={label}
    value={value.signature}
    onChange={(v) => {
      const i = v.target.value
      const d =
        i === '' ? '' : saved ? value.date : format(new Date(), dateFormat)
      setValue(name, { signature: i, date: d }, { shouldValidate: true })
    }}
    error={invalid}
    helperText={
      invalid
        ? value.date + ' ' + errorMessage
        : value.date != null
        ? value.date
        : ' '
    }
    InputLabelProps={{
      className: required ? 'required-label' : '',
      required: required || false,
    }}
    fullWidth={true}
  />
)

SignatureField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  saved: PropTypes.bool,
}

const FormSignature = ({
  name,
  label,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = { signature: '', date: null },
  saved = false,
}) => {
  const { control, setValue } = useFormContext()

  return (
    <Controller
      render={({ value }, { invalid }) => (
        <SignatureField
          name={name}
          label={label}
          value={value}
          setValue={setValue}
          required={required}
          invalid={invalid}
          errorMessage={errorMessage}
          saved={saved}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

FormSignature.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.shape({
    signature: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
  }),
  saved: PropTypes.bool,
}

export default FormSignature

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/incident'

export function addIncident(request) {
  const incident = { incident: request.event }
  const body = JSON.stringify(incident)
  return fetch(baseUrl, {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateIncident(request, incidentId) {
  const incident = { incident: request.event }
  incident.incident.IncidentId = incidentId
  const body = JSON.stringify(incident)
  return fetch(baseUrl, {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getIncidentById(id) {
  return fetch(baseUrl + '/' + id, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getUnreferencedIncidentControlNumbers(date) {
  return fetch(baseUrl + '/ControlNumbers/' + date, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getPdfReport(incidentId, incidentControlNumber) {
  var url = new URL(baseUrl + '/pdfReport/' + incidentId)
  url.search = new URLSearchParams({
    dateTimeOffsetInMinutes: new Date().getTimezoneOffset(),
  }).toString()

  return fetch(url, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then((response) => {
      response.blob().then((blob) => {
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = 'Incident Report ' + incidentControlNumber + '.pdf'
        link.click()
        link.remove()
      })
    })
    .catch(handleError)
}

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Checkbox from '@material-ui/core/Checkbox'

const RequiredCheckboxField = ({
  label,
  value,
  onChange,
  invalid,
  errorMessage,
}) => (
  <>
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          label={'z'}
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          style={
            invalid
              ? {
                  color: 'red',
                }
              : {}
          }
        />
      }
      style={
        invalid
          ? {
              color: 'red',
            }
          : {}
      }
    />
    <FormHelperText style={{ color: 'red' }}>
      {invalid ? errorMessage : ' '}
    </FormHelperText>
  </>
)

RequiredCheckboxField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  invalid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
}

const FormRequiredCheckbox = ({
  name,
  label,
  defaultValue = false,
  rules,
  errorMessage = ' ',
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ onChange, value }, { invalid }) => (
        <RequiredCheckboxField
          label={label}
          value={value}
          onChange={onChange}
          invalid={invalid}
          errorMessage={errorMessage}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

FormRequiredCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.bool,
  rules: PropTypes.object,
  errorMessage: PropTypes.string,
}

export default FormRequiredCheckbox

import React, { useEffect, useState } from 'react'
import UserTable from '../molecules/UserTable'
import EditUserDialog from '../molecules/EditUserDialog'
import {
  loadUsersAsync,
  selectOrderedUsers,
  updateUserAsync,
} from '../../slices/userManagementSlice'
import { loadRolesAsync, selectRoles } from '../../slices/roleSlice'
import {
  loadPropertiesAsync,
  selectOrderedProperties,
} from '../../slices/propertySlice'
import { useDispatch, useSelector } from 'react-redux'
import { CircularProgress, makeStyles } from '@material-ui/core'
import { spinnerSize } from '../../../common/constants'

const useStyles = makeStyles({
  table: {
    padding: '1em',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
})

const UserManagement = () => {
  const classes = useStyles()

  const dispatch = useDispatch()

  const users = useSelector(selectOrderedUsers)
  const roles = useSelector(selectRoles)
  const propertiesSelector = useSelector(selectOrderedProperties)

  const [dataLoaded, setDataLoaded] = useState(false)
  const loaded = () => setDataLoaded(true)

  const [userToEdit, setUserToEdit] = useState({})
  const [openEditUserDialog, setOpenEditUserDialog] = useState(false)

  const editUser = (user) => {
    setUserToEdit(user)
    setOpenEditUserDialog(true)
  }

  const saveUserEdit = (user) => {
    dispatch(updateUserAsync(user))
    setOpenEditUserDialog(false)
  }

  useEffect(() => {
    if (users.length === 0) {
      dispatch(loadUsersAsync())
    } else if (roles.length === 0) {
      dispatch(loadRolesAsync())
    } else if (propertiesSelector.properties.length === 0) {
      dispatch(loadPropertiesAsync())
    } else {
      loaded()
    }
  }, [users.length, roles.length, propertiesSelector.properties.length])

  const RenderDialog = () => {
    if (openEditUserDialog) {
      return (
        <EditUserDialog
          open={openEditUserDialog}
          user={userToEdit}
          handleClose={() => setOpenEditUserDialog(false)}
          roles={roles}
          properties={propertiesSelector.properties}
          saveChanges={saveUserEdit}
        />
      )
    }
    return null
  }

  return (
    <>
      {dataLoaded === true ? (
        <div>
          <div className={classes.table}>
            <UserTable
              users={users}
              roles={roles}
              properties={propertiesSelector.properties}
              handleEditUserClick={editUser}
              className='table'
            />
          </div>
          <RenderDialog />
        </div>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
    </>
  )
}

export default UserManagement

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Button,
  ListItemText,
  Checkbox,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { selectPrimaryThemeColor } from '../../slices/themeColorSlice'

const EditUserDialog = ({
  user,
  open,
  handleClose,
  roles,
  properties,
  saveChanges,
}) => {
  const useStyles = makeStyles((theme) => ({
    formControl: {
      width: '95%',
    },
    buttonSave: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      float: 'right',
    },
  }))

  const [roleValue, setRoleValue] = useState(user.roleId)
  const [propertyValue, setPropertyValue] = useState(user.propertyIds)
  const classes = useStyles()

  const handleRoleChange = (event) => {
    setRoleValue(event.target.value)
  }

  const handlePropertyChange = (event) => {
    setPropertyValue(event.target.value)
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Edit User Access</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label='User'
              value={user.displayName}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink id='edit-user-role-label'>
                Role
              </InputLabel>
              <Select
                id='role'
                value={roleValue || null}
                onChange={handleRoleChange}
                displayEmpty
              >
                <MenuItem value={null}>None</MenuItem>
                {roles.map((role) => (
                  <MenuItem key={role.roleId} value={role.roleId}>
                    {role.roleName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.formControl}>
              <InputLabel shrink>Properties</InputLabel>
              <Select
                id='properties'
                label='Properties'
                multiple
                displayEmpty
                value={propertyValue}
                onChange={handlePropertyChange}
                renderValue={(selected) =>
                  selected.length == 0
                    ? 'None'
                    : selected
                        .map((id) => properties.find((p) => p.id == id).name)
                        .join(', ')
                }
              >
                {properties.map((property) => (
                  <MenuItem key={property.id} value={property.id}>
                    <Checkbox checked={propertyValue.includes(property.id)} />
                    <ListItemText primary={property.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify='space-between' spacing={1}>
          <Grid item xs={2}>
            <Button variant='contained' onClick={handleClose} fullWidth={true}>
              Cancel
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              className={classes.buttonSave}
              variant='contained'
              startIcon={<SaveIcon />}
              fullWidth={true}
              onClick={() =>
                saveChanges({
                  ...user,
                  roleId: roleValue,
                  propertyIds: propertyValue,
                })
              }
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

EditUserDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    userId: PropTypes.number.isRequired,
    displayName: PropTypes.string.isRequired,
    roleId: PropTypes.number,
    propertyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  }),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleId: PropTypes.number.isRequired,
      roleName: PropTypes.string.isRequired,
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  saveChanges: PropTypes.func.isRequired,
}

export default EditUserDialog

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import NumberFormat from 'react-number-format'

const MaskedInputField = ({
  label,
  prefix,
  suffix,
  mask,
  maskChar,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
}) => (
  <NumberFormat
    prefix={prefix}
    suffix={suffix}
    customInput={TextField}
    format={mask}
    mask={maskChar}
    label={label}
    value={value}
    onChange={onChange}
    error={invalid}
    helperText={invalid ? errorMessage : ' '}
    InputLabelProps={{
      className: required ? 'required-label' : '',
      required: required || false,
    }}
    fullWidth={true}
  />
)

MaskedInputField.propTypes = {
  label: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
}

const FormMaskInput = ({
  name,
  label,
  prefix = undefined,
  suffix = undefined,
  mask = undefined,
  maskChar = undefined,
  required = false,
  errorMessage = ' ',
  rules = {},
  defaultValue = '',
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ onChange, value }, { invalid }) => (
        <MaskedInputField
          label={label}
          prefix={prefix}
          suffix={suffix}
          mask={mask}
          maskChar={maskChar}
          value={value}
          onChange={onChange}
          required={required}
          invalid={invalid}
          errorMessage={errorMessage}
        />
      )}
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={rules}
    />
  )
}

FormMaskInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  mask: PropTypes.string,
  maskChar: PropTypes.string,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
}

export default FormMaskInput

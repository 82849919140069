import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { HexColorPicker, HexColorInput } from 'react-colorful'
import 'react-colorful/dist/index.css'
import { Popover, Button, TextField, Grid, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  center: {
    margin: 2,
    width: 50,
    padding: 15,
  },
  disabledInput: {
    color: theme.palette.text.primary,
  },
}))

const ColorPicker = ({ label, color, setColor }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button color='default' onClick={handleClick}>
        <TextField
          style={{ padding: 10 }}
          value={color}
          label={label}
          disabled={true}
          InputProps={{
            classes: {
              disabled: classes.disabledInput,
            },
          }}
          InputLabelProps={{ disabled: false }}
        />
        <div
          style={{
            backgroundColor: color,
            height: 50,
            width: 50,
            padding: 20,
          }}
        />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Grid container>
          <Grid item xs={12} className={classes.center}>
            <HexColorPicker
              color={color}
              onChange={setColor}
              style={{ width: 100 }}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center', paddingBottom: 10 }}>
            <HexColorInput color={color} onChange={setColor} />
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

ColorPicker.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
}

export default ColorPicker

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import Grid from '@material-ui/core/Grid'
import { makeStyles, Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormInput from '../atoms/FormInput'
import FormSelect from '../atoms/FormSelect'
import FormTable from './FormTable'
import FormDisplayField from '../atoms/FormDisplayField'

const columns = [
  {
    header: 'First Name',
    accessor: 'firstName',
  },
  {
    header: 'Last Name',
    accessor: 'lastName',
  },
  {
    header: 'Is Tenant',
    accessor: 'isTenant',
  },
  {
    header: 'Building',
    accessor: 'building',
  },
  {
    header: 'Apartment',
    accessor: 'apartment',
  },
]

const weaponStatus = [
  { id: 1, name: 'Possessed' },
  { id: 2, name: 'Used' },
]

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const PerpetratorFields = ({ defaultValues = {}, openDialog, list = [] }) => {
  const classes = useStyles()
  const { watch } = useFormContext()
  const perpetratorCount = list.length
  const watchWeaponStatus = watch('event.weaponId')
  return (
    <>
      <SectionHeader sectionName='Perpetrators' />
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={2}>
          <FormDisplayField
            name='event.numberOfPerpetrators'
            label='Total No. of Perpetrators'
            value={perpetratorCount}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.numberArrested'
            label='No. Arrested'
            defaultValue={defaultValues.numberArrested || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.weaponId'
            label='Weapon'
            options={weaponStatus}
            defaultValue={defaultValues.weaponStatus || null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          {watchWeaponStatus != null && (
            <FormInput
              name='event.describeWeapon'
              label='Describe Weapon'
              defaultValue={defaultValues.weaponDescription || ''}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <FormInput
            name='event.uf16'
            label='UF 61'
            defaultValue={defaultValues.uf61 || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.voucherNumber'
            label='Voucher No. (s)'
            defaultValue={defaultValues.voucherNumbers || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.scs'
            label='SCS'
            defaultValue={defaultValues.scs || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.nypd'
            label='NYPD'
            defaultValue={defaultValues.nypd || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={12}>
          <FormTable
            name={'event.perpetrators'}
            columns={columns}
            data={list}
            handleOpen={openDialog}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => openDialog()} startIcon={<AddCircleIcon />}>
            Add a perpetrator
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

PerpetratorFields.propTypes = {
  defaultValues: PropTypes.shape({}),
  openDialog: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
}

export default PerpetratorFields

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/attachment'

export async function uploadFile(file) {
  const formData = new FormData()
  formData.append('attachment', file)
  return fetch(baseUrl + '/upload', {
    method: 'POST',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
    },
    body: formData,
  })
    .then(handleResponse)
    .catch(handleError)
}

export async function downloadAttachment(attachmentId, attachmentName) {
  return fetch(baseUrl + '/' + attachmentId, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
    },
  }).then((response) => {
    response.blob().then((blob) => {
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.href = url
      link.download = attachmentName
      link.click()
      link.remove()
    })
  })
}

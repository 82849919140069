import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/admin/users'

export function getUsers() {
  return fetch(baseUrl, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

export function updateUser(user) {
  const userCommand = { user: user }
  const body = JSON.stringify(userCommand)
  return fetch(baseUrl + '/' + user.userId, {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

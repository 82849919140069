import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as parkingSpotApi from '../../apiCalls/parkingspot'

const initialState = {
  status: apiStatus.idle,
  error: null,
  parkingSpots: [],
}

const parkingSpotSlice = createSlice({
  name: 'parkingSpots',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadParkingSpotsSuccess(status, { payload }) {
      return { ...status, status: apiStatus.succeeded, parkingSpots: payload }
    },
    updateParkingSpotsSuccess(state, { payload }) {
      let updatedParkingSpots = state.parkingSpots.map((parkingSpot) => {
        if (parkingSpot.id != payload.id) {
          return parkingSpot
        }
        return {
          ...parkingSpot,
          ...payload,
        }
      })

      return {
        ...state,
        parkingSpots: updatedParkingSpots,
        status: apiStatus.succeeded,
      }
    },
    addParkingSpotsSuccess(state, { payload }) {
      let updatedParkingSpots = [...state.parkingSpots, payload]
      return {
        ...state,
        parkingSpots: updatedParkingSpots,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadParkingSpotsSuccess: loadParkingSpotsSuccess,
  updateParkingSpotsSuccess: updateParkingSpotsSuccess,
  addParkingSpotsSuccess: addParkingsSpotsSuccess,
} = parkingSpotSlice.actions

export const loadParkingSpotsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await parkingSpotApi
    .getParkingSpots()
    .then((parkingSpots) => {
      dispatch(loadParkingSpotsSuccess(parkingSpots))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateParkingSpotAsync = (parkingSpot) => async (dispatch) => {
  dispatch(beginApiCall())
  return await parkingSpotApi
    .updateParkingSpot(parkingSpot)
    .then(() => dispatch(updateParkingSpotsSuccess(parkingSpot)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const addParkingSpotAsync = (parkingSpot) => async (dispatch) => {
  dispatch(beginApiCall())
  return await parkingSpotApi
    .addParkingSpot(parkingSpot)
    .then((parkingSpotId) =>
      dispatch(addParkingsSpotsSuccess({ id: parkingSpotId, ...parkingSpot }))
    )
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectParkingSpots = (state) => state.parkingSpots

export const selectParkingSpotsWithFilter =
  (active, locationId, selectedParkingSpotId = null) =>
  (state) => {
    var parkingSpotsSelector = selectParkingSpots(state)
    return {
      ...parkingSpotsSelector,
      parkingSpots: parkingSpotsSelector.parkingSpots
        .filter(
          (a) =>
            (a.isActive == active && a.locationId == locationId) ||
            (a.id == selectedParkingSpotId && a.locationId == locationId)
        )
        .sort((a, b) => {
          var x = a.name.toLowerCase()
          var y = b.name.toLowerCase()
          if (x < y) {
            return -1
          }
          if (x > y) {
            return 1
          }
          return 0
        }),
    }
  }

export const selectAllActiveParkingSpots = () => (state) => {
  const { parkingSpots } = selectParkingSpots(state)
  return {
    ...selectParkingSpots(state),
    parkingSpots: parkingSpots
      .filter((a) => a.isActive === true)
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default parkingSpotSlice.reducer

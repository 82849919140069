import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const DeleteAttachmentAlert = ({
  open = false,
  cancel,
  confirm,
  attachment = { name: '' },
}) => {
  return (
    <Dialog
      open={open}
      onClose={cancel}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{'Delete Attachement'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          You are about to delete an attachment ({attachment.name}). Please
          confirm.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} color='primary'>
          Cancel
        </Button>
        <Button
          onClick={() => confirm(attachment.attachmentId)}
          color='primary'
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

DeleteAttachmentAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  attachment: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
}

export default DeleteAttachmentAlert

import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

const InputField = ({ label, value }) => (
  <TextField label={label} value={value} fullWidth={true} disabled={true} />
)

InputField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
}

const FormDisplayField = ({ name, label, value = '' }) => {
  const { control } = useFormContext()

  return (
    <Controller
      render={() => <InputField label={label} value={value} />}
      name={name}
      control={control}
      defaultValue={value}
    />
  )
}

FormDisplayField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
}

export default FormDisplayField

import React from 'react'
import PropTypes from 'prop-types'
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import TodayIcon from '@material-ui/icons/Today'

import { dateFormat, days } from '../../../common/constants'

const DatePickerField = ({
  label,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
  showDay = true,
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        label={label}
        value={value}
        onChange={onChange}
        InputLabelProps={{
          className: required ? 'required-label' : ' ',
          required: required || false,
        }}
        error={invalid}
        helperText={
          invalid
            ? errorMessage
            : value && showDay
            ? days[new Date(value).getDay()]
            : ' '
        }
        format={dateFormat}
        variant='inline'
        maskChar='-'
        autoOk={true}
        keyboardIcon={<TodayIcon />}
        fullWidth={true}
      />
    </MuiPickersUtilsProvider>
  )
}

DatePickerField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  showDay: PropTypes.bool,
}

export default DatePickerField

import React from 'react'
import PropTypes from 'prop-types'

const NoAccess = ({ reason = '' }) => {
  return (
    <div style={{ paddingLeft: '2%' }}>
      <h1>Unauthorized {reason}</h1>{' '}
      <h3>Please contact your CHIEF system admin or IT support.</h3>
    </div>
  )
}

NoAccess.propTypes = {
  reason: PropTypes.string,
}

export default NoAccess

import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/admin'

export function getThemeColors() {
  return fetch(baseUrl + '/themeColors')
    .then(handleResponse)
    .catch(handleError)
}

export function updateThemeColor(themeColorChange) {
  const theme = { themeColor: themeColorChange }
  const body = JSON.stringify(theme)
  return fetch(baseUrl + '/themeColors', {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

export function getThemeImages() {
  return fetch(baseUrl + '/themeImages')
    .then(handleResponse)
    .catch(handleError)
}

export function updateThemeImage(themeImageChange) {
  const theme = { themeImage: themeImageChange }
  const body = JSON.stringify(theme)
  return fetch(baseUrl + '/themeImages', {
    method: 'PUT',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: body,
  })
    .then(handleResponse)
    .catch(handleError)
}

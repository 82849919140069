import React from 'react'
import { useFormContext } from 'react-hook-form'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormTimePicker from '../atoms/FormTimePicker'
import FormSelect from '../atoms/FormSelect'

const conditions = [
  { id: 1, name: 'Good' },
  { id: 2, name: 'Fair' },
  { id: 3, name: 'Poor' },
]

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const EvictionFields = () => {
  const { watch } = useFormContext()
  const classes = useStyles()

  const watchManagementAtScene = watch(
    'event.eviction.managementPresentAtScene'
  )
  const watchMaintenanceAtScene = watch(
    'event.eviction.maintenancePresentAtScene'
  )
  const watchPropertyCondition = watch('event.eviction.apartmentConditionId')

  return (
    <>
      <SectionHeader sectionName='Eviction' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={3}>
          <FormInput
            name='event.eviction.tenantName'
            label='Tenant Name'
            required={true}
            errorMessage='Tenant Name field is required.'
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <FormInput
            name='event.eviction.address'
            label='Address'
            required={true}
            errorMessage='Address field is required.'
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.eviction.timeApartmentSecured'
            label='Time Apartment Secured'
            required={true}
            errorMessage='A valid time is required'
            rules={{
              required: true,
              validate: (value) => !value || !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name='event.eviction.marshalName'
            label='Marshal Name'
            required={true}
            errorMessage='Marshal Name field is required.'
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.eviction.marshalRemainedOnScene'
            label='Marshal Remained on Scene'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.eviction.managementPresentAtScene'
            label='Management Personnel Present at Scene'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          {watchManagementAtScene && (
            <FormInput
              name='event.eviction.managementPersonnelName'
              label='Personnel Name'
              required={true}
              errorMessage={'Personnel Name field is required'}
              rules={{ required: true }}
            />
          )}
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.eviction.maintenancePresentAtScene'
            label='Maintenance Personnel Present at Scene'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          {watchMaintenanceAtScene && (
            <FormInput
              name='event.eviction.maintenancePersonnelName'
              label='Personnel Name'
              required={true}
              errorMessage={'Personnel Name field is required'}
              rules={{ required: true }}
            />
          )}
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={3}>
          <FormSelect
            name='event.eviction.apartmentConditionId'
            label='Condition of Apartment & Housing Company Property'
            options={conditions}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6}>
          {watchPropertyCondition && (
            <FormInput
              name='event.eviction.propertyConditionExplanation'
              label='Explain'
              required={true}
              errorMessage={'Explain field is required'}
              rules={{ required: true }}
            />
          )}
        </Grid>
        <Grid item xs={2} />
      </Grid>
    </>
  )
}

export default EvictionFields

import { configureStore } from '@reduxjs/toolkit'
import usersReducer from '../admin/slices/userManagementSlice'
import roleReducer from '../admin/slices/roleSlice'
import propertyReducer from '../admin/slices/propertySlice'
import sectionReducer from '../admin/slices/sectionSlice'
import buildingReducer from '../admin/slices/buildingSlice'
import locationReducer from '../admin/slices/locationSlice'
import apartmentReducer from '../admin/slices/apartmentSlice'
import floorReducer from '../admin/slices/floorSlice'
import storeReducer from '../admin/slices/storeSlice'
import eventDispatchedStatusReducer from '../eventReports/slices/eventDispatchedStatusSlice'
import eventConditionsReducer from '../eventReports/slices/eventConditionsSlice'
import eventPlatoonsReducer from '../eventReports/slices/eventPlatoonsSlice'
import callerOriginsSlice from '../eventReports/slices/callerOriginsSlice'
import incidentClassificationCodesReducer from '../eventReports/slices/incidentClassificationCodesSlice'
import dispositionCodesReducer from '../eventReports/slices/dispositionCodesSlice'
import themeColorsReducer from '../admin/slices/themeColorSlice'
import themeImageReducer from '../admin/slices/themeImageSlice'
import callLogControlNumbersReducer from '../eventReports/slices/callLogControlNumberSlice'
import incidentControlNumbersReducer from '../eventReports/slices/incidentControlNumberSlice'
import callTypesReducer from '../eventReports/slices/callTypesSlice'
import currentUserReducer from '../admin/slices/currentUserSlice'
import tenantReducer from '../admin/slices/tenantSlice'
import stateCodesReducer from '../eventReports/slices/stateCodesSlice'
import parkingSpotReducer from '../admin/slices/parkingSpotSlice'

export default configureStore({
  reducer: {
    users: usersReducer,
    roles: roleReducer,
    properties: propertyReducer,
    sections: sectionReducer,
    buildings: buildingReducer,
    locations: locationReducer,
    apartments: apartmentReducer,
    floors: floorReducer,
    stores: storeReducer,
    eventDispatchedStatus: eventDispatchedStatusReducer,
    eventConditions: eventConditionsReducer,
    eventPlatoons: eventPlatoonsReducer,
    callerOrigins: callerOriginsSlice,
    callTypes: callTypesReducer,
    incidentClassificationCodes: incidentClassificationCodesReducer,
    dispositionCodes: dispositionCodesReducer,
    themeColors: themeColorsReducer,
    themeImages: themeImageReducer,
    availableCallLogControlNumbers: callLogControlNumbersReducer,
    availableIncidentControlNumbers: incidentControlNumbersReducer,
    currentUser: currentUserReducer,
    tenants: tenantReducer,
    stateCodes: stateCodesReducer,
    parkingSpots: parkingSpotReducer,
  },
})

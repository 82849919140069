import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/auth'

export async function getAuthToken(adToken) {
  const headers = new Headers()
  const bearer = `Bearer ${adToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json; charset=utf-8')

  const options = {
    method: 'GET',
    headers: headers,
  }
  return fetch(baseUrl + '/token', options)
    .then(handleResponse)
    .then((e) => {
      sessionStorage.setItem('CHIEFToken', JSON.stringify(e))
    })
    .catch(handleError)
}

export async function getUserProfile() {
  const authToken = JSON.parse(sessionStorage.getItem('CHIEFToken')).token
  const headers = new Headers()
  const bearer = `Bearer ${authToken}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json; charset=utf-8')

  const options = {
    method: 'GET',
    headers: headers,
  }

  return fetch(baseUrl + '/profile', options)
    .then(handleResponse)
    .catch(handleError)
}

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import FormInput from '../atoms/FormInput'
import FormEventTypeSelect from '../atoms/FormEventTypeSelect'
import FormSelect from '../atoms/FormSelect'
import FormDatePicker from '../atoms/FormDatePicker'
import FormTimePicker from '../atoms/FormTimePicker'
import FormTextArea from '../atoms/FormTextArea'

const UnusualIncidentEventDetailsFields = ({
  defaultValues,
  eventTypes,
  eventTypeChangeHandler,
  callControlNumber,
  properties,
}) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction='row'
        alignItems='center'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          width: '95%',
        }}
      >
        <Grid item xs={2}>
          <FormEventTypeSelect
            name='event.type'
            label='Event Type'
            options={eventTypes}
            required={true}
            errorMessage={'Event Type field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.type || ''}
            changeHandler={eventTypeChangeHandler}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.preparedBy'
            label='Prepared By'
            required={true}
            errorMessage='Prepared By field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.preparedBy || ''}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <FormSelect
            name='event.callLogControlNumber'
            label='Call Control Number'
            options={callControlNumber}
            required={true}
            errorMessage={'Call Control Number field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.callLogControlNumber || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormDatePicker
            name='event.dateOfIncident'
            label='Date of Incident'
            required={true}
            errorMessage='A valid date is required'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={defaultValues.dateOfIncident || new Date()}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeOfIncident'
            label='Time of Incident'
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={defaultValues.timeOfIncident || new Date()}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.propertyId'
            label='Property'
            options={properties}
            required={true}
            errorMessage={'Property field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.propertyId || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.locationOfIncident'
            label='Location Of Incident'
            required={true}
            errorMessage='Location of Incident field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.locationOfIncident || ''}
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name='event.typeOfIncident'
            label='Type of Incident'
            required={true}
            errorMessage='Type of Incident field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.typeOfIncident || ''}
          />
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={3}>
          <FormInput
            name='event.supervisorName'
            label='Supervisor Name'
            required={true}
            errorMessage='Supervisor Name field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.supervisorName || ''}
          />
        </Grid>
        <Grid item xs={5}>
          <FormInput
            name='event.otherAgenciesPresent'
            label='Any other agencies present (e.g. PD, FP, EMS, OEM)'
            defaultValue={defaultValues.otherAgenciesPresent || ''}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={5}>
          <FormInput
            name='event.otherSCTDepartments'
            label='Any other Resident Departments'
            defaultValue={defaultValues.otherSCTDepartments || ''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormTextArea
            name='event.incidentDetails'
            label='Details of Incident'
            rows={20}
            required={true}
            errorMessage='Incident Details field is required'
            rules={{ required: true }}
            defaultValue={defaultValues.incidentDetails || ''}
          />
        </Grid>
      </Grid>
    </>
  )
}

UnusualIncidentEventDetailsFields.propTypes = {
  defaultValues: PropTypes.shape({
    type: PropTypes.number,
    preparedBy: PropTypes.string,
    callControlNumber: PropTypes.number,
    dateOfIncident: PropTypes.instanceOf(Date),
    timeOfIncident: PropTypes.instanceOf(Date),
    property: PropTypes.any,
    locationOfIncident: PropTypes.string,
    supervisorName: PropTypes.string,
    otherAgenciesPresent: PropTypes.string,
    otherSCTDepartment: PropTypes.string,
    incidentDetails: PropTypes.string,
  }),
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  eventTypeChangeHandler: PropTypes.func.isRequired,
  callControlNumber: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
}

export default UnusualIncidentEventDetailsFields

import { createSlice } from '@reduxjs/toolkit'
import * as roleApi from '../../apiCalls/role'

const roleSlice = createSlice({
  name: 'roles',
  initialState: [],
  reducers: {
    loadRoles(_, action) {
      return action.payload
    },
  },
})

const { loadRoles } = roleSlice.actions

export const loadRolesAsync = () => async (dispatch) => {
  return await roleApi
    .getRoles()
    .then((roles) => {
      dispatch(loadRoles(roles))
    })
    .catch((error) => {
      console.log(error)
      //dispatch to handle error needs to come in here
      throw error
    })
}

export const selectRoles = (state) => state.roles

export default roleSlice.reducer

import React from 'react'
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const FormEventTypeMultiSelect = ({
  label,
  eventTypes = [],
  updateEventTypes,
  selectedValues = [],
}) => {
  const handleChange = (event) => {
    const selected = event.target.value
    updateEventTypes(selected)
  }
  return (
    <>
      <FormControl fullWidth={true}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={selectedValues}
          onChange={handleChange}
          input={<Input />}
          renderValue={() =>
            selectedValues.length < 3
              ? selectedValues
                  .map((s) => eventTypes.find((t) => t.id == s).name)
                  .join(', ')
              : selectedValues.length + ' items selected'
          }
        >
          {eventTypes.map((type) => (
            <MenuItem key={type.id} value={type.id}>
              <Checkbox checked={selectedValues.indexOf(type.id) > -1} />
              <ListItemText primary={type.name} />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText> </FormHelperText>
      </FormControl>
    </>
  )
}

FormEventTypeMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  eventTypes: PropTypes.array,
  updateEventTypes: PropTypes.func,
  selectedValues: PropTypes.array,
}

export default FormEventTypeMultiSelect

import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  withStyles,
  makeStyles,
} from '@material-ui/core'
import {
  selectPrimaryThemeColor,
  selectTertiaryThemeColor,
} from '../../slices/themeColorSlice'
import { useSelector } from 'react-redux'

const UserTable = ({ users, roles, properties, handleEditUserClick }) => {
  const StyledTableCell = withStyles((theme) => ({
    head: {
      background: useSelector(selectPrimaryThemeColor()),
      color: theme.palette.common.white,
    },
  }))(TableCell)

  const useStyles = makeStyles({
    button: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      color: 'white',
      height: '30px',
    },
  })

  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table aria-label='user table' size={'small'}>
        <TableHead className={classes.header}>
          <TableRow>
            <StyledTableCell style={{ width: '40%' }}>
              Display Name
            </StyledTableCell>
            <StyledTableCell style={{ width: '25%' }}>Role</StyledTableCell>
            <StyledTableCell style={{ width: '25%' }}>
              Properties
            </StyledTableCell>
            <StyledTableCell style={{ width: '10%' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.userId} hover>
              <TableCell component='th' scope='row'>
                {user.displayName}
              </TableCell>
              <TableCell>
                {
                  (
                    roles.find((role) => role.roleId == user.roleId) || {
                      roleName: '',
                    }
                  ).roleName
                }
              </TableCell>
              <TableCell>
                {user.propertyIds
                  .map((id) => properties.find((p) => p.id == id).name)
                  .sort((a, b) =>
                    a.localeCompare(b, undefined, { sensitivity: 'base' })
                  )
                  .join(', ')}
              </TableCell>
              <TableCell>
                <Button
                  className={classes.button}
                  variant='contained'
                  color='default'
                  onClick={() => handleEditUserClick(user)}
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.number.isRequired,
      displayName: PropTypes.string.isRequired,
      roleId: PropTypes.number,
      propertyIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    })
  ),
  roles: PropTypes.arrayOf(
    PropTypes.shape({
      roleId: PropTypes.number.isRequired,
      roleName: PropTypes.string.isRequired,
    })
  ),
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  handleEditUserClick: PropTypes.func.isRequired,
}

export default UserTable

import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Grid,
  Button,
  Checkbox,
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(20),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: 'white',
  },
}))

const LocationAccordionHeader = ({
  title,
  filterValue,
  addFunction,
  showInactiveState,
  showInactiveOnChange,
  extClasses = useStyles(),
}) => {
  const classes = useStyles()
  return (
    <Grid container direction='row' alignItems='center'>
      <Grid item xs={2}>
        <Typography className={classes.heading}>{title}</Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography className={classes.secondaryHeading}>
          {filterValue}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Checkbox
          checked={showInactiveState}
          onClick={(event) => showInactiveOnChange(event)}
          style={{ color: 'white' }}
        />
        <label>Show Inactive</label>
      </Grid>
      <Grid item xs={1}>
        <Button
          className={extClasses.addButton}
          variant='contained'
          onClick={(event) => addFunction(event)}
        >
          + ADD
        </Button>
      </Grid>
    </Grid>
  )
}

LocationAccordionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  filterValue: PropTypes.string,
  addFunction: PropTypes.func.isRequired,
  showInactiveState: PropTypes.bool.isRequired,
  showInactiveOnChange: PropTypes.func.isRequired,
}

export default LocationAccordionHeader

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormTable from './FormTable'
import FormCheckbox from '../atoms/FormCheckbox'
import { Button } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const columns = [
  {
    header: 'First Name',
    accessor: 'firstName',
  },
  {
    header: 'Last Name',
    accessor: 'lastName',
  },
  {
    header: 'Is Tenant',
    accessor: 'isTenant',
  },
  {
    header: 'Building',
    accessor: 'building',
  },
  {
    header: 'Apartment',
    accessor: 'apartment',
  },
]

const ComplainantsWitnessesFields = ({ openDialog, list = [] }) => {
  return (
    <>
      <SectionHeader sectionName='Complainants & Witnesses' />
      <Grid
        container
        spacing={0}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          width: '95%',
        }}
      >
        <Grid item xs={12}>
          <FormCheckbox name='event.isSCT' label='Is Resident?' />
        </Grid>
        <Grid item xs={12}>
          <FormTable
            name={'event.complainanantsAndWitnesses'}
            columns={columns}
            data={list}
            handleOpen={openDialog}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => openDialog()} startIcon={<AddCircleIcon />}>
            Add a complainant / witness
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

ComplainantsWitnessesFields.propTypes = {
  openDialog: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
}

export default ComplainantsWitnessesFields

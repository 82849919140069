import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormInput from '../atoms/FormInput'
import FormSignature from '../atoms/FormSignature'
import FormRequiredCheckbox from '../atoms/FormRequiredCheckbox'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const ApprovalFields = ({ defaultValues = {} }) => {
  const classes = useStyles()
  const { watch } = useFormContext()
  const watchSupervisorSigned = watch(
    'event.accidentApproval.approverSignature'
  )

  const watchSupervisorName = watch(
    'event.accidentApproval.approvingSupervisor'
  )

  const supervisorRequired = () => {
    return watchSupervisorSigned
      ? watchSupervisorSigned.signature.length > 0 &&
          watchSupervisorSigned.date.length > 0
      : false
  }

  const supervisorShieldNumberRequired = () => {
    if (supervisorRequired()) return true
    if (watchSupervisorName) return true
    return false
  }

  return (
    <>
      <SectionHeader sectionName='Approval' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={4}>
          <FormInput
            name='event.accidentApproval.reportedBy'
            label='Reported By (Print name)'
            required={true}
            errorMessage={'Reported By field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.reportedBy || ''}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={4}>
          <FormInput
            name='event.accidentApproval.approvingSupervisor'
            label='Supervisor Approving (Print name)'
            required={supervisorRequired()}
            errorMessage={'Supervisor Approving field is required'}
            rules={{ required: supervisorRequired() }}
            defaultValue={defaultValues.approvingSupervisor || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormRequiredCheckbox
            name='event.accidentApproval.reporterConfirmationOfAccuracy'
            label='I have reviewed this incident and confirm that the information herein is correct'
            rules={{ validate: (value) => value }}
            errorMessage={'Please confirm'}
            defaultValue={defaultValues.reporterConfirmationOfAccuracy || false}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={3}>
          <FormRequiredCheckbox
            name='event.accidentApproval.approverConfirmationOfAccuracy'
            label='I have reviewed this incident and approve the information herein'
            rules={{
              validate: (value) =>
                (!supervisorRequired() && !watchSupervisorName) || value,
            }}
            errorMessage={'Please confirm'}
            defaultValue={defaultValues.approverConfirmationOfAccuracy || false}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <FormInput
            name='event.accidentApproval.reporterShieldNumber'
            label='Shield No.'
            required={true}
            errorMessage={'Shield No. is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.reporterShieldNumber || ''}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={2}>
          <FormInput
            name='event.accidentApproval.approverShieldNumber'
            label='Shield No.'
            required={supervisorShieldNumberRequired()}
            errorMessage={'Shield No. field is required'}
            rules={{ required: supervisorShieldNumberRequired() }}
            defaultValue={defaultValues.approverShieldNumber || ''}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={4}>
          <FormSignature
            name='event.accidentApproval.reporterSignature'
            label="Reporting Officer's Signature (Print initials)"
            required={true}
            errorMessage={"Reporting Officer's Signature field is required"}
            rules={{
              required: true,
              validate: (value) => {
                return value.signature.length > 0 && value.date.length > 0
              },
            }}
            defaultValue={
              defaultValues.reporterSignature || {
                signature: '',
                date: '',
              }
            }
            saved={defaultValues.reporterSignature ? true : false}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={4}>
          <FormSignature
            name='event.accidentApproval.approverSignature'
            label='Signature of Supervisor (Print initials)'
            required={watchSupervisorName}
            errorMessage={"Supervising Officer's Signature field is required"}
            rules={{
              validate: (value) => {
                return (
                  (value.signature.length === 0 &&
                    value.date.length === 0 &&
                    !watchSupervisorName) ||
                  (value.signature.length > 0 && value.date.length > 0)
                )
              },
            }}
            defaultValue={
              defaultValues.approverSignature || {
                signature: '',
                date: '',
              }
            }
            saved={defaultValues.approverSignature ? true : false}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  )
}

ApprovalFields.propTypes = {
  defaultValues: PropTypes.shape({
    reporterName: PropTypes.string,
    reporterConfirmation: PropTypes.bool,
    reporterShieldNumber: PropTypes.string,
    reporterSignature: PropTypes.shape({
      signature: PropTypes.string,
      date: PropTypes.instanceOf(Date),
    }),
    supervisorName: PropTypes.string,
    supervisorConfirmation: PropTypes.bool,
    supervisorShieldNumber: PropTypes.string,
    supervisorSignature: PropTypes.shape({
      signature: PropTypes.string,
      date: PropTypes.instanceOf(Date),
    }),
  }),
}

export default ApprovalFields

import React from 'react'
import PageHeader from '../../../common/components/organisms/PageHeader'
import SearchEvents from '../organisms/SearchEvents'

const SearchIssues = () => {
  return (
    <>
      <div>
        <PageHeader pageName='Search Events' />
      </div>
      <SearchEvents />
    </>
  )
}

export default SearchIssues

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import Grid from '@material-ui/core/Grid'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  makeStyles,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import FormSelect from '../atoms/FormSelect'
import FormTenantSelect from '../atoms/FormTenantSelect'
import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'
import FormDatePicker from '../atoms/FormDatePicker'
import FormMaskedInput from '../atoms/FormMaskedInput'
import FormTextArea from '../atoms/FormTextArea'
import { phoneNumberFormat } from '../../../common/constants'
import { useSelector } from 'react-redux'

import FormLocationSelect from '../atoms/FormLocationSelect'

import { selectSectionsWithFilter } from '../../../admin/slices/sectionSlice'
import { selectBuildingsWithFilter } from '../../../admin/slices/buildingSlice'
import { selectLocationIdForApartmentWithBuildingFilter } from '../../../admin/slices/locationSlice'
import { selectApartmentsWithFilter } from '../../../admin/slices/apartmentSlice'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'

const PeopleInvolvedDialog = ({
  open,
  handleClose,
  handleRemove,
  handleSave,
  person = {},
  propertyFilterValue,
  stateList,
  tenantList,
  personInvolvedTypes,
}) => {
  const useStyles = makeStyles({
    closeBtn: { position: 'absolute', right: '0px' },
    buttonSave: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      float: 'right',
    },
  })
  const classes = useStyles()

  const methods = useForm()
  const { watch, handleSubmit, setValue } = methods

  const [loaded, setLoaded] = useState(false)

  const watchPersonType = watch('personTypeId', person.personTypeId || false)
  const watchIndicatedCause = watch(
    'injuredPersonIndicatedCauseOfAccident',
    person.injuredPersonIndicatedCauseOfAccident || false
  )
  const watchRemovedByAmbulance = watch(
    'injuredPersonRemovedByAmbulance',
    person.injuredPersonRemovedByAmbulance || false
  )

  const savedSection = person.isTenant ? person.sectionId : null
  const savedBuilding = person.isTenant ? person.buildingId : null
  const savedApartment = person.isTenant ? person.apartmentId : null

  const [sectionFilterValue, setSectionFilterValue] = useState(person.sectionId)
  const [buildingFilterValue, setBuildingFilterValue] = useState(
    person.buildingId
  )
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(true, propertyFilterValue, savedSection)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(true, sectionFilterValue, savedBuilding)
  )
  const locationSelector = useSelector(
    selectLocationIdForApartmentWithBuildingFilter(buildingFilterValue)
  )
  const apartmentSelector = useSelector(
    selectApartmentsWithFilter(
      true,
      locationSelector.location ? locationSelector.location.id : null,
      savedApartment
    )
  )

  const watchIsTenant = watch('isTenant', person.isTenant || false)

  const setTenantInfo = (tenant) => {
    if (tenant) {
      setSectionFilterValue(tenant.sectionId)
      setBuildingFilterValue(tenant.buildingId)
      setValue('sectionId', tenant.sectionId)
      setValue('buildingId', tenant.buildingId)
      setValue('apartmentId', tenant.apartmentId)
      setValue('firstName', tenant.firstName)
      setValue('lastName', tenant.lastName)
      if (tenant.mi) setValue('mi', tenant.mi)
      if (tenant.address) setValue('address', tenant.address)
      if (tenant.city) setValue('city', tenant.city)
      if (tenant.stateId) setValue('stateId', tenant.stateId)
      if (tenant.zip) setValue('zip', tenant.zip)
      if (tenant.telephoneNumber)
        setValue('phoneNumber', tenant.telephoneNumber)
    }
  }

  const handleSaveInternally = (data) => {
    handleSave(data)
    setBuildingFilterValue(null)
  }

  useEffect(async () => {
    if (!loaded && (watchIsTenant || person.isTenant)) {
      setSectionFilterValue(person.sectionId)
      setBuildingFilterValue(person.buildingId)
      setLoaded(true)
    }
  }, [watchIsTenant])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>
        Person Involved
        <IconButton onClick={handleClose} className={classes.closeBtn}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={0} direction='row' alignItems='center'>
              <Grid item xs={3}>
                <FormSelect
                  name='personTypeId'
                  label='Person'
                  options={personInvolvedTypes}
                  required={true}
                  errorMessage={'Person field is required.'}
                  rules={{ required: true }}
                  defaultValue={person.personTypeId || null}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <FormCheckbox
                  name='isTenant'
                  label='Is Tenant?'
                  defaultValue={person.isTenant || false}
                />
              </Grid>
              <Grid item xs={4} />
              {watchIsTenant && (
                <>
                  <Grid item xs={3}>
                    <FormTenantSelect
                      name='tenantId'
                      label='Tenant'
                      defaultValue=''
                      options={tenantList}
                      onChangeHandler={setTenantInfo}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <FormLocationSelect
                      name='sectionId'
                      label='Section'
                      options={sectionsSelector.sections}
                      required={watchIsTenant}
                      errorMessage={'Section field is required.'}
                      rules={{ required: watchIsTenant }}
                      defaultValue={person.sectionId || ''}
                      onChangeHandler={setSectionFilterValue}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <FormLocationSelect
                      name='buildingId'
                      label='Building'
                      options={buildingsSelector.buildings}
                      required={watchIsTenant}
                      errorMessage={'Building field is required.'}
                      rules={{ required: watchIsTenant }}
                      defaultValue={person.buildingId || ''}
                      onChangeHandler={setBuildingFilterValue}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={2}>
                    <FormSelect
                      name='apartmentId'
                      label='Apartment'
                      options={apartmentSelector.apartments}
                      defaultValue={person.apartmentId || null}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={3}>
                <FormInput
                  name='firstName'
                  label={
                    watchPersonType == 3
                      ? 'First Name (include city agency)'
                      : 'First Name'
                  }
                  required={true}
                  errorMessage={'First Name field is required'}
                  rules={{ required: true }}
                  defaultValue={person.firstName || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormInput
                  name='lastName'
                  label='Last Name'
                  required={true}
                  errorMessage={'Last Name field is required'}
                  rules={{ required: true }}
                  defaultValue={person.lastName || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormInput
                  name='mi'
                  label='MI'
                  defaultValue={person.mi || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormDatePicker
                  name='dateOfBirth'
                  label='Date of Birth'
                  errorMessage='A valid date is required'
                  rules={{
                    validate: (value) => !value || !isNaN(Date.parse(value)),
                  }}
                  defaultValue={
                    person.dateOfBirth ? new Date(person.dateOfBirth) : null
                  }
                  showDay={false}
                />
              </Grid>
              <Grid item xs={3}>
                <FormInput
                  name='address'
                  label='Address'
                  defaultValue={person.address || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormInput
                  name='city'
                  label='City'
                  defaultValue={person.city || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormSelect
                  name='stateId'
                  label='State'
                  options={stateList}
                  defaultValue={person.stateId || 32}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormInput
                  name='zip'
                  label='Zip'
                  defaultValue={person.zip || ''}
                />
              </Grid>
              {watchPersonType === 1 && (
                <>
                  <Grid item xs={3}>
                    <FormSelect
                      name='sexId'
                      label='Sex'
                      options={[
                        { id: 1, name: 'Male' },
                        { id: 2, name: 'Female' },
                      ]}
                      defaultValue={person.sexId || null}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3}>
                    <FormInput
                      name='age'
                      label='Age'
                      defaultValue={person.age || null}
                    />
                  </Grid>
                  <Grid item xs={1} />
                </>
              )}
              <Grid item xs={3}>
                <FormMaskedInput
                  name='phoneNumber'
                  label='Phone Number'
                  mask={phoneNumberFormat.mask}
                  maskChar='_'
                  rules={{
                    validate: (value) => {
                      value = value.replace('_', '')
                      return (
                        value.length === 0 ||
                        value.length === phoneNumberFormat.length
                      )
                    },
                  }}
                  defaultValue={person.phoneNumber || ''}
                />
              </Grid>
              {watchPersonType === 1 && (
                <>
                  <Grid item xs={6}>
                    <FormCheckbox
                      name='injuredPersonAtScene'
                      label='Was the Injured Person at the Scene'
                      defaultValue={person.injuredPersonAtScene || false}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormCheckbox
                      name='injuredPersonIndicatedCauseOfAccident'
                      label='Injured Person Indicated Cause of Accident'
                      defaultValue={
                        person.injuredPersonIndicatedCauseOfAccident || false
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextArea
                      name='natureOfInjury'
                      label='Nature of Injury'
                      rows={5}
                      defaultValue={person.natureOfInjury || ''}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextArea
                      name='injuredPersonStatement'
                      label='Statement'
                      rows={5}
                      required={watchIndicatedCause}
                      errorMessage={
                        watchIndicatedCause ? 'Statement field is required' : ''
                      }
                      rules={{ required: watchIndicatedCause }}
                      defaultValue={person.injuredPersonStatement || ''}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <FormCheckbox
                      name='injuredPersonRemovedByAmbulance'
                      label='Removed by Ambulance'
                      defaultValue={
                        person.injuredPersonRemovedByAmbulance || false
                      }
                    />
                  </Grid>
                  {watchRemovedByAmbulance && (
                    <>
                      <Grid item xs={3}>
                        <FormInput
                          name='emsNumber'
                          label='EMS #'
                          defaultValue={person.emsNumber || ''}
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={3}>
                        <FormInput
                          name='hospitalName'
                          label='Hospital Name'
                          defaultValue={person.hospitalName || ''}
                        />
                      </Grid>
                      <Grid item xs={1} />
                    </>
                  )}
                </>
              )}
            </Grid>
            <DialogActions>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    startIcon={<RemoveCircleOutlineIcon />}
                    fullWidth={true}
                    onClick={() => {
                      handleRemove()
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={2}>
                  <Button
                    className={classes.buttonSave}
                    variant='contained'
                    startIcon={<AddIcon />}
                    fullWidth={true}
                    onClick={handleSubmit((data) => {
                      handleSaveInternally(data)
                    })}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

PeopleInvolvedDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  person: PropTypes.shape(),
  propertyFilterValue: PropTypes.number.isRequired,
  tenantList: PropTypes.array.isRequired,
  personInvolvedTypes: PropTypes.array,
  stateList: PropTypes.array,
}

export default PeopleInvolvedDialog

import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import SectionHeader from '../../../common/components/molecules/SectionHeader'

import FormSelect from '../atoms/FormSelect'
import FormInput from '../atoms/FormInput'
import FormTimePicker from '../atoms/FormTimePicker'
import FormTextArea from '../atoms/FormTextArea'

const CallerFields = ({
  callTypes,
  dispositionCodes,
  eventCondition,
  dispositionChangeFunction,
}) => {
  const eventComplete = eventCondition === 2
  return (
    <>
      <SectionHeader sectionName='Details' />
      <Grid
        container
        spacing={0}
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          width: '95%',
        }}
      >
        <Grid item xs={4}>
          <FormSelect
            name='event.responseDetails.callTypeId'
            label='Type of Call'
            options={callTypes}
            required={true}
            errorMessage={'Type of Call field is required.'}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <FormInput
            name='event.responseDetails.respondingOfficer'
            label='Responding Officer(s)'
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          <FormSelect
            name='event.responseDetails.dispositionId'
            label='Disposition'
            options={dispositionCodes}
            required={eventComplete}
            errorMessage={eventComplete ? 'Disposition field is required' : ''}
            rules={{ required: eventComplete }}
            onChangeFunction={dispositionChangeFunction}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.responseDetails.dispositionTime'
            label='Disposition Time'
            errorMessage='A valid time is required.'
            required={eventComplete}
            rules={{
              required: eventComplete,
              validate: (value) => !value || !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={5} />
        <Grid item xs={12}>
          <FormTextArea
            name='event.responseDetails.notes'
            label='Notes'
            rows={10}
          />
        </Grid>
      </Grid>
    </>
  )
}

CallerFields.propTypes = {
  callTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  dispositionCodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  eventCondition: PropTypes.number.isRequired,
  dispositionChangeFunction: PropTypes.func.isRequired,
}

export default CallerFields

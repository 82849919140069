import React from 'react'
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const FormSectionMultiSelect = ({
  label,
  incidentTypes = [],
  updateIncidentTypes,
  selectedSections,
}) => {
  // const [selectedSections, setSections] = React.useState([])

  const handleChange = (event) => {
    const selected = event.target.value
    // setSections(selected)
    updateIncidentTypes(selected)
  }
  return (
    <>
      <FormControl fullWidth={true}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple
          value={selectedSections}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected.join(', ')}
        >
          {incidentTypes.map((type) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={selectedSections.indexOf(type) > -1} />
              <ListItemText primary={type} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

FormSectionMultiSelect.propTypes = {
  label: PropTypes.string.isRequired,
  incidentTypes: PropTypes.array,
  updateIncidentTypes: PropTypes.func,
}

export default FormSectionMultiSelect

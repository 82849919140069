import { handleResponse, handleError } from './apiUtils'
const baseUrl = process.env.API_URL + '/options/callTypes'

export function getCallTypes() {
  return fetch(baseUrl, {
    method: 'GET',
    headers: {
      Authorization:
        'Bearer ' + JSON.parse(sessionStorage.getItem('CHIEFToken')).token,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
    .then(handleResponse)
    .catch(handleError)
}

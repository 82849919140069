import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import FormInput from '../atoms/FormInput'
import FormEventTypeSelect from '../atoms/FormEventTypeSelect'
import FormSelect from '../atoms/FormSelect'
import FormDatePicker from '../atoms/FormDatePicker'
import FormTimePicker from '../atoms/FormTimePicker'

const CallLogEventDetailsFields = ({
  defaultValues,
  eventTypes,
  eventDispatchedStatus,
  eventConditions,
  eventPlatoons,
  timeChangeHandler,
  eventTypeChangeHandler,
  disableEventConditionField = false,
}) => {
  return (
    <>
      <Grid
        container
        spacing={0}
        direction='row'
        alignItems='center'
        style={{
          marginLeft: 'auto',
          marginRight: 'auto',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          width: '95%',
        }}
      >
        <Grid item xs={2}>
          <FormEventTypeSelect
            name='event.type'
            label='Event Type'
            options={eventTypes}
            required={true}
            errorMessage={'Event Type field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.type || ''}
            changeHandler={eventTypeChangeHandler}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.preparedBy'
            label='Prepared By'
            required={true}
            errorMessage='Prepared By field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.preparedBy || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.dispatchedStatusId'
            label='Proactive / Dispatched'
            options={eventDispatchedStatus}
            required={true}
            errorMessage={'Proactive / Dispatched field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.dispatchedStatus || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.conditionId'
            label='Event Condition'
            options={eventConditions}
            required={true}
            errorMessage={'Event Condition field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.condition || ''}
            disabled={disableEventConditionField}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormDatePicker
            name='event.date'
            label='Call Date'
            required={true}
            errorMessage='A valid date is required'
            showDay={true}
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={defaultValues.date || new Date()}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeResponded'
            label='Time Responded'
            timeChangeHandler={timeChangeHandler}
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={defaultValues.timeResponded || new Date()}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.platoonId'
            label='Platoon'
            options={eventPlatoons}
            required={true}
            errorMessage={'Platoon field is required'}
            rules={{ required: true }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.dispatcher'
            label='Dispatcher'
            required={true}
            errorMessage='Dispatcher field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.dispatcher || storedValue('lastDispatcher','')}
          />
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  )
}

const storedValue = (key,defaultValue) =>{
  var data = sessionStorage.getItem(key);
  if (!data) {
      return defaultValue;
    }
  return JSON.parse(data);
}

CallLogEventDetailsFields.propTypes = {
  defaultValues: PropTypes.shape({
    type: PropTypes.number,
    preparedBy: PropTypes.string,
    dispatchedStatus: PropTypes.number,
    condition: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    timeResponded: PropTypes.instanceOf(Date),
    platoon: PropTypes.number,
    dispatcher: PropTypes.string,
  }),
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  eventDispatchedStatus: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  eventConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  eventPlatoons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      //TODO: what type are these going to be lol?
      startTime: PropTypes.any,
      endTime: PropTypes.any,
    })
  ),
  timeChangeHandler: PropTypes.func,
  eventTypeChangeHandler: PropTypes.func.isRequired,
  disableEventConditionField: PropTypes.bool,
}

export default CallLogEventDetailsFields

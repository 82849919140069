import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as floorApi from '../../apiCalls/floor'

const initialState = {
  status: apiStatus.idle,
  error: null,
  floors: [],
}

const floorSlice = createSlice({
  name: 'floors',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadFloorsSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, floors: payload }
    },
    updateFloorSuccess(state, { payload }) {
      let updatedFloors = state.floors.map((floor) => {
        if (floor.id != payload.id) return floor
        return {
          ...floor,
          ...payload,
        }
      })

      return {
        ...state,
        floors: updatedFloors,
        status: apiStatus.succeeded,
      }
    },
    addFloorSuccess(state, { payload }) {
      let updatedFloors = [...state.floors, payload]
      return { ...state, floors: updatedFloors, status: apiStatus.succeeded }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadFloorsSuccess,
  updateFloorSuccess,
  addFloorSuccess,
} = floorSlice.actions

export const loadFloorsAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await floorApi
    .getFloors()
    .then((floors) => {
      dispatch(loadFloorsSuccess(floors))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const updateFloorAsync = (floor) => async (dispatch) => {
  dispatch(beginApiCall())
  return await floorApi
    .updateFloor(floor)
    .then(() => dispatch(updateFloorSuccess(floor)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const addFloorAsync = (floor) => async (dispatch) => {
  dispatch(beginApiCall())
  return await floorApi
    .addFloor(floor)
    .then((floorId) => dispatch(addFloorSuccess({ id: floorId, ...floor })))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const selectFloors = (state) => state.floors

export const selectFloorsWithFilter = (
  active,
  locationId,
  selectedFloorId = null
) => (state) => {
  var floorsSelector = selectFloors(state)
  return {
    ...floorsSelector,
    floors: floorsSelector.floors
      .filter(
        (f) =>
          (f.isActive == active && f.locationId == locationId) ||
          (f.id == selectedFloorId && f.locationId == locationId)
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default floorSlice.reducer

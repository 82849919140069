export function handleResponse(response) {
  if (response.ok) return response.json()
  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const error = response.text()
    throw new Error(error)
  }
  if (response === 401) {
    //TODO: Silent renew AD Token, renew CHIEF Token or get new one where necessary
    const error = response.text()
    throw new Error(error)
  }
  throw new Error('Network response was not ok.')
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error('API call failed. ' + error)
  throw error
}

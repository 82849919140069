import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Button,
  DialogContent,
  CircularProgress,
  makeStyles,
  Grid,
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { green } from '@material-ui/core/colors'
import { useSelector } from 'react-redux'
import { selectPrimaryThemeColor } from '../../slices/themeColorSlice'

const AddLocationsDialog = ({
  open,
  title,
  handleClose,
  save,
  loading,
  namesForValidation = [],
}) => {
  const useStyles = makeStyles((theme) => ({
    wrapper: {
      float: 'right',
      margin: theme.spacing(1),
      position: 'relative',
    },
    wrapperCancel: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    button: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }))

  const [newLocation, setLocation] = useState({ name: '', isActive: true })
  const [errorMessage, setErrorMessage] = useState(' ')
  const classes = useStyles()

  const handleChange = (event) => {
    const { name, value } = event.target
    setLocation((prevLocation) => ({ ...prevLocation, [name]: value }))
    if (namesForValidation.includes(value)) {
      setErrorMessage('Already exists')
    } else {
      setErrorMessage(' ')
    }
  }

  const handleSubmit = () => {
    if (errorMessage == ' ') {
      setLocation({ name: '', isActive: true })
      save(newLocation)
    }
  }

  const handleOnClose = () => {
    setLocation({ name: '', isActive: true })
    setErrorMessage(' ')
    handleClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleOnClose}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          id='name'
          name='name'
          label='Name'
          value={newLocation.name}
          onChange={handleChange}
          onto
          error={errorMessage != ' '}
          helperText={errorMessage}
        />
      </DialogContent>
      <DialogActions>
        <Grid container justify='space-between'>
          <Grid item xs={2}>
            <div className={classes.wrapperCancel}>
              <Button
                variant='contained'
                fullWidth={true}
                onClick={handleOnClose}
              >
                CANCEL
              </Button>
            </div>
          </Grid>
          <Grid item xs={2}>
            <div className={classes.wrapper}>
              <Button
                className={classes.button}
                variant='contained'
                startIcon={<SaveIcon />}
                fullWidth={true}
                disabled={loading || errorMessage != ' '}
                onClick={handleSubmit}
              >
                ADD
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

AddLocationsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  namesForValidation: PropTypes.array,
}

export default AddLocationsDialog

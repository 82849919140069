import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
// Component imports
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkedReports from '../molecules/LinkedReports'
import AccidentEventDetailsFields from '../molecules/AccidentEventDetailsFields'
import PlaceOfOccurrenceFields from '../molecules/PlaceOfOccurrenceFields'
import PeopleInvolvedFields from '../molecules/PeopleInvolvedFields'
import ApprovalFields from '../molecules/AccidentApprovalFields'
import FormDetails from '../molecules/FormDetails'
import EventFormButtons from '../molecules/EventFormButtons'
// Store imports
import { useDispatch, useSelector } from 'react-redux'
import {
  loadPropertiesAsync,
  selectActiveUserProperties,
} from '../../../admin/slices/propertySlice'
import {
  loadEventConditionsAsync,
  selectFilteredEventConditions,
} from '../../slices/eventConditionsSlice'
import {
  selectSectionsWithFilter,
  loadSectionsAsync,
} from '../../../admin/slices/sectionSlice'
import {
  selectBuildingsWithFilter,
  loadBuildingsAsync,
  selectBuildings,
} from '../../../admin/slices/buildingSlice'
import {
  selectLocationsWithFilter,
  loadLocationsAsync,
} from '../../../admin/slices/locationSlice'
import {
  selectApartmentsWithFilter,
  loadApartmentsAsync,
  selectApartments,
} from '../../../admin/slices/apartmentSlice'
import {
  selectFloorsWithFilter,
  loadFloorsAsync,
} from '../../../admin/slices/floorSlice'
import {
  selectStoresWithFilter,
  loadStoresAsync,
} from '../../../admin/slices/storeSlice'
import {
  selectParkingSpotsWithFilter,
  loadParkingSpotsAsync,
} from '../../../admin/slices/parkingSpotSlice'
// Constants
import {
  eventTypes,
  personInvolvedTypes,
  roles,
  spinnerSize,
} from '../../../common/constants'
import {
  apiStatusEnum as apiStatus,
  eventTypeEnum,
} from '../../../common/enums'
import ContributingCircumstancesFields from '../molecules/ContributingCircumstanceFields'
import PeopleInvolvedDialog from '../molecules/PeopleInvolvedDialog'
import AccidentDetailsFields from '../molecules/AccidentDetailsField'
import * as accidentApi from '../../../apiCalls/accident'
import { useHistory, withRouter } from 'react-router-dom'
import {
  selectControlNumbers,
  loadControlNumbersAsync,
} from '../../slices/incidentControlNumberSlice'
import * as attachmentApi from '../../../apiCalls/attachment'
import DeleteAttachmentAlert from '../molecules/DeleteAttachmentAlert'
import {
  selectCurrentUserDisplayName,
  selectCurrentUserProperties,
  selectCurrentUserRole,
} from '../../../admin/slices/currentUserSlice'
import { getIncidentById } from '../../../apiCalls/incident'
import { Typography } from '@material-ui/core'
import ErrorAlertDialog from '../molecules/ErrorAlertDialog'
import { loadStatesAsync, selectStates } from '../../slices/stateCodesSlice'
import {
  loadTenantsAsync,
  selectTenantsByPropertyId,
} from '../../../admin/slices/tenantSlice'
import PageHeader from '../../../common/components/organisms/PageHeader'
import FormValidationMessage from '../molecules/FormValidationMessage'

const accidentTypeList = ['Injured Persons', 'Damaged Property']

const AccidentEvent = ({
  eventTypeChange,
  accidentEvent = null,
  incident = null,
  setRecordEventLoaded,
}) => {
  const [header, setHeader] = useState('Record New Accident')
  const currentUserName = useSelector(selectCurrentUserDisplayName())
  const currentUserActiveProperties = useSelector(
    selectCurrentUserProperties()
  ).filter((p) => p.isActive)
  const isGeneralUser =
    useSelector(selectCurrentUserRole()) === roles.generalUser
  const defaultAccident = {
    event: {
      type: 4,
      preparedBy: currentUserName,
      eventConditionId: 1,
      place: {
        propertyId: currentUserActiveProperties.length
          ? currentUserActiveProperties[0].id
          : null,
      },
    },
  }
  let currentAccident = {}
  if (accidentEvent !== null) currentAccident = accidentEvent
  else currentAccident = defaultAccident

  if (accidentEvent === null && incident !== null) {
    currentAccident.event = {
      ...currentAccident.event,
      callLogControlNumber: incident.event.callLogControlNumber,
      incidentControlNumber: incident.event.incidentControlNumber,
      dateReported: incident.event.dateReported,
      timeReported: incident.event.timeReported,
      dateOfOccurrence: incident.event.dateOfIncident,
      timeOfOccurrence: incident.event.timeOfIncident,
      place: {
        propertyId: incident.event.location.propertyId,
        sectionId: incident.event.location.sectionId,
        buildingId: incident.event.location.buildingId,
        locationId: incident.event.location.locationId,
        floorId: incident.event.location.floorId,
        apartmentId: incident.event.location.apartmentId,
        storeId: incident.event.location.storeId,
        parkingSpotId: incident.event.location.parkingSpotId,
      },
    }
  }

  const showDownload = accidentEvent != null

  let eventTypeList = {}
  if (isGeneralUser) eventTypeList = eventTypes.filter((t) => t.id != 3)
  else eventTypeList = eventTypes

  const methods = useForm({ defaultValues: currentAccident })
  const { handleSubmit, setValue, watch, formState } = methods

  const dispatch = useDispatch()

  const [dataLoaded, setDataLoaded] = useState(false)
  const loading = () => setDataLoaded(false)
  const loaded = () => setDataLoaded(true)

  const [accidentTypes, setAccidentTypes] = useState({
    injuredPersons: false,
    damagedProperty: false,
  })
  const updateAccidentTypes = (l = []) => {
    const current = {
      injuredPersons: false,
      damagedProperty: false,
    }
    l.map((t) => {
      switch (t) {
        case 'Injured Persons':
          current.injuredPersons = true
          break
        case 'Damaged Property':
          current.damagedProperty = true
      }
    })
    setAccidentTypes(current)
  }

  const incidentTypeList = ['Injured Persons', 'Damaged Property']
  const [incidentTypes, setIncidentTypes] = useState({
    injuredPersons: false,
    damagedProperty: false,
    string: '',
  })
  const updateIncidentTypes = async (l = []) => {
    const current = {
      injuredPersons: false,
      damagedProperty: false,
      string: '',
    }
    l.map((t) => {
      switch (t) {
        case 'Injured Persons':
          current.injuredPersons = true
          break
        case 'Damaged Property':
          current.damagedProperty = true
          break
      }
    })
    current.string = l.join(',')
    setIncidentTypes(current)
  }

  const resetForm = () => {
    setRecordEventLoaded(false)
    if (currentAccident.event.accidentId != undefined) {
      history.push(
        '/event/' +
          eventTypeEnum.accident +
          '/' +
          currentAccident.event.accidentId
      )
    } else {
      history.push('/recordEvent/' + eventTypeEnum.accident)
    }
  }

  const watchSupervisorSigned = watch(
    'event.accidentApproval.approverSignature'
  )

  const supervisorSignature = () => {
    return watchSupervisorSigned
      ? watchSupervisorSigned.signature.length > 0 &&
          watchSupervisorSigned.date.length > 0
      : false
  }

  const eventConditions = useSelector(
    selectFilteredEventConditions(supervisorSignature())
  )

  const savedProperty = accidentEvent
    ? accidentEvent.event.place.propertyId
    : null
  const savedSection = accidentEvent
    ? accidentEvent.event.place.sectionId
    : null
  const savedBuilding = accidentEvent
    ? accidentEvent.event.place.buildingId
    : null
  const savedLocation = accidentEvent
    ? accidentEvent.event.place.locationId
    : null
  const savedApartment = accidentEvent
    ? accidentEvent.event.place.apartmentId
    : null
  const savedFloor = accidentEvent ? accidentEvent.event.place.floorId : null
  const savedStore = accidentEvent ? accidentEvent.event.place.storeId : null
  const savedParkingSpot = accidentEvent
    ? accidentEvent.event.place.parkingSpotId
    : null

  const [propertyFilterValue, setPropertyFilterValue] = useState(null)
  const [sectionFilterValue, setSectionFilterValue] = useState(null)
  const clearSectionFilter = () => setSectionFilterValue(null)
  const [buildingFilterValue, setBuildingFilterValue] = useState(null)
  const clearBuildingFilter = () => setBuildingFilterValue(null)
  const [locationFilterValue, setLocationFilterValue] = useState(null)
  const clearLocationFilter = () => setLocationFilterValue(null)
  const [locationType, setLocationType] = useState('')
  const propertySelector = useSelector(
    selectActiveUserProperties(currentUserActiveProperties, savedProperty)
  )
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(true, propertyFilterValue, savedSection)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(true, sectionFilterValue, savedBuilding)
  )
  const allBuildingsSelector = useSelector(selectBuildings)
  const locationsSelector = useSelector(
    selectLocationsWithFilter(true, buildingFilterValue, savedLocation)
  )
  const apartmentsSelector = useSelector(
    selectApartmentsWithFilter(true, locationFilterValue, savedApartment)
  )
  const allApartmentsSelector = useSelector(selectApartments)
  const floorSelector = useSelector(
    selectFloorsWithFilter(true, locationFilterValue, savedFloor)
  )
  const storesSelector = useSelector(
    selectStoresWithFilter(true, locationFilterValue, savedStore)
  )
  const parkingSpotsSelector = useSelector(
    selectParkingSpotsWithFilter(true, locationFilterValue, savedParkingSpot)
  )

  const [sectionDisabled, setSectionDisabled] = useState(true)
  const [buildingDisabled, setBuildingDisabled] = useState(true)
  const [locationDisabled, setLocationDisabled] = useState(true)

  const resetSelect = (fieldName) => setValue(fieldName, '')

  const setPropertyAsFilter = (item) => {
    setPropertyFilterValue(item)
    resetSelect('event.place.sectionId')
    resetSelect('event.place.buildingId')
    resetSelect('event.place.locationId')
    clearSectionFilter()
    clearBuildingFilter()
    clearLocationFilter()
    setSectionDisabled(false)
    setBuildingDisabled(true)
    setLocationDisabled(true)
    setLocationType('')
  }

  const selectSectionAsFilter = (item) => {
    setSectionFilterValue(item)
    resetSelect('event.place.buildingId')
    resetSelect('event.place.locationId')
    clearBuildingFilter()
    clearLocationFilter()
    setBuildingDisabled(false)
    setLocationDisabled(true)
    setLocationType('')
  }
  const selectBuildingAsFilter = (item) => {
    setBuildingFilterValue(item)
    resetSelect('event.place.locationId')
    clearLocationFilter()
    setLocationDisabled(false)
    setLocationType('')
  }
  const selectLocationAsFilter = (item) => {
    setLocationFilterValue(item)
    setLocationType(locationsSelector.locations.find((e) => e.id === item).name)
  }

  const [peopleInvolvedList, setPeopleInvolvedList] = useState([])
  const [peopleInvolvedDialog, setPeopleInvolvedDialog] = useState({
    open: false,
    index: -1,
    person: {},
  })
  const closePeopleInvolvedDialog = () =>
    setPeopleInvolvedDialog({ open: false, index: -1, person: {} })
  const openPeopleInvolvedDialog = (i = -1, p = {}) =>
    setPeopleInvolvedDialog({ open: true, index: i, person: p })
  const updatePeopleInvolvedList = (list) => {
    let listUpdated = list.map((data) => {
      var personType = personInvolvedTypes.find(
        (p) => p.id === data.personTypeId
      ).name

      var building = ''
      if (
        data.buildingId !== undefined &&
        data.buildingId !== null &&
        allBuildingsSelector.buildings.find((b) => b.id === data.buildingId) !==
          undefined
      ) {
        building = allBuildingsSelector.buildings.find(
          (b) => b.id === data.buildingId
        ).name
      }

      var apartment = ''
      if (
        data.apartmentId !== undefined &&
        data.apartmentId !== null &&
        allApartmentsSelector.apartments.find(
          (a) => a.id == data.apartmentId
        ) !== undefined
      ) {
        apartment = allApartmentsSelector.apartments.find(
          (a) => a.id == data.apartmentId
        ).name
      }

      return {
        ...data,
        personType: personType,
        building: building,
        apartment: apartment,
      }
    })
    setPeopleInvolvedList(listUpdated)
    setValue('peopleInvolved.list', listUpdated)
  }

  const handleOpenPeopleInvolvedDialog = (i = -1, p = {}) => {
    openPeopleInvolvedDialog(i, p)
  }
  const handleClosePeopleInvolvedDialog = () => {
    closePeopleInvolvedDialog()
  }
  const handleRemovePeopleInvolvedDialog = () => {
    if (peopleInvolvedDialog.index !== -1) {
      let updatedList = peopleInvolvedList
      updatedList.splice(PeopleInvolvedDialog.index, 1)
      updatePeopleInvolvedList(updatedList)
    }
    closePeopleInvolvedDialog()
  }
  const handleSavePeopleInvolvedDialog = (data) => {
    let updatedList = peopleInvolvedList
    if (peopleInvolvedDialog.index === -1) {
      updatedList.push(data)
    } else {
      updatedList[peopleInvolvedDialog.index] = data
    }
    updatePeopleInvolvedList(updatedList)
    closePeopleInvolvedDialog()
  }

  const [firstLoadOfExistingCallLog, setFirstLoadOfExistingCallLog] =
    useState(false)
  const [secondLoadOfExistingCallLog, setSecondLoadOfExistingCallLog] =
    useState(false)

  const incidentControlNumbers = useSelector(selectControlNumbers)
  const [callLogControlNumber, setCallLogControlNumber] = useState('')

  const onIncidentControlNumberChange = (controlNumber) => {
    getIncidentById(controlNumber.incidentId).then((incident) => {
      setCallLogControlNumber(incident.callLogControlNumber)
      setValue('event.callLogControlNumber', incident.callLogControlNumber)
      setValue('event.incidentControlNumber', incident.incidentControlNumber)
      setValue('event.dateReported', incident.dateReported)
      setValue('event.timeReported', incident.timeReported)
      setValue('event.dateOfOccurrence', incident.dateOfIncident)
      setValue('event.timeOfOccurrence', incident.timeOfIncident)
    })
  }

  // attachments
  const [attachments, setAttachments] = useState([])
  const [attachmentsIsLoading, setAttachmentsIsLoading] = useState(false)
  const [deleteAttachmentAlert, setDeleteAttachmentAlert] = useState({
    open: false,
  })

  const [showAttachmentUploadError, setShowAttachmentUploadError] =
    useState(false)
  const [attachmentUploadError, setAttachmentUploadError] = useState({
    title: '',
    content: '',
  })

  const closeAttachmentErrorAlert = () => {
    setShowAttachmentUploadError(false)
    setAttachmentUploadError({ title: '', content: '' })
  }

  const handleAddAttachments = async (e) => {
    let attachmentsToAdd = e.target.files
    let updatedAttachments = attachments

    for (var i = 0; i < attachmentsToAdd.length; i++) {
      await attachmentApi
        .uploadFile(attachmentsToAdd[i])
        .then((attachment) => updatedAttachments.push(attachment))
        .catch((error) => {
          console.log(error)
          setShowAttachmentUploadError(true)
          setAttachmentUploadError({
            title: 'Error encountered while uploading an attachment.',
            content: error.name + ': ' + error.message,
          })
        })
    }

    setAttachments(updatedAttachments)
    setValue('attachments.list', updatedAttachments)
    setAttachmentsIsLoading(false)
  }

  const handleDeleteAttachment = async (attachmentId) => {
    setAttachmentsIsLoading(true)
    let attachment = attachments.find((a) => a.attachmentId == attachmentId)
    setDeleteAttachmentAlert({ open: true, attachment: attachment })
  }

  const closeDeleteAttachmentAlert = async () => {
    setDeleteAttachmentAlert({ open: false })
  }

  const handleCancelDeleteAttachmentAlert = async () => {
    closeDeleteAttachmentAlert()
    setAttachmentsIsLoading(false)
  }

  const handleConfirmDeleteAttachmentAlert = async (attachmentId) => {
    let updatedAttachments = attachments.filter(
      (a) => a.attachmentId != attachmentId
    )
    setAttachments(updatedAttachments)
    setValue('attachments.list', updatedAttachments)
    setDeleteAttachmentAlert({ open: false, attachment: {} })
    setAttachmentsIsLoading(false)
  }

  const handleDownloadAttachment = async (attachmentId) => {
    await attachmentApi.downloadAttachment(
      attachmentId,
      attachments.find((a) => a.attachmentId == attachmentId).name
    )
  }

  const statesSelector = useSelector(selectStates)

  //tenants
  const tenantSelector = useSelector(
    selectTenantsByPropertyId(propertyFilterValue)
  )

  useEffect(async () => {
    if (
      currentAccident.event.accidentId != undefined &&
      !firstLoadOfExistingCallLog
    ) {
      if (currentAccident.event.incidentTypes != undefined)
        updateIncidentTypes(currentAccident.event.incidentTypes.split(','))
      setHeader('Accident: ' + currentAccident.event.accidentControlNumber)
      setAttachments(currentAccident.event.accidentDetail.attachments)
      setCallLogControlNumber(currentAccident.event.callLogControlNumber)
      setPropertyAsFilter(currentAccident.event.place.propertyId)
      selectSectionAsFilter(currentAccident.event.place.sectionId)
      selectBuildingAsFilter(currentAccident.event.place.buildingId)
      selectLocationAsFilter(currentAccident.event.place.locationId)
      setFirstLoadOfExistingCallLog(true)
    }
    if (
      currentAccident.event.incidentId != undefined &&
      locationsSelector.status === apiStatus.succeeded &&
      buildingsSelector.status === apiStatus.succeeded &&
      apartmentsSelector.status === apiStatus.succeeded &&
      firstLoadOfExistingCallLog &&
      !secondLoadOfExistingCallLog
    ) {
      selectLocationAsFilter(currentAccident.event.place.locationId)
      setSecondLoadOfExistingCallLog(true)
    }
    if (
      currentAccident.event.involvedPeople &&
      locationsSelector.status === apiStatus.succeeded &&
      buildingsSelector.status === apiStatus.succeeded &&
      apartmentsSelector.status === apiStatus.succeeded
    ) {
      updatePeopleInvolvedList(currentAccident.event.involvedPeople)
    }

    if (
      currentAccident.event.accidentId === undefined &&
      currentAccident.event.callLogControlNumber !== undefined
    )
      setCallLogControlNumber(currentAccident.event.callLogControlNumber)

    if (incidentControlNumbers.status != apiStatus.pending)
      dispatch(loadControlNumbersAsync())
    if (propertySelector.status === apiStatus.idle) {
      dispatch(loadPropertiesAsync())
    }
    if (statesSelector.length == 0) {
      dispatch(loadStatesAsync())
    }
    if (sectionsSelector.status === apiStatus.idle) {
      dispatch(loadSectionsAsync())
    }
    if (buildingsSelector.status === apiStatus.idle) {
      dispatch(loadBuildingsAsync())
    }
    if (locationsSelector.status === apiStatus.idle) {
      dispatch(loadLocationsAsync())
    }
    if (floorSelector.status === apiStatus.idle) {
      dispatch(loadFloorsAsync())
    }
    if (apartmentsSelector.status === apiStatus.idle) {
      dispatch(loadApartmentsAsync())
    }
    if (storesSelector.status == apiStatus.idle) {
      dispatch(loadStoresAsync())
    }
    if (parkingSpotsSelector.status === apiStatus.idle) {
      dispatch(loadParkingSpotsAsync())
    }
    if (tenantSelector.status == apiStatus.idle) {
      dispatch(loadTenantsAsync())
    }
    if (eventConditions.length === 0) {
      dispatch(loadEventConditionsAsync())
    } else {
      loaded()
    }

    if (
      propertySelector.status === apiStatus.succeeded &&
      sectionsSelector.status === apiStatus.succeeded &&
      buildingsSelector.status === apiStatus.succeeded &&
      locationsSelector.status === apiStatus.succeeded
    ) {
      if (incident !== null) {
        if (propertyFilterValue == null) {
          setPropertyAsFilter(incident.event.location.propertyId)
          setValue('event.place.propertyId', incident.event.location.propertyId)
        }
        if (propertyFilterValue !== null && sectionFilterValue == null) {
          selectSectionAsFilter(incident.event.location.sectionId)
          setValue('event.place.sectionId', incident.event.location.sectionId)
        }
        if (sectionFilterValue !== null && buildingFilterValue == null) {
          selectBuildingAsFilter(incident.event.location.buildingId)
          setValue('event.place.buildingId', incident.event.location.buildingId)
        }
        if (buildingFilterValue !== null && locationFilterValue == null) {
          selectLocationAsFilter(incident.event.location.locationId)
          setValue('event.place.locationId', incident.event.location.locationId)
        }
        if (
          currentAccident.event.accidentId === undefined &&
          currentAccident.event.place.propertyId !== undefined &&
          propertyFilterValue === null
        ) {
          setPropertyAsFilter(currentAccident.event.place.propertyId)
        }
      }
    }
  }, [
    dispatch,
    firstLoadOfExistingCallLog,
    secondLoadOfExistingCallLog,
    tenantSelector.status,
    propertySelector.status,
    sectionsSelector.status,
    buildingsSelector.status,
    apartmentsSelector.status,
    locationsSelector.status,
    eventConditions.length,
    statesSelector.length,
    propertyFilterValue,
    sectionFilterValue,
    buildingFilterValue,
    locationFilterValue,
  ])

  const watchEventType = watch('event.type')
  const history = useHistory()

  const canEdit = () => {
    if (!isGeneralUser) return true
    if (isGeneralUser && defaultAccident.event.conditionId != 2) return true
    return false
  }

  const [showSaveError, setShowSaveError] = useState(false)
  const [saveError, setSaveError] = useState({
    title: '',
    content: '',
  })

  const closeSaveErrorAlert = () => {
    setShowSaveError(false)
    setSaveError({
      title: '',
      content: '',
    })
    loaded()
  }

  const { isValid } = formState
  const saveForm = async () => {
    if (!isValid) {
      setOpenValidationMessage(true)
    }
  }

  const [openValidationMessage, setOpenValidationMessage] = useState(false)
  const handelCloseValidationMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationMessage(false)
  }

  return (
    <>
      <PageHeader pageName={header} />
      {dataLoaded ? (
        <>
          {canEdit() ? (
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(async (data) => {
                  setOpenValidationMessage(false)
                  loading()
                  data.event.incidentTypes = incidentTypes.string
                  data.event.involvedPeople = peopleInvolvedList
                  data.event.callLogControlNumber = callLogControlNumber
                  data.event.accidentDetail.attachments = attachments
                  if (currentAccident.event.accidentId != undefined) {
                    accidentApi
                      .updateAccident(data, currentAccident.event.accidentId)
                      .then((accidentId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' + eventTypeEnum.accident + '/' + accidentId
                        )
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  } else {
                    loading()
                    accidentApi
                      .addAccident(data)
                      .then((accidentId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' + eventTypeEnum.accident + '/' + accidentId
                        )
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  }
                })}
              >
                {currentAccident.event.controlNumberReferenceTree && (
                  <LinkedReports
                    controlNumberReferenceTree={
                      currentAccident.event.controlNumberReferenceTree
                    }
                  />
                )}
                {propertySelector.status === apiStatus.succeeded &&
                  propertySelector.properties.length == 0 && (
                    <Typography color='secondary' align='center' variant='h5'>
                      Please note: You currently do not have any properties
                      assigned to you. You can contact a CHIEF system admin for
                      your property access to be updated.
                    </Typography>
                  )}
                <AccidentEventDetailsFields
                  defaultValues={{
                    ...currentAccident.event,
                    dateReported: new Date(currentAccident.event.dateReported),
                    timeReported: new Date(currentAccident.event.timeReported),
                    dateOfOccurrence: new Date(
                      currentAccident.event.dateOfOccurrence
                    ),
                    timeOfOccurrence: new Date(
                      currentAccident.event.timeOfOccurrence
                    ),
                    timeJobReceived: new Date(
                      currentAccident.event.timeJobReceived
                    ),
                  }}
                  eventTypes={eventTypeList}
                  eventTypeChangeHandler={eventTypeChange}
                  eventConditions={eventConditions}
                  accidentTypes={accidentTypeList}
                  setAccidentTypes={updateAccidentTypes}
                  callControlNumbers={[]}
                  callControlNumber={callLogControlNumber}
                  callIncidentNumbers={
                    currentAccident.event.accidentId
                      ? [
                          ...incidentControlNumbers.controlNumbers,
                          {
                            id: currentAccident.event.incidentControlNumber,
                            name: currentAccident.event.incidentControlNumber,
                          },
                        ]
                      : incidentControlNumbers.controlNumbers
                  }
                  onIncidentControlNumberChange={onIncidentControlNumberChange}
                  updateIncidentTypes={updateIncidentTypes}
                  selectedIncidentTypes={incidentTypes.string}
                  incidentTypes={incidentTypeList}
                  disableEventConditionField={isGeneralUser}
                />
                <PlaceOfOccurrenceFields
                  defaultValues={currentAccident.place}
                  placesOfOccurrence={[
                    { id: 1, name: 'Roadway' },
                    { id: 2, name: 'Sidewalk' },
                    { id: 3, name: 'Inside Building' },
                    { id: 4, name: 'Other' },
                    { id: 5, name: 'Exact Location Unknown' },
                  ]}
                  properties={propertySelector.properties}
                  propertyChangeHandler={setPropertyAsFilter}
                  sections={sectionsSelector.sections}
                  sectionChangeHandler={selectSectionAsFilter}
                  sectionDisabled={sectionDisabled}
                  buildings={buildingsSelector.buildings}
                  buildingChangeHandler={selectBuildingAsFilter}
                  buildingDisabled={buildingDisabled}
                  locations={locationsSelector.locations}
                  locationChangeHandler={selectLocationAsFilter}
                  locationDisabled={locationDisabled}
                  locationType={locationType}
                  apartments={apartmentsSelector.apartments}
                  floors={floorSelector.floors}
                  stores={storesSelector.stores}
                  parkingSpots={parkingSpotsSelector.parkingSpots}
                  otherPlacesOfOccurrence={[
                    { id: 1, name: 'Roadway' },
                    { id: 2, name: 'Sidewalk' },
                    { id: 3, name: 'Inside Building' },
                    { id: 4, name: 'Other (specify)' },
                    { id: 5, name: 'Exact Location Unknown' },
                  ]}
                  disablePropertyChange={peopleInvolvedList.length > 0}
                  propertyHelperText={
                    peopleInvolvedList.length > 0
                      ? 'People Involved must be removed to enable this field for edit.'
                      : null
                  }
                />
                <ContributingCircumstancesFields
                  defaultValues={currentAccident.circumstances}
                  surfaceConditions={[
                    { id: 1, name: 'Dry' },
                    { id: 2, name: 'Wet' },
                    { id: 3, name: 'Muddy' },
                    { id: 4, name: 'Ice' },
                    { id: 5, name: 'Snow' },
                    { id: 6, name: 'Other (specify)' },
                  ]}
                  defects={[
                    { id: 1, name: 'Dry' },
                    { id: 2, name: 'None' },
                    { id: 3, name: 'Hole' },
                    { id: 4, name: 'Obstruction' },
                    { id: 5, name: 'Depressed' },
                    { id: 6, name: 'Raised' },
                    { id: 7, name: 'Other (specify)' },
                  ]}
                />
                <PeopleInvolvedFields
                  openDialog={handleOpenPeopleInvolvedDialog}
                  list={peopleInvolvedList}
                />
                <AccidentDetailsFields
                  defaultValues={currentAccident.accidentDetail}
                  jobReceivedByList={[
                    { id: 1, name: 'Radio' },
                    { id: 2, name: 'Phone' },
                    { id: 3, name: 'Walk-In' },
                    { id: 4, name: 'Observation' },
                    { id: 5, name: 'Other' },
                  ]}
                  addAttachments={handleAddAttachments}
                  attachments={attachments}
                  attachmentsIsLoading={attachmentsIsLoading}
                  setAttachmentsIsLoading={setAttachmentsIsLoading}
                  deleteAttachment={handleDeleteAttachment}
                  downloadAttachment={handleDownloadAttachment}
                />
                {accidentTypes.injuredPersons && <div>injured</div>}
                {accidentTypes.damagedProperty && <div>damaged</div>}
                <ApprovalFields />
                {currentAccident.event.accidentId != undefined && (
                  <FormDetails
                    createdBy={currentAccident.event.insertedBy}
                    createdOn={currentAccident.event.insertedDate}
                    lastModifiedBy={currentAccident.event.updatedBy}
                    lastModifiedOn={currentAccident.event.updatedDate}
                  />
                )}
                <EventFormButtons
                  eventType={watchEventType}
                  reset={resetForm}
                  save={saveForm}
                  download={() =>
                    accidentApi.getPdfReport(
                      currentAccident.event.accidentId,
                      currentAccident.event.accidentControlNumber
                    )
                  }
                  disableDownload={isGeneralUser}
                  showDownload={showDownload}
                />
              </form>
            </FormProvider>
          ) : (
            <>
              This event has been completed. Please contact a Super User or
              Admin.
            </>
          )}
          <PeopleInvolvedDialog
            open={peopleInvolvedDialog.open}
            handleClose={handleClosePeopleInvolvedDialog}
            handleRemove={handleRemovePeopleInvolvedDialog}
            handleSave={handleSavePeopleInvolvedDialog}
            person={peopleInvolvedDialog.person}
            stateList={statesSelector}
            propertyFilterValue={propertyFilterValue}
            tenantList={tenantSelector.tenants}
            personInvolvedTypes={personInvolvedTypes}
          />
          <DeleteAttachmentAlert
            open={deleteAttachmentAlert.open}
            cancel={handleCancelDeleteAttachmentAlert}
            confirm={handleConfirmDeleteAttachmentAlert}
            attachment={deleteAttachmentAlert.attachment}
          />
          <ErrorAlertDialog
            open={showAttachmentUploadError}
            title={attachmentUploadError.title}
            content={attachmentUploadError.content}
            close={closeAttachmentErrorAlert}
          />
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
      <ErrorAlertDialog
        open={showSaveError}
        title={saveError.title}
        content={saveError.content}
        close={closeSaveErrorAlert}
      />
      <FormValidationMessage
        open={openValidationMessage}
        handleClose={handelCloseValidationMessage}
      />
    </>
  )
}

AccidentEvent.propTypes = {
  eventTypeChange: PropTypes.func.isRequired,
  accidentEvent: PropTypes.shape(),
  incident: PropTypes.shape({
    event: {
      callLogControlNumber: PropTypes.string.isRequired,
      incidentControlNumber: PropTypes.string.isRequired,
      dateReported: PropTypes.instanceOf(Date).isRequired,
      timeReported: PropTypes.instanceOf(Date).isRequired,
      dateOfIncident: PropTypes.instanceOf(Date).isRequired,
      timeOfIncident: PropTypes.instanceOf(Date).isRequired,
    },
  }),
  setRecordEventLoaded: PropTypes.func,
}

export default withRouter(AccidentEvent)

import React, { useEffect, useState } from 'react'
import CallLogEvent from '../organisms/CallLogEvent'
import IncidentEvent from '../organisms/IncidentEvent'
import UnusualIncidentEvent from '../organisms/UnusualIncidentEvent'
import AccidentEvent from '../organisms/AccidentEvent'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  apiStatusEnum,
  eventTypeEnum as eventTypes,
} from '../../../common/enums'
import { getCallLogById } from '../../../apiCalls/callLog'
import { getIncidentById } from '../../../apiCalls/incident'
import { getUnusualIncidentById } from '../../../apiCalls/unusualIncident'
import { getAccidentById } from '../../../apiCalls/accident'
import ErrorAlertDialog from '../molecules/ErrorAlertDialog'
import { useHistory, withRouter, useParams } from 'react-router-dom'
import { spinnerSize } from '../../../common/constants'
import { useSelector } from 'react-redux'
import {
  selectCurrentUser,
  selectIsACurrentUserProperty,
} from '../../../admin/slices/currentUserSlice'
import NoAccess from '../../../common/components/templates/NoAccess'

const RecordNewEvent = () => {
  const history = useHistory()

  const [callLogEvent, setCallLogEvent] = useState(null)
  const [incidentEvent, setIncidentEvent] = useState(null)
  const [unusualIncident, setUnusualIncidentEvent] = useState(null)
  const [accidentEvent, setAccidentEvent] = useState(null)
  const [eventType, setEventType] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [callLogForIncident, setCallLogForIncident] = useState(null)
  const [incidentForAccident, setIncidentForAccident] = useState(null)

  const { action, eventTypeId, id } = useParams()

  const currentUserSelector = useSelector(selectCurrentUser())
  const [propertyId, setPropertyId] = useState(null)

  const isAUserProperty = useSelector(selectIsACurrentUserProperty(propertyId))

  useEffect(async () => {
    if (currentUserSelector.status == apiStatusEnum.succeeded) {
      if (action == 'recordEvent' && eventTypeId !== undefined) {
        setPropertyId(null)
        await setEventType(parseInt(eventTypeId))
        setLoaded(true)
      }
      if (action == 'recordEvent' && eventTypeId === undefined) {
        setEventType(eventTypes.callLog)
        setPropertyId(null)
        setLoaded(true)
      }
      if (eventTypeId !== undefined && id !== undefined) {
        switch (eventTypeId) {
          case '1':
            getCallLogById(id)
              .then((response) => {
                setCallLogEvent({
                  event: { type: eventTypes.callLog, ...response },
                })
                setEventType(eventTypes.incident) //to rerender existing form, for some reason
                setEventType(eventTypes.callLog)
                setPropertyId(response.location.propertyId)
                setLoaded(true)
              })
              .catch((error) => {
                console.log(error)
                setShowLoadError(true)
                setLoadError({
                  title: 'Error encountered while loading the Call Log.',
                  content: error.name + ': ' + error.message,
                })
              })
            break
          case '2':
            getIncidentById(id)
              .then((response) => {
                setIncidentEvent({
                  event: { type: eventTypes.incident, ...response },
                })
                setEventType(eventTypes.callLog) //to rerender existing form, for some reason
                setEventType(eventTypes.incident)
                setPropertyId(response.location.propertyId)
                setLoaded(true)
              })
              .catch((error) => {
                console.log(error)
                setShowLoadError(true)
                setLoadError({
                  title: 'Error encountered while loading the Incident.',
                  content: error.name + ': ' + error.message,
                })
              })
            break
          case '3':
            if (currentUserSelector.currentUser.role !== 'General User') {
              getUnusualIncidentById(id)
                .then((response) => {
                  setUnusualIncidentEvent({
                    event: { type: eventTypes.unusualIncident, ...response },
                  })
                  setEventType(eventTypes.callLog) //to rerender existing form, for some reason
                  setEventType(eventTypes.unusualIncident)
                  setPropertyId(response.propertyId)
                  setLoaded(true)
                })
                .catch((error) => {
                  console.log(error)
                  setShowLoadError(true)
                  setLoadError({
                    title:
                      'Error encountered while loading the Unusual Incident.',
                    content: error.name + ': ' + error.message,
                  })
                })
              break
            } else {
              setEventType(eventTypes.unusualIncident)
              setLoaded(true)
              break
            }
          case '4':
            getAccidentById(id)
              .then((response) => {
                setAccidentEvent({
                  event: { type: eventTypes.accident, ...response },
                })
                setEventType(eventTypes.callLog) //to rerender existing form, for some reason
                setEventType(eventTypes.accident)
                setPropertyId(response.place.propertyId)
                setLoaded(true)
              })
              .catch((error) => {
                console.log(error)
                setShowLoadError(true)
                setLoadError({
                  title: 'Error encountered while loading the Accident.',
                  content: error.name + ': ' + error.message,
                })
              })
            break
        }
      } else {
        setLoaded(true)
      }
    }
  }, [eventTypeId, id, loaded, currentUserSelector.status])

  const eventTypeChange = async (x) => {
    switch (x) {
      case 1:
        setEventType(eventTypes.callLog)
        break
      case 2:
        setEventType(eventTypes.incident)
        break
      case 3:
        setEventType(eventTypes.unusualIncident)
        break
      case 4:
        setEventType(eventTypes.accident)
        break
    }
    history.push('/recordEvent/' + x)
  }

  const createIncidentFromCall = (callLog) => {
    setCallLogForIncident(callLog)
    setIncidentEvent(null)
    setEventType(eventTypes.incident)
    setLoaded(true)
    window.scrollTo(0, 0)
  }

  const createUnusualIncidentFromCall = (callLog) => {
    setCallLogForIncident(callLog)
    setUnusualIncidentEvent(null)
    setEventType(eventTypes.unusualIncident)
    setLoaded(true)
    window.scrollTo(0, 0)
  }

  const createAccidentFromIncident = (incident) => {
    setIncidentForAccident(incident)
    setAccidentEvent(null)
    setEventType(eventTypes.accident)
    setLoaded(true)
    window.scrollTo(0, 0)
  }

  const [showLoadError, setShowLoadError] = useState(false)
  const [loadError, setLoadError] = useState({
    title: '',
    content: '',
  })

  const closeSaveErrorAlert = () => {
    setShowLoadError(false)
    setLoadError({
      title: '',
      content: '',
    })
  }

  return (
    <>
      {loaded ? (
        <>
          {isAUserProperty ? (
            <>
              {loaded && eventType === eventTypes.callLog && (
                <CallLogEvent
                  eventTypeChange={eventTypeChange}
                  callLogEvent={callLogEvent}
                  createIncidentFromCall={createIncidentFromCall}
                  createUnusualIncidentFromCall={createUnusualIncidentFromCall}
                  setRecordEventLoaded={setLoaded}
                />
              )}
              {loaded && eventType === eventTypes.incident && (
                <IncidentEvent
                  eventTypeChange={eventTypeChange}
                  incidentEvent={incidentEvent}
                  callLog={callLogForIncident}
                  createAccidentFromIncident={createAccidentFromIncident}
                  setRecordEventLoaded={setLoaded}
                />
              )}
              {loaded &&
                eventType === eventTypes.unusualIncident &&
                currentUserSelector.currentUser.role !== 'General User' && (
                  <UnusualIncidentEvent
                    eventTypeChange={eventTypeChange}
                    unusualIncidentEvent={unusualIncident}
                    callLog={callLogForIncident}
                    setRecordEventLoaded={setLoaded}
                  />
                )}
              {loaded &&
                eventType === eventTypes.unusualIncident &&
                currentUserSelector.currentUser.role === 'General User' && (
                  <NoAccess reason='(Role Access)' />
                )}
              {loaded && eventType === eventTypes.accident && (
                <AccidentEvent
                  eventTypeChange={eventTypeChange}
                  accidentEvent={accidentEvent}
                  incident={incidentForAccident}
                  setRecordEventLoaded={setLoaded}
                />
              )}{' '}
            </>
          ) : (
            <NoAccess reason='(Property Access)' />
          )}
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
      <ErrorAlertDialog
        open={showLoadError}
        title={loadError.title}
        content={loadError.content}
        close={closeSaveErrorAlert}
      />
    </>
  )
}

export default withRouter(RecordNewEvent)

import React, { useState } from 'react'
import {
  Input,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  FormHelperText,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Controller, useFormContext } from 'react-hook-form'

const MultiSelectField = ({
  label,
  value,
  onChange,
  options = [],
  required = true,
  invalid,
  errorMessage,
  defaultValue = '',
}) => {
  var initialValue = []
  if (defaultValue.length) initialValue = defaultValue.split(', ')

  initialValue = initialValue.filter((v) => options.some((o) => o.name == v))

  const [selectedValues, setSelectedValues] = useState(initialValue)

  const handleChange = (event) => {
    const selected = event.target.value
    setSelectedValues(selected)
    onChange(selected.join(', '))
  }

  return (
    <FormControl fullWidth={true} required={required} error={invalid}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        error={invalid}
        value={selectedValues}
        onChange={handleChange}
        input={<Input />}
        renderValue={() => value}
        required={true}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.name}>
            <Checkbox checked={selectedValues.indexOf(option.name) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{invalid ? errorMessage : ' '}</FormHelperText>
    </FormControl>
  )
}

MultiSelectField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  defaultValue: PropTypes.string,
}

const FormMultiSelect = ({
  name,
  label,
  options,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = '',
}) => {
  const { control } = useFormContext()

  return (
    <>
      <Controller
        render={({ onChange, value }, { invalid }) => (
          <MultiSelectField
            label={label}
            options={options}
            required={required}
            value={value}
            onChange={onChange}
            invalid={invalid}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
          />
        )}
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
      />
    </>
  )
}

FormMultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
}

export default FormMultiSelect

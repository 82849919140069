import { createSlice } from '@reduxjs/toolkit'
import * as eventConditionsApi from '../../apiCalls/eventCondition'

const eventConditionsSlice = createSlice({
  name: 'eventConditions',
  initialState: [],
  reducers: {
    loadEventConditions(_, action) {
      return action.payload
    },
  },
})

const { loadEventConditions } = eventConditionsSlice.actions

export const loadEventConditionsAsync = () => async (dispatch) => {
  return await eventConditionsApi
    .getEventConditions()
    .then((eventConditions) => {
      dispatch(loadEventConditions(eventConditions))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectEventConditions = (state) => state.eventConditions

export const selectFilteredEventConditions = (supervisorSigned) => (state) => {
  if (supervisorSigned) {
    return state.eventConditions
  } else if (state.eventConditions.length > 0) {
    return state.eventConditions.filter((c) => c.name === 'Pending')
  } else return []
}

export default eventConditionsSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import * as eventDispatchedStatusApi from '../../apiCalls/eventDispatchedStatus'

const eventDispatchedStatusSlice = createSlice({
  name: 'eventDispatchedStatus',
  initialState: [],
  reducers: {
    loadEventDispatchedStatus(_, action) {
      return action.payload
    },
  },
})

const { loadEventDispatchedStatus } = eventDispatchedStatusSlice.actions

export const loadEventDispatchedStatusAsync = () => async (dispatch) => {
  return await eventDispatchedStatusApi
    .getEventDispatchedStatus()
    .then((eventDispatchedStatus) => {
      dispatch(loadEventDispatchedStatus(eventDispatchedStatus))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectEventDispatchedStatus = (state) =>
  state.eventDispatchedStatus

export default eventDispatchedStatusSlice.reducer

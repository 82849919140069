import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'

const FormTenantSelect = ({ label, options, onChangeHandler }) => (
  <Autocomplete
    options={options}
    getOptionLabel={(option) => option.firstName + ' ' + option.lastName}
    onChange={async (_, data) => {
      onChangeHandler(data)
      return data
    }}
    fullWidth={true}
    openOnFocus={true}
    renderInput={(params) => (
      <TextField {...params} helperText=' ' label={label} />
    )}
  />
)

FormTenantSelect.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
}

export default FormTenantSelect

import React, { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'
import {
  loadPropertiesAsync,
  selectPropertiesActiveFilter,
} from '../../slices/propertySlice'
import {
  selectPrimaryThemeColor,
  selectSecondaryThemeColor,
  selectTertiaryThemeColor,
} from '../../slices/themeColorSlice'
import { useDispatch, useSelector } from 'react-redux'
import {
  Grid,
  CircularProgress,
  makeStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { GetApp, Publish, CheckCircle } from '@material-ui/icons'
import { importTenantsFromExcelFile } from '../../../apiCalls/tenant'
import { apiStatusEnum as apiStatus } from '../../../common/enums'
import {
  loadSectionsAsync,
  selectAllActiveSections,
} from '../../slices/sectionSlice'
import {
  loadBuildingsAsync,
  selectAllActiveBuildings,
} from '../../slices/buildingSlice'
import {
  loadLocationsAsync,
  selectAllActiveApartmentLocations,
} from '../../slices/locationSlice'
import {
  loadApartmentsAsync,
  selectAllActiveApartments,
} from '../../slices/apartmentSlice'
import { loadTenantsAsync } from '../../slices/tenantSlice'
import {
  loadStatesAsync,
  selectStates,
} from '../../../eventReports/slices/stateCodesSlice'
import { spinnerSize } from '../../../common/constants'

const template = {
  Section: '',
  Building: '',
  Apartment: '',
  'First Name': '',
  'Last Name': '',
  MI: '',
  'Home Telephone': '',
  'Business Telephone': '',
  'Telephone Number': '',
  Address: '',
  City: '',
  State: '',
  Zip: '',
}

const arraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length != b.length) return false

  for (let i = 0; i < a.length; i++) {
    if (a[i] != b[i]) return false
  }
  return true
}

const ImportTenants = () => {
  const useStyles = makeStyles({
    grid: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      width: '95%',
    },
    validateButton: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
    downloadButton: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
    },
    importButton: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      color: 'white',
    },
    successMessage: {
      color: '#34cb34',
    },
    failedMessage: {
      color: '#e60000',
    },
  })
  const classes = useStyles()

  const dispatch = useDispatch()

  const [dataLoaded, setDataLoaded] = useState(false)
  const loaded = () => setDataLoaded(true)

  const [inProgress, setInProgress] = useState(false)
  const startAction = () => setInProgress(true)
  const endAction = () => setInProgress(false)

  const [isImporting, setIsImporting] = useState(false)
  const startImporting = () => {
    setInProgress(true)
    setIsImporting(true)
  }
  const endImporting = () => {
    setInProgress(false)
    setIsImporting(false)
  }

  const [uploadedFile, setUploadedFile] = useState(null)
  const [uploadedFileName, setUploadedFileName] = useState(null)
  const [uploadedData, setUploadedData] = useState(null)

  const [valid, setValid] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')

  const [selectedProperty, setSelectedProperty] = useState('')
  const propertiesSelector = useSelector(selectPropertiesActiveFilter(true))
  const sectionsSelector = useSelector(selectAllActiveSections())
  const buildingSelector = useSelector(selectAllActiveBuildings())
  const locationsSelector = useSelector(selectAllActiveApartmentLocations())
  const apartmentsSelector = useSelector(selectAllActiveApartments())
  const statesSelector = useSelector(selectStates)

  useEffect(() => {
    if (propertiesSelector.status === apiStatus.idle) {
      dispatch(loadPropertiesAsync())
    }
    if (sectionsSelector.status === apiStatus.idle) {
      dispatch(loadSectionsAsync())
    }
    if (buildingSelector.status === apiStatus.idle) {
      dispatch(loadBuildingsAsync())
    }
    if (locationsSelector.status === apiStatus.idle) {
      dispatch(loadLocationsAsync())
    }
    if (apartmentsSelector.status === apiStatus.idle) {
      dispatch(loadApartmentsAsync())
    }
    if (statesSelector.length === 0) {
      dispatch(loadStatesAsync())
    }
    if (
      propertiesSelector.status === apiStatus.succeeded &&
      sectionsSelector.status === apiStatus.succeeded &&
      buildingSelector.status === apiStatus.succeeded &&
      locationsSelector.status === apiStatus.succeeded &&
      apartmentsSelector.status === apiStatus.succeeded
    ) {
      loaded()
    }
  }, [
    propertiesSelector.status,
    sectionsSelector.status,
    buildingSelector.status,
    locationsSelector.status,
    apartmentsSelector.status,
    statesSelector.length,
  ])

  const handlePropertyChange = (event) => {
    startAction()
    setSelectedProperty(event.target.value)
    setUploadedFile(null)
    setUploadedFileName(null)
    setUploadedData(null)
    setValid(false)
    setValidationMessage('')
    endAction()
  }

  const getSection = (sectionName) => {
    const section = sectionsSelector.sections.find(
      (s) => s.name === sectionName && s.propertyId === selectedProperty
    )
    return section
  }

  const getBuilding = (sectionId, buildingName) => {
    const building = buildingSelector.buildings.find(
      (b) => b.sectionId === sectionId && b.name === buildingName
    )
    return building
  }

  const getLocation = (buildingId) => {
    const location = locationsSelector.locations.find(
      (l) => l.buildingId === buildingId && l.name === 'Apartment'
    )
    return location
  }

  const getApartment = (locationId, apartmentName) => {
    const apartment = apartmentsSelector.apartments.find(
      (a) => a.locationId === locationId && a.name === apartmentName
    )
    return apartment
  }

  const downloadTemplate = () => {
    startAction()
    const workSheet = XLSX.utils.json_to_sheet([template])
    const workBook = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Tenants')
    XLSX.writeFile(workBook, 'TenantImport.xlsx')
    endAction()
  }

  const handleFile = (currentFile) => {
    if (currentFile != undefined) {
      startAction()
      setValid(false)
      setValidationMessage('')
      setUploadedFile(currentFile)
      setUploadedFileName(currentFile.name)
      const reader = new FileReader()
      const rABS = !!reader.readAsBinaryString
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result
        const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
        /* Get first worksheet */
        const wsName = wb.SheetNames[0]
        const ws = wb.Sheets[wsName]
        /* Convert to json */
        const data = XLSX.utils.sheet_to_json(ws)
        /* Update state */
        setUploadedData(data)
      }
      if (rABS) reader.readAsBinaryString(currentFile)
      else reader.readAsArrayBuffer(currentFile)
      endAction()
    }
  }

  const validateNumber = (testNumber) => {
    return /\(\d{3}\) \d{3}-\d{4}/.test(testNumber)
  }

  const handleValidation = (data) => {
    startAction()
    var error = false
    const tenants = data
    const columns = Object.keys(template)

    if (tenants.length === 0) {
      setValid(false)
      setValidationMessage('No tenants found.')
      endAction()
      return false
    }

    if (!arraysEqual(columns, Object.keys(tenants[0]))) {
      setValid(false)
      setValidationMessage(
        'Not all column names are present or in the correct order.'
      )
      error = true
    }

    var tenantArray = []
    for (var i = 0; i < tenants.length; i++) {
      let tenant = tenants[i]

      if (
        tenant['Section'] == null ||
        tenant['Section'] == '' ||
        tenant['Building'] == null ||
        tenant['Building'] == '' ||
        tenant['Apartment'] == null ||
        tenant['Apartment'] == '' ||
        tenant['First Name'] == null ||
        tenant['First Name'] == '' ||
        tenant['Last Name'] == null ||
        tenant['Last Name'] == ''
      ) {
        setValid(false)
        setValidationMessage(
          'Required field value not found in row ' + (i + 2) + '.'
        )
        error = true
        break
      }

      const currentTenant =
        tenant['Section'] +
        '' +
        tenant['Building'] +
        '' +
        tenant['Apartment'] +
        '' +
        tenant['First Name'] +
        '' +
        tenant['Last Name']

      if (tenantArray.includes(currentTenant)) {
        setValid(false)
        setValidationMessage(
          'Duplicate tenant for building found in row ' + (i + 2) + '.'
        )
        error = true
        break
      } else {
        tenantArray.push(currentTenant)
      }

      const section = getSection(tenant['Section'])
      if (section) {
        const building = getBuilding(section.id, tenant['Building'])
        if (building) {
          const location = getLocation(building.id)
          if (location) {
            if (!getApartment(location.id, tenant['Apartment'])) {
              setValid(false)
              setValidationMessage(
                'Incorrect apartment found in row ' + (i + 2) + '.'
              )
              error = true
              break
            }
          } else {
            setValid(false)
            setValidationMessage(
              'No apartments found for building ' +
                tenant['Building'] +
                ' in row ' +
                (i + 2) +
                '.'
            )
            error = true
            break
          }
        } else {
          setValid(false)
          setValidationMessage(
            'Incorrect building found in row ' + (i + 2) + '.'
          )
          error = true
          break
        }
      } else {
        setValid(false)
        setValidationMessage('Incorrect section found in row ' + (i + 2) + '.')
        error = true
        break
      }

      if (tenant['State']) {
        const tenantState = statesSelector.find(
          (s) => s.name == tenant['State']
        )
        if (!tenantState) {
          setValid(false)
          setValidationMessage('Incorrect state found in row ' + (i + 2) + '.')
          error = true
          break
        }
      }

      if (tenant['Home Telephone']) {
        if (!validateNumber(tenant['Home Telephone'])) {
          setValid(false)
          setValidationMessage(
            'Invalid Home Telephone number found in row ' +
              (i + 2) +
              '. Please match the format (000) 000-0000.'
          )
          error = true
          break
        }
      }

      if (tenant['Business Telephone']) {
        if (!validateNumber(tenant['Business Telephone'])) {
          setValid(false)
          setValidationMessage(
            'Invalid Business Telephone number found in row ' +
              (i + 2) +
              '. Please match the format (000) 000-0000.'
          )
          error = true
          break
        }
      }

      if (tenant['Telephone Number']) {
        if (!validateNumber(tenant['Telephone Number'])) {
          setValid(false)
          setValidationMessage(
            'Invalid Telephone Number found in row ' +
              (i + 2) +
              '. Please match the format (000) 000-0000.'
          )
          error = true
          break
        }
      }

      if (tenant['Zip']) {
        if (!/^\d*$/.test('' + tenant['Zip'])) {
          setValid(false)
          setValidationMessage('Invalid Zip found in row ' + (i + 2) + '.')
          error = true
          break
        }
      }
    }

    if (!error) {
      setValid(true)
      setValidationMessage('Valid form.')
    }
    endAction()
  }

  const handleImport = async (file) => {
    startImporting()
    await importTenantsFromExcelFile(selectedProperty, file)
      .then(() => {
        dispatch(loadTenantsAsync())
        setValidationMessage('Tenants imported successfully.')
      })
      .catch(() => {
        setValid(false)
        setValidationMessage('Tenant import failed.')
      })
    endImporting()
  }

  return (
    <>
      {dataLoaded === true ? (
        <>
          <Grid container className={classes.grid} spacing={1}>
            <Grid item xs={3}>
              <FormControl fullWidth={true}>
                <InputLabel>Property</InputLabel>
                <Select
                  label='Property'
                  id='property'
                  value={selectedProperty}
                  onChange={handlePropertyChange}
                >
                  {propertiesSelector.properties.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={9} />
            {selectedProperty != '' && (
              <>
                <Grid item xs={2} align='center'>
                  <Button variant='contained' component='label'>
                    Select File
                    <input
                      accept='.xlsx'
                      name='uploadTenantList'
                      type='file'
                      onChange={(e) => {
                        handleFile(e.target.files[0])
                      }}
                      hidden
                    />
                  </Button>
                </Grid>
                <Grid item xs={10}>
                  {uploadedFileName}
                </Grid>
                {validationMessage != '' && !valid && (
                  <Grid item xs={8}>
                    <Typography className={classes.failedMessage}>
                      {validationMessage}
                    </Typography>
                  </Grid>
                )}
                {validationMessage != '' && valid && (
                  <Grid item xs={8}>
                    <Typography className={classes.successMessage}>
                      {validationMessage}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} />
              </>
            )}
            <Grid item xs={3} style={{ textAlign: 'center' }}>
              <Button
                className={classes.downloadButton}
                startIcon={<GetApp />}
                variant='contained'
                onClick={() => downloadTemplate()}
              >
                Download Template
              </Button>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3} style={{ textAlign: 'right' }}>
              <Button
                className={classes.validateButton}
                startIcon={<CheckCircle />}
                variant='contained'
                disabled={uploadedData === null || inProgress}
                onClick={() => handleValidation(uploadedData)}
              >
                Validate
              </Button>
            </Grid>
            <Grid item xs={3} style={{ textAlign: 'left' }}>
              <Button
                className={classes.importButton}
                startIcon={
                  isImporting ? <CircularProgress size={24} /> : <Publish />
                }
                variant='contained'
                onClick={async () => await handleImport(uploadedFile)}
                disabled={!valid || inProgress}
              >
                Import
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
    </>
  )
}

export default ImportTenants

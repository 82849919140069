import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
//Component imports
import CircularProgress from '@material-ui/core/CircularProgress'
import LinkedReports from '../molecules/LinkedReports'
import UnusualIncidentEventDetails from '../molecules/UnusualIncidentEventDetails'
import FormDetails from '../molecules/FormDetails'
import EventFormButtons from '../molecules/EventFormButtons'
//Store imports
import { useDispatch, useSelector } from 'react-redux'
import {
  loadPropertiesAsync,
  selectActiveUserProperties,
} from '../../../admin/slices/propertySlice'
//Constants
import { eventTypes, roles, spinnerSize } from '../../../common/constants'
import {
  apiStatusEnum as apiStatus,
  eventTypeEnum,
} from '../../../common/enums'
import * as unusualIncidentApi from '../../../apiCalls/unusualIncident'
import { useHistory, withRouter } from 'react-router-dom'
import {
  selectControlNumbers,
  loadControlNumbersAsync,
} from '../../slices/callLogControlNumberSlice'
import {
  selectCurrentUserDisplayName,
  selectCurrentUserProperties,
  selectCurrentUserRole,
} from '../../../admin/slices/currentUserSlice'
import { Typography } from '@material-ui/core'
import ErrorAlertDialog from '../molecules/ErrorAlertDialog'
import PageHeader from '../../../common/components/organisms/PageHeader'
import FormValidationMessage from '../molecules/FormValidationMessage'
import { SettingsOverscanOutlined } from '@material-ui/icons'
import NoAccess from '../../../common/components/templates/NoAccess'

const UnusualIncidentEvent = ({
  eventTypeChange,
  unusualIncidentEvent = null,
  callLog = null,
  setRecordEventLoaded,
}) => {
  const [header, setHeader] = useState('Record New Unusual Incident')
  const currentUserName = useSelector(selectCurrentUserDisplayName())
  const currentUserActiveProperties = useSelector(
    selectCurrentUserProperties()
  ).filter((p) => p.isActive)
  const isGeneralUser =
    useSelector(selectCurrentUserRole()) === roles.generalUser
  const defaultUnusualIncident = {
    event: {
      type: 3,
      preparedBy: currentUserName,
      dateOfIncident: new Date(),
      timeOfIncident: new Date(),
      propertyId: currentUserActiveProperties.length
        ? currentUserActiveProperties[0].id
        : null,
    },
  }
  let currentUnusualIncident = {}
  if (unusualIncidentEvent !== null)
    currentUnusualIncident = unusualIncidentEvent
  else currentUnusualIncident = defaultUnusualIncident

  if (unusualIncidentEvent === null && callLog !== null) {
    currentUnusualIncident.event = {
      ...currentUnusualIncident.event,
      callLogControlNumber: callLog.event.callLogControlNumber,
      propertyId: callLog.event.location.propertyId,
    }
  }

  const showDownload = unusualIncidentEvent != null

  const methods = useForm({ defaultValues: currentUnusualIncident })
  const { handleSubmit, watch, formState } = methods

  const dispatch = useDispatch()

  const [dataLoaded, setDataLoaded] = useState(false)
  const loading = () => setDataLoaded(false)
  const loaded = () => setDataLoaded(true)

  const resetForm = () => {
    setRecordEventLoaded(false)
    if (currentUnusualIncident.event.unusualIncidentId != undefined) {
      history.push(
        '/event/' +
          eventTypeEnum.unusualIncident +
          '/' +
          currentUnusualIncident.event.unusualIncidentId
      )
    } else {
      history.push('/recordEvent/' + eventTypeEnum.unusualIncident)
    }
  }

  const savedProperty = unusualIncidentEvent
    ? unusualIncidentEvent.event.propertyId
    : null
  const propertySelector = useSelector(
    selectActiveUserProperties(currentUserActiveProperties, savedProperty)
  )
  const callLogControlNumbers = useSelector(selectControlNumbers)

  useEffect(() => {
    if (currentUnusualIncident.event.unusualIncidentId != undefined) {
      setHeader('Unusual Incident')
    }
    if (callLogControlNumbers.status != apiStatus.pending)
      dispatch(loadControlNumbersAsync())
    if (propertySelector.status === apiStatus.idle) {
      dispatch(loadPropertiesAsync()).then(() => loaded())
    }
    if (propertySelector.status === apiStatus.succeeded) {
      loaded()
    }
  }, [dispatch, dataLoaded])

  const watchEventType = watch('event.type')
  const history = useHistory()

  const [showSaveError, setShowSaveError] = useState(false)
  const [saveError, setSaveError] = useState({
    title: '',
    content: '',
  })

  const closeSaveErrorAlert = () => {
    setShowSaveError(false)
    setSaveError({
      title: '',
      content: '',
    })
    loaded()
  }

  const { isValid } = formState
  const saveForm = () => {
    if (!isValid) {
      setOpenValidationMessage(true)
    }
  }

  const [openValidationMessage, setOpenValidationMessage] = useState(false)
  const handelCloseValidationMessage = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setOpenValidationMessage(false)
  }

  return (
    <>
      <PageHeader pageName={header} />
      {dataLoaded ? (
        <>
          {!isGeneralUser ? (
            <FormProvider {...methods}>
              <form
                onSubmit={handleSubmit(async (data) => {
                  setOpenValidationMessage(false)
                  loading()
                  if (
                    currentUnusualIncident.event.unusualIncidentId != undefined
                  ) {
                    unusualIncidentApi
                      .updateUnusualIncident(
                        data,
                        currentUnusualIncident.event.unusualIncidentId
                      )
                      .then((unusualIncidentId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' +
                            eventTypeEnum.unusualIncident +
                            '/' +
                            unusualIncidentId
                        )
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  } else {
                    unusualIncidentApi
                      .addUnusualIncident(data)
                      .then((unusualIncidentId) => {
                        setRecordEventLoaded(false)
                        history.push(
                          '/event/' +
                            eventTypeEnum.unusualIncident +
                            '/' +
                            unusualIncidentId
                        )
                        loaded()
                      })
                      .catch((error) => {
                        console.log(error)
                        setShowSaveError(true)
                        setSaveError({
                          title: 'Error encountered while saving the incident.',
                          content: error.name + ': ' + error.message,
                        })
                      })
                  }
                })}
              >
                {currentUnusualIncident.event.controlNumberReferenceTree && (
                  <LinkedReports
                    controlNumberReferenceTree={
                      currentUnusualIncident.event.controlNumberReferenceTree
                    }
                  />
                )}
                {propertySelector.status === apiStatus.succeeded &&
                  propertySelector.properties.length == 0 && (
                    <Typography color='secondary' align='center' variant='h5'>
                      Please note: You currently do not have any properties
                      assigned to you. You can contact a CHIEF system admin for
                      your property access to be updated.
                    </Typography>
                  )}
                <UnusualIncidentEventDetails
                  defaultValues={currentUnusualIncident.event}
                  eventTypes={eventTypes}
                  eventTypeChangeHandler={eventTypeChange}
                  callControlNumber={
                    currentUnusualIncident.event.controlNumberReferenceTree
                      ? [
                          ...callLogControlNumbers.controlNumbersForUnusualIncident,
                          {
                            id:
                              currentUnusualIncident.event
                                .controlNumberReferenceTree
                                .callLogControlNumber,
                            name:
                              currentUnusualIncident.event
                                .controlNumberReferenceTree
                                .callLogControlNumber,
                          },
                        ]
                      : callLogControlNumbers.controlNumbersForUnusualIncident
                  }
                  properties={propertySelector.properties}
                />
                {currentUnusualIncident.event.unusualIncidentId !=
                  undefined && (
                  <FormDetails
                    createdBy={currentUnusualIncident.event.insertedBy}
                    createdOn={currentUnusualIncident.event.insertedDate}
                    lastModifiedBy={currentUnusualIncident.event.updatedBy}
                    lastModifiedOn={currentUnusualIncident.event.updatedDate}
                  />
                )}
                <EventFormButtons
                  eventType={watchEventType}
                  reset={resetForm}
                  save={saveForm}
                  download={() =>
                    unusualIncidentApi.getPdfReport(
                      currentUnusualIncident.event.unusualIncidentId,
                      currentUnusualIncident.event.unusualIncidentControlNumber
                    )
                  }
                  showDownload={showDownload}
                />
              </form>
            </FormProvider>
          ) : (
            <NoAccess reason='(Role Access)' />
          )}
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
      <ErrorAlertDialog
        open={showSaveError}
        title={saveError.title}
        content={saveError.content}
        close={closeSaveErrorAlert}
      />
      <FormValidationMessage
        open={openValidationMessage}
        handleClose={handelCloseValidationMessage}
      />
    </>
  )
}

UnusualIncidentEvent.propTypes = {
  eventTypeChange: PropTypes.func.isRequired,
  unusualIncidentEvent: PropTypes.shape(),
  callLog: PropTypes.shape({
    event: {
      callLogNumber: PropTypes.string.isRequired,
      date: PropTypes.instanceOf(Date).isRequired,
      timeResponded: PropTypes.instanceOf(Date).isRequired,
    },
  }),
  setRecordEventLoaded: PropTypes.func,
}

export default withRouter(UnusualIncidentEvent)

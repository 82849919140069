import { createSlice } from '@reduxjs/toolkit'
import * as statesApi from '../../apiCalls/state'

const stateCodesSlice = createSlice({
  name: 'stateCodes',
  initialState: [],
  reducers: {
    loadStateCodes(_, action) {
      return action.payload
    },
  },
})

const { loadStateCodes } = stateCodesSlice.actions

export const loadStatesAsync = () => async (dispatch) => {
  return await statesApi
    .getStates()
    .then((stateCodes) => {
      dispatch(loadStateCodes(stateCodes))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectStates = (state) => state.stateCodes

export default stateCodesSlice.reducer

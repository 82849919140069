import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core/'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'
import FormTextArea from '../atoms/FormTextArea'
import { useFormContext } from 'react-hook-form'
import Attachments from './Attachments'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const IncidentDetailsFields = ({
  defaultValues = {},
  addAttachments,
  attachments,
  attachmentsIsLoading,
  setAttachmentsIsLoading,
  deleteAttachment,
  downloadAttachment,
}) => {
  const classes = useStyles()

  const { watch } = useFormContext()
  const watchAdditionalReports = watch('event.incidentDetail.attachReports')

  return (
    <>
      <SectionHeader sectionName='Details' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={3}>
          <FormCheckbox
            name='event.incidentDetail.housingCompanyPropertyAffected'
            label='Housing Company Property Affected'
            // defaultValue={false}
          />
        </Grid>
        <Grid item xs={3}>
          <FormCheckbox
            name='event.incidentDetail.aidedProperty'
            label='Aided Property'
            // defaultValue={false}
          />
        </Grid>
        <Grid item xs={3}>
          <FormInput
            name='event.incidentDetail.aidedNumber'
            label='Aided No.(s) (if any)'
            // defaultValue={''}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={12}>
          <FormTextArea
            name='event.incidentDetail.description'
            label='Describe details of incident (include method of entry/escape)'
            rows={10}
            required={true}
            errorMessage={'Details field is required'}
            rules={{ required: true }}
            // defaultValue={''}
          />
        </Grid>
        <Grid item xs={12}>
          <FormCheckbox
            name='event.incidentDetail.attachReports'
            label='Attach additional reports'
            // defaultValue={false}
          />
        </Grid>
        {watchAdditionalReports && (
          <Grid item xs={12}>
            <Attachments
              attachments={attachments}
              addAttachments={addAttachments}
              isLoading={attachmentsIsLoading}
              setIsLoading={setAttachmentsIsLoading}
              deleteAttachment={deleteAttachment}
              downloadAttachment={downloadAttachment}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

IncidentDetailsFields.propTypes = {
  defaultValues: PropTypes.shape({
    housingCompanyPropertyAffected: PropTypes.bool,
    aidedPrepared: PropTypes.bool,
    aidedNumbers: PropTypes.string,
    incidentDescription: PropTypes.string,
    additionalReports: PropTypes.bool,
  }),
  addAttachments: PropTypes.func,
  attachments: PropTypes.array,
  attachmentsIsLoading: PropTypes.bool,
  setAttachmentsIsLoading: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  downloadAttachment: PropTypes.func.isRequired,
}

export default IncidentDetailsFields

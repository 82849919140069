import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@material-ui/core'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import CloseIcon from '@material-ui/icons/Close'
import FormSelect from '../atoms/FormSelect'
import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'
import FormTimePicker from '../atoms/FormTimePicker'
import FormMaskedInput from '../atoms/FormMaskedInput'
import { vehicleIssueTypes } from '../../../common/constants'
import FormMultiSelect from '../atoms/FormMultiSelect'
import { useSelector } from 'react-redux'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'

const VehicleDialog = ({
  open,
  handleClose,
  handleRemove,
  handleSave,
  vehicle = {},
  stateList,
}) => {
  const useStyles = makeStyles({
    closeButton: {
      position: 'absolute',
      right: '0px',
    },
    buttonSave: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
      float: 'right',
    },
  })
  const classes = useStyles()

  const methods = useForm()
  const { watch, handleSubmit } = methods

  const watchStickered = watch('stickered', vehicle.stickered || false)
  const watchTowed = watch('towed', vehicle.towed || false)
  const watchLegallyParked = watch(
    'parkedLegally',
    vehicle.parkedLegally || false
  )

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={'md'}
    >
      <DialogTitle>
        Vehicle
        <IconButton onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...methods}>
          <form>
            <Grid container spacing={0} direction='row' alignItems='center'>
              <Grid item xs={4}>
                <FormMultiSelect
                  name='vehicleIssues'
                  label='Issues'
                  options={vehicleIssueTypes}
                  required={true}
                  errorMessage={'Issue field is required'}
                  rules={{ required: true }}
                  defaultValue={vehicle.vehicleIssues || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormInput
                  name='plateNumber'
                  label='Plate No.'
                  required={true}
                  errorMessage={'Plate No. field is required'}
                  rules={{ required: true }}
                  defaultValue={vehicle.plateNumber || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormSelect
                  name='stateId'
                  label='State'
                  options={stateList}
                  defaultValue={vehicle.stateId || 32}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={1}>
                <FormMaskedInput
                  name='year'
                  label='Year'
                  mask='####'
                  maskChar='_'
                  defaultValue={vehicle.year || ''}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInput
                  name='make'
                  label='Make'
                  defaultValue={vehicle.make || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormInput
                  name='style'
                  label='Style'
                  defaultValue={vehicle.style || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <FormInput
                  name='model'
                  label='Model'
                  defaultValue={vehicle.model || ''}
                />
              </Grid>
              <Grid item xs={4}>
                <FormInput
                  name='vinNumber'
                  label='VIN No.'
                  defaultValue={vehicle.vinNumber || ''}
                />
              </Grid>
              <Grid item xs={8} />
              <Grid item xs={2}>
                <FormCheckbox
                  name='stickered'
                  label='Stickered'
                  defaultValue={vehicle.stickered || false}
                />
              </Grid>
              <Grid item xs={2}>
                {watchStickered && (
                  <FormTimePicker
                    name='timeStickered'
                    label='Time Stickered'
                    errorMessage='A valid time is required'
                    required={true}
                    rules={{
                      required: true,
                      validate: (value) => !value || !isNaN(Date.parse(value)),
                    }}
                    defaultValue={vehicle.timeStickered || null}
                  />
                )}
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormCheckbox
                  name='towed'
                  label='Towed'
                  defaultValue={vehicle.towed || false}
                />
              </Grid>
              <Grid item xs={2}>
                {watchTowed && (
                  <FormTimePicker
                    name='timeTowed'
                    label='Time Towed'
                    errorMessage='A valid time is required'
                    required={true}
                    rules={{
                      required: true,
                      validate: (value) => !value || !isNaN(Date.parse(value)),
                    }}
                    defaultValue={vehicle.timeTowed || null}
                  />
                )}
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={5}>
                <FormCheckbox
                  name='parkedLegally'
                  label='Vehicle was parked on property legally'
                  defaultValue={vehicle.parkedLegally || false}
                />
              </Grid>
              <Grid item xs={7}>
                {!watchLegallyParked && (
                  <FormInput
                    name='parkedLegallyExplanation'
                    label='If no, explain'
                    errorMessage='Explanation is required'
                    required={true}
                    rules={{
                      required: true,
                    }}
                    defaultValue={vehicle.parkedLegallyExplanation || ''}
                  />
                )}
              </Grid>
            </Grid>
            <DialogActions>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Button
                    variant='contained'
                    startIcon={<RemoveCircleOutlineIcon />}
                    fullWidth={true}
                    onClick={() => {
                      handleRemove()
                    }}
                  >
                    Remove
                  </Button>
                </Grid>
                <Grid item xs={8} />
                <Grid item xs={2}>
                  <Button
                    className={classes.buttonSave}
                    variant='contained'
                    startIcon={<AddIcon />}
                    fullWidth={true}
                    onClick={handleSubmit((data) => {
                      handleSave(data)
                    })}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

VehicleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  vehicle: PropTypes.shape({
    vehicleIssues: PropTypes.string,
    plateNumber: PropTypes.string,
    stateId: PropTypes.number,
    year: PropTypes.string,
    make: PropTypes.string,
    style: PropTypes.style,
    vinNumber: PropTypes.string,
    stickered: PropTypes.bool,
    timeStickered: PropTypes.instanceOf(Date),
    towed: PropTypes.bool,
    timeTowed: PropTypes.instanceOf(Date),
    legallyParked: PropTypes.bool,
    illegalParkingDescription: PropTypes.string,
  }),
  stateList: PropTypes.array,
}

export default VehicleDialog

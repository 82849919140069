import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Divider, Typography } from '@material-ui/core'

import { format } from 'date-fns'

import { dateFormat } from '../../../common/constants'

const FormDetails = ({
  createdBy,
  createdOn,
  lastModifiedBy = null,
  lastModifiedOn = null,
}) => (
  <>
    <Grid
      container
      spacing={1}
      style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '98%',
      }}
    >
      <Grid item xs={12} style={{ padding: '10px' }}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography align='right' color='textSecondary' variant='body2'>
          Created by user, {createdBy}, on{' '}
          {format(new Date(createdOn), dateFormat)} at{' '}
          {format(new Date(createdOn), 'p')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {lastModifiedOn != null ? (
          <Typography align='right' color='textSecondary' variant='body2'>
            Last Modified by user, {lastModifiedBy}, on{' '}
            {format(new Date(lastModifiedOn), dateFormat)} at{' '}
            {format(new Date(lastModifiedOn), 'p')}
          </Typography>
        ) : (
          <Typography></Typography>
        )}
      </Grid>
    </Grid>
  </>
)

FormDetails.propTypes = {
  createdBy: PropTypes.string,
  createdOn: PropTypes.any,
  lastModifiedBy: PropTypes.string,
  lastModifiedOn: PropTypes.any,
}
export default FormDetails

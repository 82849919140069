import React from 'react'
import PropTypes from 'prop-types'
import { Button, Grid, makeStyles } from '@material-ui/core'
import { useHistory, withRouter } from 'react-router-dom'

const useStyles = makeStyles({
  image: {
    height: 80,
  },
  button: {
    '&:hover': {
      background: 'none',
    },
  },
  center: {
    textAlign: 'center',
    verticalAlign: 'middle',
  },
})

const HeaderTitle = ({ title, subtitle, image }) => {
  const history = useHistory()

  const classes = useStyles()
  return (
    <Grid container style={{ marginTop: 0 }}>
      <Grid item xs={2} className={classes.center}>
        <Button
          onClick={() => history.push('/')}
          disableRipple={true}
          className={classes.button}
        >
          <img className={classes.image} src={image} />
        </Button>
      </Grid>
      <Grid item xs={10}>
        <h1>{title}</h1>
        <h6>{subtitle}</h6>
      </Grid>
    </Grid>
  )
}

HeaderTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
}

export default withRouter(HeaderTitle)

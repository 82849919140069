import React from 'react'
import { render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import App from './App'
import store from './store/store'
import { Provider as ReduxProvider } from 'react-redux'
// Authentication
import { PublicClientApplication } from '@azure/msal-browser'
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react'
import { msalConfig } from './common/auth/authConfig'
import ForceADSignIn from './common/components/molecules/ForceADSignIn'

const msalInstance = new PublicClientApplication(msalConfig)

render(
  <MsalProvider instance={msalInstance}>
    <UnauthenticatedTemplate>
      <ForceADSignIn />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <ReduxProvider store={store}>
        <Router>
          <App />
        </Router>
      </ReduxProvider>
    </AuthenticatedTemplate>
  </MsalProvider>,
  document.getElementById('app')
)

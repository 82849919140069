import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const ErrorAlertDialog = ({
  open = false,
  close,
  title = 'An error occured',
  content = 'No details provided',
}) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color='primary'>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ErrorAlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
}

export default ErrorAlertDialog

import { createSlice } from '@reduxjs/toolkit'
import * as userManagementApi from '../../apiCalls/userManagement'

const userManagementSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    loadUsers(_, action) {
      return action.payload
    },
    updateUser(users, action) {
      return users.map((user) => {
        if (user.userId !== action.payload.userId) {
          return user
        }
        return {
          ...user,
          ...action.payload,
        }
      })
    },
  },
})

export const { loadUsers, updateUser } = userManagementSlice.actions

export const loadUsersAsync = () => async (dispatch) => {
  return await userManagementApi
    .getUsers()
    .then((users) => {
      dispatch(loadUsers(users))
    })
    .catch((error) => {
      console.log(error)
      //dispatch to handle error needs to come in here
      throw error
    })
}

export const updateUserAsync = (user) => async (dispatch) => {
  return await userManagementApi
    .updateUser(user)
    .then(dispatch(updateUser(user)))
    .catch((error) => {
      console.log(error)
      //dispatch to handle error needs to come in here
      throw error
    })
}

export const selectUsers = (state) => state.users

export const selectOrderedUsers = (state) => {
  if (state.users.length > 0) {
    var users = state.users.slice().sort((a, b) => {
      var x = a.displayName.toLowerCase()
      var y = b.displayName.toLowerCase()
      var xx = a.roleId !== null
      var yy = b.roleId !== null
      if ((xx && yy) || (!xx && !yy)) {
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
      }
      if (!xx && yy) {
        return 1
      }
      if (xx && !yy) {
        return -1
      }
      return 0
    })
    return users
  }
  return []
}

export default userManagementSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as propertyApi from '../../apiCalls/property'
import * as filters from '../../common/filters'

const initialState = {
  status: apiStatus.idle,
  error: null,
  properties: [],
}

const propertySlice = createSlice({
  name: 'properties',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadPropertiesSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, properties: payload }
    },
    updatePropertySuccess(state, { payload }) {
      let updatedProperties = state.properties.map((property) => {
        if (property.id != payload.id) {
          return property
        }
        return {
          ...property,
          ...payload,
        }
      })

      return {
        ...state,
        properties: updatedProperties,
        status: apiStatus.succeeded,
      }
    },
    addPropertySuccess(state, { payload }) {
      let updatedProperties = [...state.properties, payload]
      return {
        ...state,
        properties: updatedProperties,
        status: apiStatus.succeeded,
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadPropertiesSuccess,
  updatePropertySuccess,
  addPropertySuccess,
} = propertySlice.actions

export const loadPropertiesAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await propertyApi
    .getProperties()
    .then((properties) => {
      dispatch(loadPropertiesSuccess(properties))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const updatePropertyAsync = (property) => async (dispatch) => {
  dispatch(beginApiCall())
  return await propertyApi
    .updateProperty(property)
    .then(() => dispatch(updatePropertySuccess(property)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const addPropertyAsync = (property) => async (dispatch) => {
  dispatch(beginApiCall())
  return await propertyApi
    .addProperty(property)
    .then((propertyId) =>
      dispatch(addPropertySuccess({ id: propertyId, ...property }))
    )
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError(error.message))
      throw error
    })
}

export const selectProperties = (state) => state.properties

export const selectOrderedProperties = (state) => {
  var { properties } = selectProperties(state)
  if (properties.length > 0) {
    return {
      ...selectProperties(state),
      properties: properties.slice().sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
    }
  }
  return selectProperties(state)
}

export const selectPropertiesActiveFilter = (active) => (state) => {
  var { properties } = selectProperties(state)
  return {
    ...selectProperties(state),
    properties: filters.ActiveFilter(properties, active).sort((a, b) => {
      var x = a.name.toLowerCase()
      var y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    }),
  }
}

export const selectActiveUserProperties = (
  userPropertyList,
  selectedPropertyId = null
) => (state) => {
  var { properties } = selectProperties(state)
  return {
    ...selectProperties(state),
    properties: properties
      .filter(
        (i) =>
          (i.isActive && userPropertyList.some((p) => p.id === i.id)) ||
          i.id == selectedPropertyId
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default propertySlice.reducer

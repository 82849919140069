import React, { useEffect, useState } from 'react'
import {
  apiStatusEnum as apiStatus,
  addLocationDialogEnum as LocationDialog,
} from '../../../common/enums'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import LocationAccordionHeader from '../molecules/LocationAccordionHeader'
import LocationsTable from '../molecules/LocationsTable'
import DynamicAccordion from '../molecules/DynamicAccordion'
import AddLocationsDialog from '../molecules/AddLocationsDialog'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadPropertiesAsync,
  selectPropertiesActiveFilter,
  updatePropertyAsync,
  addPropertyAsync,
  selectProperties,
} from '../../slices/propertySlice'
import {
  selectSectionsWithFilter,
  loadSectionsAsync,
  updateSectionAsync,
  addSectionAsync,
  selectSections,
} from '../../slices/sectionSlice'
import {
  selectBuildingsWithFilter,
  loadBuildingsAsync,
  updateBuildingAsync,
  addBuildingAsync,
  selectBuildings,
} from '../../slices/buildingSlice'
import {
  selectLocationsWithFilter,
  loadLocationsAsync,
  updateLocationAsync,
  addLocationAsync,
  selectLocations,
} from '../../slices/locationSlice'
import {
  selectApartmentsWithFilter,
  loadApartmentsAsync,
  updateApartmentAsync,
  addApartmentAsync,
  selectApartments,
} from '../../slices/apartmentSlice'
import {
  selectFloorsWithFilter,
  loadFloorsAsync,
  updateFloorAsync,
  addFloorAsync,
  selectFloors,
} from '../../slices/floorSlice'
import {
  selectStoresWithFilter,
  loadStoresAsync,
  updateStoreAsync,
  addStoreAsync,
  selectStores,
} from '../../slices/storeSlice'
import {
  selectParkingSpotsWithFilter,
  loadParkingSpotsAsync,
  updateParkingSpotAsync,
  addParkingSpotAsync,
  selectParkingSpots,
} from '../../slices/parkingSpotSlice'
import {
  selectPrimaryThemeColor,
  selectSecondaryThemeColor,
  selectTertiaryThemeColor,
} from '../../../admin/slices/themeColorSlice'
import { spinnerSize } from '../../../common/constants'

const LocationSections = () => {
  const useStyles = makeStyles({
    sectionHeader: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
    },
    addButton: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
    activateButton: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      color: 'white',
    },
  })
  const classes = useStyles()

  const [initialDataLoaded, setInitialDataLoaded] = useState(false)
  const [propertyActiveFilter, setPropertyActiveFilter] = useState(true)
  const [sectionActiveFilter, setSectionActiveFilter] = useState(true)
  const [buildingActiveFilter, setBuildingActiveFilter] = useState(true)
  const [locationActiveFilter, setLocationActiveFilter] = useState(true)
  const [apartmentActiveFilter, setApartmentActiveFilter] = useState(true)
  const [floorActiveFilter, setFloorActiveFilter] = useState(true)
  const [storeActiveFilter, setStoreActiveFilter] = useState(true)
  const [parkingSpotActiveFilter, setParkingSpotActiveFilter] = useState(true)

  const [propertyFilterValue, setPropertyFilterValue] = useState({})
  const [sectionFilterValue, setSectionFilterValue] = useState({})
  const clearSectionFilter = () => setSectionFilterValue({})
  const [buildingFilterValue, setBuildingFilterValue] = useState({})
  const clearBuildingFilter = () => setBuildingFilterValue({})
  const [locationFilterValue, setLocationFilterValue] = useState({})
  const clearLocationFilter = () => setLocationFilterValue({})

  const [
    itemClickedForActivateOrDeactive,
    setItemClickedForActivateOrDeactive,
  ] = useState(-1)

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const dispatch = useDispatch()
  const { properties } = useSelector(selectProperties)
  const { sections } = useSelector(selectSections)
  const { buildings } = useSelector(selectBuildings)
  const { locations } = useSelector(selectLocations)
  const { apartments } = useSelector(selectApartments)
  const { floors } = useSelector(selectFloors)
  const { stores } = useSelector(selectStores)
  const { parkingSpots } = useSelector(selectParkingSpots)

  const propertySelector = useSelector(
    selectPropertiesActiveFilter(propertyActiveFilter)
  )
  const sectionsSelector = useSelector(
    selectSectionsWithFilter(sectionActiveFilter, propertyFilterValue.id)
  )
  const buildingsSelector = useSelector(
    selectBuildingsWithFilter(buildingActiveFilter, sectionFilterValue.id)
  )
  const locationsSelector = useSelector(
    selectLocationsWithFilter(locationActiveFilter, buildingFilterValue.id)
  )
  const apartmentsSelector = useSelector(
    selectApartmentsWithFilter(apartmentActiveFilter, locationFilterValue.id)
  )
  const floorsSelector = useSelector(
    selectFloorsWithFilter(floorActiveFilter, locationFilterValue.id)
  )
  const storesSelector = useSelector(
    selectStoresWithFilter(storeActiveFilter, locationFilterValue.id)
  )
  const parkingSpotsSelector = useSelector(
    selectParkingSpotsWithFilter(
      parkingSpotActiveFilter,
      locationFilterValue.id
    )
  )

  const [sectionDisabled, setSectionDisabled] = useState(true)
  const [buildingDisabled, setBuildingDisabled] = useState(true)
  const [locationDisabled, setLocationDisabled] = useState(true)

  const [addLocationDialogOpen, setAddLocationsDialogOpen] = useState('')

  const openAddPropertyDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.property)
  }
  const openAddSectionDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.section)
  }
  const openAddBuildingDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.building)
  }
  const openAddLocationDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.location)
  }
  const openAddFloorDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.floor)
  }
  const openAddApartmentDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.apartment)
  }
  const openAddStoreDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.store)
  }
  const openAddParkingSpotDialog = (event) => {
    event.stopPropagation()
    setAddLocationsDialogOpen(LocationDialog.parkingSpot)
  }
  const closeDialog = () => {
    setAddLocationsDialogOpen('')
  }

  const addProperty = (property) => {
    dispatch(addPropertyAsync(property)).then(() => closeDialog())
  }
  const addSection = (partialLocation) => {
    let newSection = { ...partialLocation, propertyId: propertyFilterValue.id }
    dispatch(addSectionAsync(newSection)).then(() => closeDialog())
  }
  const addBuilding = (partialBuilding) => {
    let newBuilding = { ...partialBuilding, sectionId: sectionFilterValue.id }
    dispatch(addBuildingAsync(newBuilding)).then(() => closeDialog())
  }
  const addLocation = (partialLocation) => {
    let newLocation = { ...partialLocation, buildingId: buildingFilterValue.id }
    dispatch(addLocationAsync(newLocation)).then(() => closeDialog())
  }
  const addApartment = (partialApartment) => {
    let newApartment = {
      ...partialApartment,
      locationId: locationFilterValue.id,
    }
    dispatch(addApartmentAsync(newApartment)).then(() => closeDialog())
  }
  const addFloor = (partialFloor) => {
    let newFloor = { ...partialFloor, locationId: locationFilterValue.id }
    dispatch(addFloorAsync(newFloor)).then(() => closeDialog())
  }
  const addStore = (partialStore) => {
    let newStore = { ...partialStore, locationId: locationFilterValue.id }
    dispatch(addStoreAsync(newStore)).then(() => closeDialog())
  }
  const addParkingSpot = (partialParkingSpot) => {
    let newParkingSpot = {
      ...partialParkingSpot,
      locationId: locationFilterValue.id,
    }
    dispatch(addParkingSpotAsync(newParkingSpot)).then(() => closeDialog())
  }

  const togglePropertyActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updatePropertyAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleSectionActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateSectionAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleBuildingActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateBuildingAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleLocationActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateLocationAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleApartmentActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateApartmentAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleFloorActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateFloorAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleStoreActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateStoreAsync({ ...item, isActive: !item.isActive }))
  }
  const toggleParkingSpotActiveInactive = (event, item) => {
    event.stopPropagation()
    setItemClickedForActivateOrDeactive(item.id)
    dispatch(updateParkingSpotAsync({ ...item, isActive: !item.isActive }))
  }
  const selectPropertyAsFilter = (item) => {
    setPropertyFilterValue(item)
    clearSectionFilter()
    clearBuildingFilter()
    clearLocationFilter()
    setSectionDisabled(false)
    setBuildingDisabled(true)
    setLocationDisabled(true)
    setExpanded('sectionPanel')
  }
  const selectSectionAsFilter = (item) => {
    setSectionFilterValue(item)
    clearBuildingFilter()
    clearLocationFilter()
    setBuildingDisabled(false)
    setLocationDisabled(true)
    setExpanded('buildingPanel')
  }
  const selectBuildingAsFilter = (item) => {
    setBuildingFilterValue(item)
    clearLocationFilter()
    setLocationDisabled(false)
    setExpanded('locationPanel')
  }
  const selectLocationAsFilter = (item) => {
    setLocationFilterValue(item)
    setExpanded('')
  }

  const toggleActiveViewForProperties = (event) => {
    event.stopPropagation()
    setPropertyActiveFilter(!propertyActiveFilter)
  }
  const toggleActiveViewForSections = (event) => {
    event.stopPropagation()
    setSectionActiveFilter(!sectionActiveFilter)
  }
  const toggleActiveViewForBuildings = (event) => {
    event.stopPropagation()
    setBuildingActiveFilter(!buildingActiveFilter)
  }
  const toggleActiveViewForLocations = (event) => {
    event.stopPropagation()
    setLocationActiveFilter(!locationActiveFilter)
  }
  const toggleActiveViewForApartments = (event) => {
    event.stopPropagation()
    setApartmentActiveFilter(!apartmentActiveFilter)
  }
  const toggleActiveViewForFloors = (event) => {
    event.stopPropagation()
    setFloorActiveFilter(!floorActiveFilter)
  }
  const toggleActiveViewForStores = (event) => {
    event.stopPropagation()
    setStoreActiveFilter(!storeActiveFilter)
  }
  const toggleActiveViewForParkingSpots = (event) => {
    event.stopPropagation()
    setParkingSpotActiveFilter(!parkingSpotActiveFilter)
  }

  useEffect(() => {
    if (propertySelector.status == apiStatus.idle) setInitialDataLoaded(false)
    dispatch(loadPropertiesAsync()).then(() => setInitialDataLoaded(true))
    if (sectionsSelector.status == apiStatus.idle) dispatch(loadSectionsAsync())
    if (buildingsSelector.status == apiStatus.idle)
      dispatch(loadBuildingsAsync())
    if (locationsSelector.status == apiStatus.idle)
      dispatch(loadLocationsAsync())
    if (floorsSelector.status == apiStatus.idle) dispatch(loadFloorsAsync())
    if (apartmentsSelector.status == apiStatus.idle)
      dispatch(loadApartmentsAsync())
    if (storesSelector.status == apiStatus.idle) dispatch(loadStoresAsync())
    if (parkingSpotsSelector.status == apiStatus.idle)
      dispatch(loadParkingSpotsAsync())
  }, [dispatch])

  const RenderDynamicAccordion = () => {
    switch (locationFilterValue.name) {
      case 'Apartment':
        return (
          <DynamicAccordion
            title={locationFilterValue.name}
            activeFilter={apartmentActiveFilter}
            toggleActiveFilter={toggleActiveViewForApartments}
            addFunction={openAddApartmentDialog}
            items={apartmentsSelector.apartments}
            toggleItemActiveInactive={toggleApartmentActiveInactive}
            rowBusy={{
              loading: apartmentsSelector.status == apiStatus.pending,
              item: itemClickedForActivateOrDeactive,
            }}
            classes={classes}
          />
        )
      case 'Floor':
        return (
          <DynamicAccordion
            title={locationFilterValue.name}
            activeFilter={floorActiveFilter}
            toggleActiveFilter={toggleActiveViewForFloors}
            addFunction={openAddFloorDialog}
            items={floorsSelector.floors}
            toggleItemActiveInactive={toggleFloorActiveInactive}
            rowBusy={{
              loading: floorsSelector.status == apiStatus.pending,
              item: itemClickedForActivateOrDeactive,
            }}
            classes={classes}
          />
        )
      case 'Store':
        return (
          <DynamicAccordion
            title={locationFilterValue.name}
            activeFilter={storeActiveFilter}
            toggleActiveFilter={toggleActiveViewForStores}
            addFunction={openAddStoreDialog}
            items={storesSelector.stores}
            toggleItemActiveInactive={toggleStoreActiveInactive}
            rowBusy={{
              loading: storesSelector.status == apiStatus.pending,
              item: itemClickedForActivateOrDeactive,
            }}
            classes={classes}
          />
        )
      case 'Parking Spot':
        return (
          <DynamicAccordion
            title={locationFilterValue.name}
            activeFilter={parkingSpotActiveFilter}
            toggleActiveFilter={toggleActiveViewForParkingSpots}
            addFunction={openAddParkingSpotDialog}
            items={parkingSpotsSelector.parkingSpots}
            toggleItemActiveInactive={toggleParkingSpotActiveInactive}
            rowBusy={{
              loading: parkingSpotsSelector.status == apiStatus.pending,
              item: itemClickedForActivateOrDeactive,
            }}
            classes={classes}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {initialDataLoaded ? (
        <>
          <Accordion
            expanded={expanded === 'propertyPanel'}
            onChange={handleChange('propertyPanel')}
          >
            <AccordionSummary
              id='propertyPanel'
              className={classes.sectionHeader}
            >
              <LocationAccordionHeader
                title='Property'
                filterValue={propertyFilterValue.name}
                showInactiveState={!propertyActiveFilter}
                showInactiveOnChange={toggleActiveViewForProperties}
                addFunction={openAddPropertyDialog}
                extClasses={classes}
              />
            </AccordionSummary>
            <AccordionDetails>
              <LocationsTable
                items={propertySelector.properties}
                buttonFunction={togglePropertyActiveInactive}
                rowFunction={selectPropertyAsFilter}
                rowBusy={{
                  loading: propertySelector.status == apiStatus.pending,
                  item: itemClickedForActivateOrDeactive,
                }}
                extClasses={classes}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'sectionPanel'}
            onChange={handleChange('sectionPanel')}
            disabled={sectionDisabled}
          >
            <AccordionSummary
              id='sectionPanel'
              className={classes.sectionHeader}
            >
              <LocationAccordionHeader
                title='Section'
                filterValue={sectionFilterValue.name}
                showInactiveState={!sectionActiveFilter}
                showInactiveOnChange={toggleActiveViewForSections}
                addFunction={openAddSectionDialog}
                extClasses={classes}
              />
            </AccordionSummary>
            <AccordionDetails>
              <LocationsTable
                items={sectionsSelector.sections}
                buttonFunction={toggleSectionActiveInactive}
                rowFunction={selectSectionAsFilter}
                rowBusy={{
                  loading: sectionsSelector.status == apiStatus.pending,
                  item: itemClickedForActivateOrDeactive,
                }}
                extClasses={classes}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'buildingPanel'}
            onChange={handleChange('buildingPanel')}
            disabled={buildingDisabled}
          >
            <AccordionSummary
              id='buildingPanel'
              className={classes.sectionHeader}
            >
              <LocationAccordionHeader
                title='Building'
                filterValue={buildingFilterValue.name}
                showInactiveState={!buildingActiveFilter}
                showInactiveOnChange={toggleActiveViewForBuildings}
                addFunction={openAddBuildingDialog}
                extClasses={classes}
              />
            </AccordionSummary>
            <AccordionDetails>
              <LocationsTable
                items={buildingsSelector.buildings}
                buttonFunction={toggleBuildingActiveInactive}
                rowFunction={selectBuildingAsFilter}
                rowBusy={{
                  loading: buildingsSelector.status == apiStatus.pending,
                  item: itemClickedForActivateOrDeactive,
                }}
                extClasses={classes}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === 'locationPanel'}
            onChange={handleChange('locationPanel')}
            disabled={locationDisabled}
          >
            <AccordionSummary
              id='locationPanel'
              className={classes.sectionHeader}
            >
              <LocationAccordionHeader
                title='Location'
                filterValue={locationFilterValue.name}
                showInactiveState={!locationActiveFilter}
                showInactiveOnChange={toggleActiveViewForLocations}
                addFunction={openAddLocationDialog}
                extClasses={classes}
              />
            </AccordionSummary>
            <AccordionDetails>
              <LocationsTable
                items={locationsSelector.locations}
                buttonFunction={toggleLocationActiveInactive}
                rowFunction={selectLocationAsFilter}
                rowBusy={{
                  loading: locationsSelector.status == apiStatus.pending,
                  item: itemClickedForActivateOrDeactive,
                }}
                extClasses={classes}
              />
            </AccordionDetails>
          </Accordion>
          <RenderDynamicAccordion />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.property}
            title='Add Property'
            handleClose={closeDialog}
            save={addProperty}
            loading={propertySelector.status == apiStatus.pending}
            namesForValidation={properties.map((p) => {
              return p.name
            })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.section}
            title='Add Section'
            handleClose={closeDialog}
            save={addSection}
            loading={sectionsSelector.status == apiStatus.pending}
            namesForValidation={sections
              .filter((s) => s.propertyId == propertyFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.building}
            title='Add Building'
            handleClose={closeDialog}
            save={addBuilding}
            loading={buildingsSelector.status == apiStatus.pending}
            namesForValidation={buildings
              .filter((s) => s.sectionId == sectionFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.location}
            title='Add Location'
            handleClose={closeDialog}
            save={addLocation}
            loading={locationsSelector.status == apiStatus.pending}
            namesForValidation={locations
              .filter((s) => s.buildingId == buildingFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.apartment}
            title='Add Apartment'
            handleClose={closeDialog}
            save={addApartment}
            loading={apartmentsSelector.status == apiStatus.pending}
            namesForValidation={apartments
              .filter((s) => s.locationId == locationFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.floor}
            title='Add Floor'
            handleClose={closeDialog}
            save={addFloor}
            loading={floorsSelector.status == apiStatus.pending}
            namesForValidation={floors
              .filter((s) => s.locationId == locationFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.store}
            title='Add Store'
            handleClose={closeDialog}
            save={addStore}
            loading={storesSelector.status == apiStatus.pending}
            namesForValidation={stores
              .filter((s) => s.locationId == locationFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
          <AddLocationsDialog
            open={addLocationDialogOpen == LocationDialog.parkingSpot}
            title='Add Parking Spot'
            handleClose={closeDialog}
            save={addParkingSpot}
            loading={parkingSpotsSelector.status == apiStatus.pending}
            namesForValidation={parkingSpots
              .filter((s) => s.locationId == locationFilterValue.id)
              .map((s) => {
                return s.name
              })}
          />
        </>
      ) : (
        <CircularProgress
          size={spinnerSize}
          style={{
            position: 'fixed',
            left: window.innerWidth / 2 - spinnerSize / 2,
            top: window.innerHeight / 2 - spinnerSize / 2,
          }}
        />
      )}
    </>
  )
}

export default LocationSections

import React, { useEffect } from 'react'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { loginRequest } from '../../auth/authConfig'

export const SignInButton = () => {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (!isAuthenticated) {
      sessionStorage.removeItem('CHIEFToken')
      sessionStorage.removeItem('CHIEFUser')

      instance.loginRedirect(loginRequest).catch((e) => {
        if (process.env.NODE_ENV === 'development') console.log(e)
      })
    }
  }, [isAuthenticated])

  return <></>
}

export default SignInButton

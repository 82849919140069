import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'

import { makeStyles, TextField, Typography } from '@material-ui/core'
import FormInput from '../atoms/FormInput'
import FormEventTypeSelect from '../atoms/FormEventTypeSelect'
import FormSelect from '../atoms/FormSelect'
import FormDatePicker from '../atoms/FormDatePicker'
import FormTimePicker from '../atoms/FormTimePicker'
import FormSectionMultiSelect from '../atoms/FormSectionMultiSelect'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
  bottomText: {
    fontStyle: 'italic',
    textAlign: 'center',
    color: 'grey',
  },
})

const AccidentEventDetailsFields = ({
  defaultValues,
  eventTypes,
  eventTypeChangeHandler,
  eventConditions,
  callIncidentNumbers,
  selectedIncidentTypes,
  updateIncidentTypes,
  incidentTypes,
  callControlNumber,
  onIncidentControlNumberChange,
  disableEventConditionField = false,
}) => {
  const classes = useStyles()
  return (
    <>
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={2}>
          <FormEventTypeSelect
            name='event.type'
            label='Event Type'
            options={eventTypes}
            required={true}
            errorMessage={'Event Type field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.type || ''}
            changeHandler={eventTypeChangeHandler}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormInput
            name='event.preparedBy'
            label='Prepared By'
            required={true}
            errorMessage='Prepared By field is required.'
            rules={{ required: true }}
            defaultValue={defaultValues.preparedBy || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3} />
        <Grid item xs={2}>
          <FormSelect
            name='event.eventConditionId'
            label='Event Condition'
            options={eventConditions}
            required={true}
            errorMessage={'Event Condition field is required'}
            rules={{ required: true }}
            defaultValue={defaultValues.eventConditionId || ''}
            disabled={disableEventConditionField}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormDatePicker
            name='event.dateReported'
            label='Date Reported'
            required={true}
            errorMessage='A valid date is required'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeReported'
            label='Time of Incident'
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormDatePicker
            name='event.dateOfOccurrence'
            label='Date of Occurrence'
            required={true}
            errorMessage='A valid date is required'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeOfOccurrence'
            label='Time of Occurrence'
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSectionMultiSelect
            label='Type of Accident(s)'
            incidentTypes={incidentTypes}
            updateIncidentTypes={updateIncidentTypes}
            selectedSections={
              selectedIncidentTypes !== ''
                ? selectedIncidentTypes.split(',')
                : []
            }
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <TextField
            id='event.callLogControlNumber'
            label='Call Control Number'
            disabled={true}
            value={callControlNumber}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormSelect
            name='event.incidentControlNumber'
            label='Incident Control Number'
            options={callIncidentNumbers}
            required={true}
            errorMessage={'Incident Control Number field is required'}
            rules={{ required: true }}
            defaultValue={undefined}
            onChangeFunction={onIncidentControlNumberChange}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.timeJobReceived'
            label='Time Job Received'
            required={true}
            errorMessage='A valid time is required.'
            rules={{
              required: true,
              validate: (value) => !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.bottomText}>
            Do not prepare for vehicle accidents / employees. Complete all
            sections.
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

AccidentEventDetailsFields.propTypes = {
  defaultValues: PropTypes.shape({
    type: PropTypes.number.isRequired,
    preparedBy: PropTypes.string,
    condition: PropTypes.any,
    dateReported: PropTypes.instanceOf(Date),
    timeReported: PropTypes.instanceOf(Date),
    dateOfOccurrence: PropTypes.instanceOf(Date),
    timeOfOccurrence: PropTypes.instanceOf(Date),
    timeJobReceived: PropTypes.instanceOf(Date),
    callControlNumber: PropTypes.any,
    callIncidentNumber: PropTypes.any,
  }),
  eventTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  eventTypeChangeHandler: PropTypes.func.isRequired,
  eventConditions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  accidentTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  setAccidentTypes: PropTypes.func.isRequired,
  callControlNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  callIncidentNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  disableEventConditionField: PropTypes.bool,
}

export default AccidentEventDetailsFields

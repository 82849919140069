import React from 'react'
import { useSelector } from 'react-redux'
import PageHeader from '../../../common/components/organisms/PageHeader'
import ImportTenant from '../organisms/ImportTenants'
import { selectCurrentUserRole } from '../../../admin/slices/currentUserSlice'
import { roles } from '../../../common/constants'
import NoAccess from '../../../common/components/templates/NoAccess'

const ManageTenants = () => {
  const isAdmin = useSelector(selectCurrentUserRole()) === roles.admin
  return (
    <>
      <PageHeader pageName='Import Tenants' />
      {isAdmin ? <ImportTenant /> : <NoAccess reason='(Role Access)' />}
    </>
  )
}

export default ManageTenants

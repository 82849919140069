import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormTable from './FormTable'
import { Button, makeStyles } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const columns = [
  { header: 'First Name', accessor: 'firstName' },
  { header: 'Last Name', accessor: 'lastName' },
  { header: 'Injured Person / Witness / Owner', accessor: 'personType' },
  { header: 'Is Tenant', accessor: 'isTenant' },
  { header: 'Building', accessor: 'building' },
  { header: 'Apartment', accessor: 'apartment' },
]

const PeopleInvolvedFields = ({ openDialog, list = [] }) => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader sectionName='People Involved' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={12}>
          <FormTable
            name='peopleInvolved.list'
            columns={columns}
            data={list}
            handleOpen={openDialog}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => openDialog()} startIcon={<AddCircleIcon />}>
            Add a person
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

PeopleInvolvedFields.propTypes = {
  openDialog: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
}

export default PeopleInvolvedFields

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormInput from '../atoms/FormInput'
import FormTimePicker from '../atoms/FormTimePicker'
import FormSelect from '../atoms/FormSelect'
import FormCheckbox from '../atoms/FormCheckbox'

const fireClasses = [
  { id: 1, name: 'Suspicious' },
  { id: 2, name: 'Not Suspicious' },
  { id: 3, name: 'Under Investigation' },
  { id: 4, name: 'False Alarm' },
]

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const FireFields = () => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader sectionName='Fire' />
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={3}>
          <FormInput
            name='event.fire.fireDepartmentNotifiedBy'
            required={true}
            label='Fire Dept. Notified By'
            errorMessage='Fire Dept. Notified By is required.'
            rules={{ required: true }}
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.fire.fireDepartmentNotifiedTime'
            label='Time F.D. Notified'
            required={true}
            errorMessage='A valid time is required'
            rules={{
              required: true,
              validate: (value) => !value || !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.fire.fireDepartmentArrivedTime'
            label='Time F.D. Arrived'
            errorMessage='A valid time is required'
            rules={{
              validate: (value) => !value || !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={2}>
          <FormTimePicker
            name='event.fire.fireDepartmentDepartedTime'
            label='Time F.D. Departed'
            errorMessage='A valid time is required'
            rules={{
              validate: (value) => !value || !isNaN(Date.parse(value)),
            }}
            defaultValue={null}
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name='event.fire.fireTypeId'
            label='Class of Fire'
            options={fireClasses}
            defaultValue={null}
            required={true}
            rules={{ required: true }}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.fire.respondingFireDepartmentCompany'
            label='Responding F.D. Company'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.fire.discoveryMadeBy'
            label='Who Discovered Fire'
            defaultValue={''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.fire.bodilyInjuries'
            label='Bodily Injuries'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={3}>
          <FormCheckbox
            name='event.fire.housingCompanyPropertyDamaged'
            label='Housing Company Property Damaged'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={3}>
          <FormCheckbox
            name='event.fire.personalPropertyDamaged'
            label='Personal Property Damaged'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={3}>
          <FormCheckbox
            name='event.fire.fireMarshalOnScene'
            label='Fire Marshal on Scene'
            defaultValue={false}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default FireFields

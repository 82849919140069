import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
} from '@material-ui/core'

const ErrorDialog = ({ open, handleClose, message }) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'sm'}>
      <DialogTitle>Error</DialogTitle>
      <DialogContent>
        <Typography>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Grid container justify='flex-end'>
          <Grid item xs={2}>
            <Button variant='contained' onClick={handleClose} fullWidth={true}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  message: PropTypes.string,
}

export default ErrorDialog

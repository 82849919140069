import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormTable from './FormTable'
import { Button, makeStyles } from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'

const columns = [
  {
    header: 'Item Name',
    accessor: 'item',
  },
  {
    header: 'Quantity',
    accessor: 'quantity',
  },
  {
    header: 'Type',
    accessor: 'issueType',
  },
  {
    header: 'Use',
    accessor: 'useType',
  },
  {
    header: 'Estimated Value',
    accessor: 'estimatedValue',
  },
]

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const PropertyFields = ({ openDialog, list = [] }) => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader sectionName='Property' />
      <Grid container spacing={0} className={classes.grid}>
        <Grid item xs={12}>
          <FormTable
            name={'event.belongings'}
            columns={columns}
            data={list}
            handleOpen={openDialog}
          />
        </Grid>
        <Grid item>
          <Button onClick={() => openDialog()} startIcon={<AddCircleIcon />}>
            Add an item
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

PropertyFields.propTypes = {
  openDialog: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      itemName: PropTypes.string,
      quantity: PropTypes.string,
      issueType: PropTypes.number,
      useType: PropTypes.number,
      description: PropTypes.string,
      estimatedValue: PropTypes.string,
    })
  ).isRequired,
}

export default PropertyFields

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormInput from '../atoms/FormInput'
import FormCheckbox from '../atoms/FormCheckbox'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const MoveInOutFields = ({ defaultValues = {} }) => {
  const classes = useStyles()

  return (
    <>
      <SectionHeader sectionName='Move In/Out' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={3}>
          <FormInput
            name='event.move.tenantName'
            label='Tenant Name'
            required={true}
            rules={{
              required: true,
            }}
            errorMessage='Tenant Name field is required'
            // defaultValue={defaultValues.tenantName || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <FormInput
            name='event.move.address'
            label='Address'
            required={true}
            rules={{
              required: true,
            }}
            errorMessage='Address field is required'
            // defaultValue={defaultValues.tenantAddress || ''}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={2}>
          <FormCheckbox
            name='event.move.apartmentIsEmpty'
            label='Apartment is Empty'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={2}>
          <FormCheckbox
            name='event.move.keysTurnedIn'
            label='Keys Turned In'
            defaultValue={false}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name='event.move.newAddress'
            label="Tenant's New Address (if possible)"
            // defaultValue={defaultValues.tenantNewAddress || ''}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <FormInput
            name='event.move.movingCompanyName'
            label='Moving Company Name'
            // defaultValue={defaultValues.movingCompanyName || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={6}>
          <FormInput
            name='event.move.movingComapnyAddress'
            label='Moving Company Address (include City, State, Zip)'
            // defaultValue={defaultValues.movingCompanyAddress || ''}
          />
        </Grid>
        <Grid item xs={2} />
        <Grid item xs={3}>
          <FormInput
            name='event.move.driversName'
            label='Driver Name'
            // defaultValue={defaultValues.movingCompanyDriverName || ''}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormInput
            name='event.move.vehicleLicenseNumber'
            label='Vehicle License No.'
            // defaultValue={
            // defaultValues.movingCompanyDriverVehicleLicenseNumber || ''
            // }
          />
        </Grid>
      </Grid>
    </>
  )
}

MoveInOutFields.propTypes = {
  defaultValues: PropTypes.shape({
    tenantName: PropTypes.string.isRequired,
    tenantAddress: PropTypes.string,
    apartmentEmpty: PropTypes.bool,
    keysTurnedIn: PropTypes.bool,
    tenantNewAddress: PropTypes.string,
    movingCompanyName: PropTypes.string,
    movingCompanyAddress: PropTypes.string,
    movingCompanyDriverName: PropTypes.string,
    movingCompanyDriverVehicleLicenseNumber: PropTypes.string,
  }),
}

export default MoveInOutFields

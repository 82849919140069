import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import { makeStyles, Typography, Grid } from '@material-ui/core'
import SectionHeader from '../../../common/components/molecules/SectionHeader'
import FormSelect from '../atoms/FormSelect'
import FormInput from '../atoms/FormInput'
import FormMaskedInput from '../atoms/FormMaskedInput'
import FormCheckbox from '../atoms/FormCheckbox'

const useStyles = makeStyles({
  grid: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
    width: '95%',
  },
})

const ContributingCircumstancesFields = ({
  defaultValues = {},
  surfaceConditions,
  defects,
}) => {
  const classes = useStyles()
  const { watch } = useFormContext()

  const watchSurfaceCondition = watch('event.circumstance.surfaceConditionId')
  const watchDefect = watch('event.circumstance.defectId')
  const watchDefectManMade = watch('event.circumstance.defectIsManMade')
  return (
    <>
      <SectionHeader sectionName='Contributing Circumstances' />
      <Grid
        container
        spacing={0}
        className={classes.grid}
        direction='row'
        alignItems='center'
      >
        <Grid item xs={3}>
          <FormSelect
            name='event.circumstance.surfaceConditionId'
            label='Roadway, Sidewalk, Stairway or Floor Condition'
            options={surfaceConditions}
            defaultValue={defaultValues.surfaceConditionId || null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          {watchSurfaceCondition === 6 && (
            <FormInput
              name='event.circumstance.specifySurfaceCondition'
              label='Specify'
              required={true}
              errorMessage='Specify field is required'
              rules={{ required: true }}
              defaultValue={defaultValues.specifySurfaceCondition || ''}
            />
          )}
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={4}>
          {watchSurfaceCondition > 3 && (
            <FormInput
              name='event.circumstance.sidewalkCondition'
              label='Condition of sidewalk at place of occurrence'
              required={true}
              errorMessage='Condition of Sidewalk field is required'
              rules={{ required: true }}
              defaultValue={defaultValues.sidewalkCondition || ''}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={0} direction='row' alignItems='center'>
            <Grid item xs={2}>
              <Typography>Path</Typography>
            </Grid>
            <Grid item xs={2}>
              <FormMaskedInput
                name='event.circumstance.pathClearedWidth'
                label=' '
                defaultValue={defaultValues.pathClearedWidth || ''}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography>Feet Wide</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={3}>
          <FormSelect
            name='event.circumstance.sideWalkClearStatusId'
            label='Entire Sidewalk Clear/Unclear'
            options={[
              { id: 1, name: 'Clear' },
              { id: 2, name: 'Unclear' },
            ]}
            defaultValue={defaultValues.sideWalkClearStatusId || null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <FormCheckbox
            name='event.circumstance.injuredPersonFellInClearedArea'
            label='Injured Person Fell in Cleared Area'
            defaultValue={defaultValues.injuredPersonFellInClearedArea || false}
          />
        </Grid>
        <Grid item xs={3}>
          <FormSelect
            name='event.circumstance.lightingStatusId'
            label='If at night, was area lit / dark'
            options={[
              { id: 1, name: 'Lit' },
              { id: 2, name: 'Dark' },
            ]}
            defaultValue={defaultValues.lightingStatusId || null}
          />
        </Grid>
        <Grid item xs={9} />
        <Grid item xs={3}>
          <FormSelect
            name='event.circumstance.defectId'
            label='Defects in Roadway, Sidewalk, Stairway or Floor'
            options={defects}
            defaultValue={defaultValues.defectId || null}
          />
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={3}>
          {watchDefect === 7 && (
            <FormInput
              name='event.circumstance.specifyDefects'
              label='Specify'
              required={true}
              errorMessage='Specify field is required'
              rules={{ required: true }}
              defaultValue={defaultValues.specifyDefects || ''}
            />
          )}
        </Grid>
        <Grid item xs={1} />
        {watchDefect != null ? (
          <Grid item xs={4}>
            <Grid container spacing={0} direction='row' alignItems='center'>
              <Grid item xs={3}>
                Estimated:
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormInput
                  name='event.circumstance.defectWidth'
                  label='Width'
                  required={true}
                  errorMessage='Width field is required'
                  rules={{ required: true }}
                  defaultValue={defaultValues.defectWidth || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormInput
                  name='event.circumstance.defectLength'
                  label='Length'
                  required={true}
                  errorMessage='Length field is required'
                  rules={{ required: true }}
                  defaultValue={defaultValues.defectLength || ''}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={2}>
                <FormInput
                  name='event.circumstance.defectDepth'
                  label='Depth'
                  required={true}
                  errorMessage='Depth field is required'
                  rules={{ required: true }}
                  defaultValue={defaultValues.defectDepth || ''}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={4} />
        )}
        <Grid item xs={4}>
          <FormCheckbox
            name='event.circumstance.defectIsManMade'
            label='Defect Appears to be Man-Made'
            defaultValue={defaultValues.defectIsManMade || false}
          />
        </Grid>
        <Grid item xs={8}>
          {watchDefectManMade === true && (
            <FormInput
              name='event.circumstance.defectIsManMadeExplanation'
              label='Explain (construction cut in roadway / sidewalk)'
              required={true}
              errorMessage='Explanation field is required'
              rules={{ required: true }}
              defaultValue={defaultValues.defectIsManMadeExplanation || ''}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

ContributingCircumstancesFields.propTypes = {
  defaultValues: PropTypes.shape(),
  surfaceConditions: PropTypes.array.isRequired,
  defects: PropTypes.array.isRequired,
}

export default ContributingCircumstancesFields

import React from 'react'
import ProfileIcon from '../molecules/ProfileIcon'
import { useMsal } from '@azure/msal-react'
import { useSelector } from 'react-redux'
import { selectCurrentUserDisplayName } from '../../../admin/slices/currentUserSlice'

const ProfileMenu = () => {
  const { instance } = useMsal()
  const userNameText = useSelector(selectCurrentUserDisplayName()) || ''
  const logout = () => {
    instance.logout()
  }
  return <ProfileIcon userName={userNameText} logOutFunction={logout} />
}

export default ProfileMenu

import { createSlice } from '@reduxjs/toolkit'
import * as incidentClassificationCodesApi from '../../apiCalls/incidentClassificationCode'

const incidentClassificationCodesSlice = createSlice({
  name: 'incidentClassificationCodes',
  initialState: [],
  reducers: {
    loadIncidentClassificationCodes(_, action) {
      return action.payload
    },
  },
})

const {
  loadIncidentClassificationCodes,
} = incidentClassificationCodesSlice.actions

export const loadIncidentClassificationCodesAsync = () => async (dispatch) => {
  return await incidentClassificationCodesApi
    .getIncidentClassificationCodes()
    .then((incidentClassificationCodes) => {
      incidentClassificationCodes.map(
        (incidentClassificationCode) =>
          (incidentClassificationCode.name =
            incidentClassificationCode.code +
            ' ' +
            incidentClassificationCode.name)
      )
      dispatch(loadIncidentClassificationCodes(incidentClassificationCodes))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectIncidentClassificationCodes = (state) =>
  state.incidentClassificationCodes

export const selectOrderedIncidentClassificationCodes = (state) => {
  var incidentClassificationCodes = state.incidentClassificationCodes
    .slice()
    .sort((a, b) => {
      var x = a.name.toLowerCase()
      var y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })

  return incidentClassificationCodes
}

export default incidentClassificationCodesSlice.reducer

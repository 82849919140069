import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as authApi from '../../apiCalls/auth'

const initialState = {
  status: apiStatus.idle,
  error: null,
  currentUser: {},
}

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadCurrentUserSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, currentUser: payload }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadCurrentUserSuccess,
} = currentUserSlice.actions

export const loadCurrentUserAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await authApi
    .getUserProfile()
    .then((user) => {
      dispatch(loadCurrentUserSuccess(user))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectCurrentUser = () => (state) => state.currentUser

export const selectCurrentUserDisplayName = () => (state) =>
  state.currentUser.currentUser.displayName

export const selectCurrentUserRole = () => (state) =>
  state.currentUser.currentUser.role

export const selectCurrentUserProperties = () => (state) => {
  var userProperties = state.currentUser.currentUser.properties
    .slice()
    .sort((a, b) => {
      var x = a.name.toLowerCase()
      var y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    })

  return userProperties || null
}

export const selectIsACurrentUserProperty = (propertyId) => (state) => {
  if (propertyId !== null) {
    return (
      state.currentUser.currentUser.properties.find(
        (p) => p.id === propertyId
      ) !== undefined
    )
  }
  return true
}

export default currentUserSlice.reducer

import React from 'react'
import ProfileMenu from '../organisms/ProfileMenu'
import NavigationMenu from '../organisms/NavigationMenu'
import HeaderTitle from '../molecules/HeaderTitle'
import { Grid, makeStyles } from '@material-ui/core'
import { selectPrimaryThemeColor } from '../../../admin/slices/themeColorSlice'
import { selectHeaderImage } from '../../../admin/slices/themeImageSlice'
import { useSelector } from 'react-redux'

const Header = () => {
  const useStyles = makeStyles({
    header: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      padding: '1em',
      color: 'white',
    },
  })

  const classes = useStyles()

  return (
    <nav className={classes.header}>
      <Grid container direction='row' alignItems='center'>
        <Grid item xs={1}>
          <NavigationMenu />
        </Grid>
        <Grid item xs={8}>
          <HeaderTitle
            title={'CHIEF'}
            subtitle={'Call Log History, Incident Event & Fall System'}
            image={useSelector(selectHeaderImage())}
          />
        </Grid>
        <Grid item xs={3}>
          <Grid container justify='flex-end'>
            <ProfileMenu />
          </Grid>
        </Grid>
      </Grid>
    </nav>
  )
}

export default Header

import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'

const SelectField = ({
  label,
  options,
  value,
  onChange,
  required,
  invalid,
  errorMessage,
  onChangeFunction,
  disabled,
}) => (
  <Autocomplete
    options={options}
    getOptionLabel={(option) => option.name}
    onChange={async (_, data) => {
      onChange(data ? data.id : null)
      if (onChangeFunction) onChangeFunction(data)
    }}
    value={options.find((o) => o.id === value) || null}
    fullWidth={true}
    openOnFocus={true}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        error={invalid}
        helperText={invalid ? errorMessage : ' '}
        InputLabelProps={{
          className: required ? 'required-label' : '',
          required: required || false,
        }}
        disabled={disabled}
      />
    )}
    disabled={disabled}
  />
)

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired
  ),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  onChangeFunction: PropTypes.func,
  disabled: PropTypes.bool,
}

const FormSelect = ({
  name,
  label,
  options,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue = '',
  onChangeFunction,
  disabled = false,
}) => {
  const { control } = useFormContext()

  return (
    <>
      <Controller
        render={({ onChange, value }, { invalid }) => (
          <SelectField
            label={label}
            options={options}
            value={value}
            onChange={onChange}
            required={required}
            invalid={invalid}
            errorMessage={errorMessage}
            onChangeFunction={onChangeFunction}
            disabled={disabled}
          />
        )}
        name={name}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
      />
    </>
  )
}

FormSelect.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  onChangeFunction: PropTypes.func,
  disabled: PropTypes.bool,
}

export default FormSelect

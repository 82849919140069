import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import CancelIcon from '@material-ui/icons/Cancel'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import GetAppIcon from '@material-ui/icons/GetApp'
import { makeStyles, Popover, MenuItem } from '@material-ui/core'
import { useSelector } from 'react-redux'
import {
  selectPrimaryThemeColor,
  selectSecondaryThemeColor,
  selectTertiaryThemeColor,
} from '../../../admin/slices/themeColorSlice'
import { eventTypeEnum } from '../../../common/enums'
import { useHistory, withRouter } from 'react-router-dom'

const EventFormButtons = ({
  eventType,
  create,
  createOption2,
  showCreateButton,
  showCreateOption2Button,
  download,
  reset,
  save,
  showDownload = false,
  disableDownload = false,
}) => {
  const useStyles = makeStyles({
    grid: {
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      width: '95%',
    },
    createButton: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
    downloadButton: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      color: 'white',
    },
    cancelButton: { background: 'grey', color: 'white' },
    resetButton: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
    saveButton: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      color: 'white',
    },
  })

  const history = useHistory()

  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const clickHandler = (event) => {
    if (eventType == eventTypeEnum.incident) {
      create()
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  return (
    <>
      <Grid container spacing={5} className={classes.grid}>
        <Grid item xs={6}>
          <Grid container spacing={5} justify='flex-start'>
            <Grid item xs={4}>
              {(showCreateButton || showCreateOption2Button) && (
                <>
                  <Button
                    id='create'
                    className={classes.createButton}
                    variant='contained'
                    startIcon={<CreateNewFolderIcon />}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={clickHandler}
                  >
                    {eventType === eventTypeEnum.incident ? (
                      <>Create Accident</>
                    ) : (
                      <>Create Incident</>
                    )}
                  </Button>
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    elevation={2}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    {showCreateButton && (
                      <MenuItem onClick={create}>Incident</MenuItem>
                    )}
                    {showCreateOption2Button && (
                      <MenuItem onClick={createOption2}>
                        Unusual Incident
                      </MenuItem>
                    )}
                  </Popover>
                </>
              )}
            </Grid>
            <Grid item xs={4}>
              {showDownload && (
                <Button
                  className={classes.downloadButton}
                  variant='contained'
                  startIcon={<GetAppIcon />}
                  onClick={(e) => download(e)}
                  disabled={disableDownload}
                >
                  Download Report
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={10} justify='flex-end'>
            <Grid item xs={2}>
              <Button
                className={classes.cancelButton}
                variant='contained'
                startIcon={<CancelIcon />}
                onClick={() => history.push('/')}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                className={classes.resetButton}
                variant='contained'
                startIcon={<RotateLeftIcon />}
                onClick={() => reset()}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                id='save-Button'
                className={classes.saveButton}
                variant='contained'
                type='submit'
                startIcon={<SaveIcon />}
                onClick={() => save()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

EventFormButtons.propTypes = {
  eventType: PropTypes.number,
  create: PropTypes.func,
  download: PropTypes.func,
  reset: PropTypes.func,
  save: PropTypes.func,
  showDownload: PropTypes.bool,
  disableDownload: PropTypes.bool,
  createOption2: PropTypes.func,
  showCreateButton: PropTypes.bool,
  showCreateOption2Button: PropTypes.bool,
}

export default withRouter(EventFormButtons)

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { apiStatusEnum as apiStatus } from '../../../common/enums'

import {
  selectThemeColors,
  loadThemeColorsAsync,
  selectSecondaryThemeColor,
  selectTertiaryThemeColor,
  selectPrimaryThemeColor,
} from '../../../admin/slices/themeColorSlice'
import {
  selectThemeImages,
  loadThemeImagesAsync,
  selectLandingPageImage,
} from '../../../admin/slices/themeImageSlice'
import { Button, Grid, makeStyles, Typography } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import SearchIcon from '@material-ui/icons/Search'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import { useHistory } from 'react-router'
import { selectCurrentUserRole } from '../../../admin/slices/currentUserSlice'

const HomePage = () => {
  const themeColorSelector = useSelector(selectThemeColors())
  const themeImageSelector = useSelector(selectThemeImages())

  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)

  const userRole = useSelector(selectCurrentUserRole())

  useEffect(() => {
    if (themeColorSelector.status == apiStatus.idle)
      dispatch(loadThemeColorsAsync())
    if (themeImageSelector.status == apiStatus.idle)
      dispatch(loadThemeImagesAsync())
    if (
      themeColorSelector.status == apiStatus.succeeded &&
      themeImageSelector.status == apiStatus.succeeded
    )
      setLoaded(true)
  }, [themeColorSelector.status, themeImageSelector.status])

  const useStyles = makeStyles({
    grid: {
      alignContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      width: '50%',
    },
    gridItem: {
      alignContent: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      width: '300px',
    },
    recordEventBtn: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
      padding: '1em',
      textTransform: 'none',
    },
    searchEventsBtn: {
      backgroundColor: useSelector(selectTertiaryThemeColor()),
      padding: '1em',
      color: 'white',
      textTransform: 'none',
    },
    adminDivider: {
      borderBottom: '2px solid black',
      width: '300px',
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
      alignContent: 'center',
    },
    adminBtn: {
      backgroundColor: useSelector(selectPrimaryThemeColor()),
      padding: '1em',
      color: 'white',
      textTransform: 'none',
    },
  })

  const classes = useStyles()
  const history = useHistory()
  const headerImage = useSelector(selectLandingPageImage())
  const isAdminUser = userRole === 'Admin'

  return (
    <>
      {loaded && (
        <>
          <Grid
            container
            direction='column'
            alignItems='center'
            justify='center'
            className={classes.grid}
          >
            <Grid item xs={12}>
              <img src={headerImage} style={{ maxHeight: '300px' }} />
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Button
                variant='contained'
                className={classes.recordEventBtn}
                endIcon={<CreateIcon />}
                onClick={() => history.push('/recordEvent')}
                fullWidth={true}
              >
                Record New Event
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.gridItem}>
              <Button
                variant='contained'
                className={classes.searchEventsBtn}
                endIcon={<SearchIcon />}
                onClick={() => history.push('/searchEvents')}
                fullWidth={true}
              >
                Search Events
              </Button>
            </Grid>
            {isAdminUser && (
              <>
                <Grid item xs={12} className={classes.adminDivider}>
                  <Typography align='center'>
                    Admin <SupervisorAccountIcon />
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <Button
                    variant='contained'
                    className={classes.adminBtn}
                    fullWidth={true}
                    onClick={() => history.push('/manageLocations')}
                  >
                    Manage Locations
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <Button
                    variant='contained'
                    className={classes.adminBtn}
                    fullWidth={true}
                    onClick={() => history.push('/manageUsers')}
                  >
                    Manage Users
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <Button
                    variant='contained'
                    className={classes.adminBtn}
                    fullWidth={true}
                    onClick={() => history.push('/manageTenants')}
                  >
                    Import Tenant Data
                  </Button>
                </Grid>
                <Grid item xs={12} className={classes.gridItem}>
                  <Button
                    variant='contained'
                    className={classes.adminBtn}
                    fullWidth={true}
                    onClick={() => history.push('/manageTheme')}
                  >
                    Manage Theme
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  )
}

export default HomePage

import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  CircularProgress,
  makeStyles,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: 'auto',
    position: 'relative',
    height: '40px',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}))

const LocationsTable = ({
  items,
  buttonFunction,
  rowFunction,
  rowBusy,
  extClasses = useStyles(),
}) => {
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table size={'small'}>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id} hover onClick={() => rowFunction(item)}>
              <TableCell component='th' scope='row'>
                {item.name}
              </TableCell>
              <TableCell style={{ textAlign: 'right' }}>
                <div className={classes.wrapper}>
                  <Button
                    className={extClasses.activateButton}
                    color='default'
                    onClick={(event) => buttonFunction(event, item)}
                  >
                    {item.isActive ? (
                      <div>Deactivate</div>
                    ) : (
                      <div>Activate</div>
                    )}
                  </Button>
                  {rowBusy.item == item.id && rowBusy.loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

LocationsTable.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  buttonFunction: PropTypes.func.isRequired,
  rowFunction: PropTypes.func,
  rowBusy: PropTypes.shape({
    item: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }),
  extClasses: PropTypes.shape(),
}

export default LocationsTable

import React from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core'
import LocationAccordionHeader from './LocationAccordionHeader'
import LocationsTable from './LocationsTable'
import PropTypes from 'prop-types'

const DynamicAccordion = ({
  title,
  activeFilter,
  toggleActiveFilter,
  addFunction,
  items,
  toggleItemActiveInactive,
  rowBusy,
  classes,
}) => {
  return (
    <Accordion
      expanded={true}
      // onChange={handleChange('apartmentPanel')}
    >
      <AccordionSummary id='dynamicPanel' className={classes.sectionHeader}>
        <LocationAccordionHeader
          title={title}
          filterValue=''
          showInactiveState={!activeFilter}
          showInactiveOnChange={toggleActiveFilter}
          addFunction={addFunction}
          extClasses={classes}
        />
      </AccordionSummary>
      <AccordionDetails>
        <LocationsTable
          items={items}
          buttonFunction={toggleItemActiveInactive}
          rowBusy={rowBusy}
          extClasses={classes}
        />
      </AccordionDetails>
    </Accordion>
  )
}

DynamicAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  activeFilter: PropTypes.bool.isRequired,
  toggleActiveFilter: PropTypes.func.isRequired,
  addFunction: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  toggleItemActiveInactive: PropTypes.func.isRequired,
  rowBusy: PropTypes.shape({
    item: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
  }),
}

export default DynamicAccordion

import React from 'react'
import PropTypes from 'prop-types'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  Paper,
} from '@material-ui/core'
import { useFormContext, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectSecondaryThemeColor } from '../../../admin/slices/themeColorSlice'

const TableComponent = ({ columns, data = [], handleOpen }) => {
  const useStyles = makeStyles({
    tableHeader: {
      backgroundColor: useSelector(selectSecondaryThemeColor()),
    },
  })
  const classes = useStyles()
  return (
    <>
      <Paper>
        <TableContainer>
          <Table size={'small'}>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.accessor}>{column.header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, i) => (
                <TableRow
                  hover
                  key={i}
                  onClick={() => {
                    handleOpen(i, row)
                  }}
                >
                  {columns.map((column, j) => (
                    <TableCell key={j}>
                      {typeof row[column.accessor] === 'boolean'
                        ? row[column.accessor]
                          ? 'Yes'
                          : 'No'
                        : row[column.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

TableComponent.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ),
  data: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
}

const FormTable = ({ name, columns, data = [], handleOpen }) => {
  const { control } = useFormContext

  return (
    <Controller
      render={() => (
        <TableComponent columns={columns} data={data} handleOpen={handleOpen} />
      )}
      name={name}
      control={control}
      defaultValue={data}
    />
  )
}

FormTable.propTypes = {
  name: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string.isRequired,
      accessor: PropTypes.string.isRequired,
    })
  ).isRequired,
  data: PropTypes.array.isRequired,
  handleOpen: PropTypes.func.isRequired,
}

export default FormTable

import { createSlice } from '@reduxjs/toolkit'
import * as dispositionCodesApi from '../../apiCalls/dispositionCode'

const dispositionCodesSlice = createSlice({
  name: 'dispositionCodes',
  initialState: [],
  reducers: {
    loadDispositionCodes(_, action) {
      return action.payload
    },
  },
})

const { loadDispositionCodes } = dispositionCodesSlice.actions

export const loadDispositionCodesAsync = () => async (dispatch) => {
  return await dispositionCodesApi
    .getDispositionCodes()
    .then((dispositionCodes) => {
      dispositionCodes.map(
        (dispositionCode) =>
          (dispositionCode.name =
            dispositionCode.code + ' ' + dispositionCode.name)
      )
      dispatch(loadDispositionCodes(dispositionCodes))
    })
    .catch((error) => {
      console.log(error)
      //TODO: dispatch to handle error needs to come in here
      throw error
    })
}

export const selectDispositionCodes = (state) => state.dispositionCodes

export default dispositionCodesSlice.reducer

import { createSlice } from '@reduxjs/toolkit'
import { getCallTypes } from '../../apiCalls/callTypes'
import { apiStatusEnum as apiStatus } from '../../common/enums'

const initialState = {
  status: apiStatus.idle,
  error: null,
  callTypes: [],
}

const callTypesSlice = createSlice({
  name: 'callTypes',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadCallTypesSuccess(state, { payload }) {
      return {
        ...state,
        status: apiStatus.succeeded,
        callTypes: payload.map((c) => {
          return { id: c.id, name: c.labelName }
        }),
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadCallTypesSuccess,
} = callTypesSlice.actions

export const loadCallTypesAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await getCallTypes()
    .then((callTypes) => {
      dispatch(loadCallTypesSuccess(callTypes))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectCallTypes = (state) => state.callTypes

export const selectOrderedCallTypes = (state) => {
  var callTypes = {
    ...state.callTypes,
    callTypes: state.callTypes.callTypes.slice().sort((a, b) => {
      var x = a.name.toLowerCase()
      var y = b.name.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    }),
  }

  return callTypes
}

export default callTypesSlice.reducer

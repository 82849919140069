import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import * as storeApi from '../../apiCalls/store'

const initialState = {
  status: apiStatus.idle,
  error: null,
  stores: [],
}

const storeSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    beginApiCall(status) {
      return { ...status, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadStoresSuccess(state, { payload }) {
      return { ...state, status: apiStatus.succeeded, stores: payload }
    },
    updateStoreSuccess(state, { payload }) {
      let updatedStores = state.stores.map((store) => {
        if (store.id != payload.id) return store
        return {
          ...store,
          ...payload,
        }
      })

      return { ...state, status: apiStatus.succeeded, stores: updatedStores }
    },
    addStoreSuccess(state, { payload }) {
      let updatedStores = [...state.stores, payload]
      return { ...state, status: apiStatus.succeeded, stores: updatedStores }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadStoresSuccess,
  updateStoreSuccess,
  addStoreSuccess,
} = storeSlice.actions

export const loadStoresAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await storeApi
    .getStores()
    .then((stores) => {
      dispatch(loadStoresSuccess(stores))
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const updateStoreAsync = (store) => async (dispatch) => {
  dispatch(beginApiCall())
  return await storeApi
    .updateStore(store)
    .then(() => dispatch(updateStoreSuccess(store)))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const addStoreAsync = (store) => async (dispatch) => {
  dispatch(beginApiCall())
  return await storeApi
    .addStore(store)
    .then((storeId) => dispatch(addStoreSuccess({ id: storeId, ...store })))
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectStores = (state) => state.stores

export const selectStoresWithFilter = (
  active,
  locationId,
  selectedStoreId = null
) => (state) => {
  var storeSelector = selectStores(state)
  return {
    ...storeSelector,
    stores: storeSelector.stores
      .filter(
        (s) =>
          (s.isActive == active && s.locationId == locationId) ||
          (s.id == selectedStoreId && s.locationId == locationId)
      )
      .sort((a, b) => {
        var x = a.name.toLowerCase()
        var y = b.name.toLowerCase()
        if (x < y) {
          return -1
        }
        if (x > y) {
          return 1
        }
        return 0
      }),
  }
}

export default storeSlice.reducer

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'

import { Paper, Grid, IconButton, CircularProgress } from '@material-ui/core'
import {
  HighlightOff as DeleteIcon,
  GetApp as DownloadIcon,
} from '@material-ui/icons'

const CardsComponent = ({
  cardSize = 3,
  spacing = 2,
  data = [],
  deleteCard,
  downloadCard,
}) => {
  const [itemLoading, setItemLoading] = useState(-1)
  const downloadButtonDisabled = (itemId) =>
    itemId != itemLoading && itemLoading != -1

  return (
    <Grid container spacing={spacing} style={{ padding: '1em' }}>
      {data.map((item) => (
        <Grid key={item.id} item xs={cardSize}>
          <Paper>
            <Grid container>
              <Grid item xs={10}>
                <div
                  style={{
                    padding: '0.5em',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {item.name}
                </div>
              </Grid>
              <Grid item xs={2}>
                <IconButton
                  variant='contained'
                  onClick={() => deleteCard(item.attachmentId)}
                >
                  <DeleteIcon />
                </IconButton>
                {item.attachmentId == itemLoading ? (
                  <CircularProgress />
                ) : (
                  <IconButton
                    variant='contained'
                    onClick={() => {
                      setItemLoading(item.attachmentId),
                        downloadCard(item.attachmentId).then(() =>
                          setItemLoading(-1)
                        )
                    }}
                    disabled={downloadButtonDisabled(item.attachmentId)}
                  >
                    <DownloadIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  )
}

CardsComponent.propTypes = {
  cardSize: PropTypes.number,
  spacing: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  deleteCard: PropTypes.func.isRequired,
  downloadCard: PropTypes.func.isRequired,
}

const FormCards = ({ name, cardSize, data = [], deleteCard, downloadCard }) => {
  const { control } = useFormContext()
  return (
    <Controller
      render={() => (
        <CardsComponent
          cardSize={cardSize}
          data={data}
          deleteCard={deleteCard}
          downloadCard={downloadCard}
        />
      )}
      name={name}
      control={control}
      defaultValue={data}
    />
  )
}

FormCards.propTypes = {
  name: PropTypes.string.isRequired,
  cardSize: PropTypes.number,
  data: PropTypes.array,
  deleteCard: PropTypes.func.isRequired,
  downloadCard: PropTypes.func.isRequired,
}

export default FormCards

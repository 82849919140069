import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext, Controller } from 'react-hook-form'
import DatePickerField from './DatePickerField'

const FormDatePicker = ({
  name,
  label,
  required = false,
  errorMessage = ' ',
  rules,
  defaultValue,
  showDay = true,
}) => {
  const { control } = useFormContext()
  return (
    <Controller
      render={({ value, onChange }, { invalid }) => (
        <DatePickerField
          label={label}
          value={value}
          onChange={onChange}
          required={required}
          invalid={invalid}
          errorMessage={errorMessage}
          showDay={showDay}
        />
      )}
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
    />
  )
}

FormDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  showDay: PropTypes.bool,
}
export default FormDatePicker

import { createSlice } from '@reduxjs/toolkit'
import { apiStatusEnum as apiStatus } from '../../common/enums'
import {
  getUnreferencedCallLogControlNumbersForIncident,
  getUnreferencedCallLogControlNumbersForUnusualIncident,
} from '../../apiCalls/callLog'

const initialState = {
  status: apiStatus.idle,
  error: null,
  controlNumbers: [],
  controlNumbersForUnusualIncident: [],
}

const callLogControlNumbersSlice = createSlice({
  name: 'availableCallLogControlNumbers',
  initialState,
  reducers: {
    beginApiCall(state) {
      return { ...state, status: apiStatus.pending }
    },
    apiCallError(state, { payload }) {
      return { ...state, status: apiStatus.failed, error: payload }
    },
    loadControlNumbersSuccess(state, { payload }) {
      return {
        ...state,
        status: apiStatus.succeeded,
        controlNumbers: payload.controlNumbers.map((item) => {
          return {
            id: item.callLogControlNumber,
            name: item.callLogControlNumber,
            callLogId: item.callLogId,
          }
        }),
        controlNumbersForUnusualIncident: payload.unusualIncidentControlNumbers.map(
          (item) => {
            return {
              id: item.callLogControlNumber,
              name: item.callLogControlNumber,
              callLogId: item.callLogId,
            }
          }
        ),
      }
    },
  },
})

const {
  beginApiCall,
  apiCallError,
  loadControlNumbersSuccess,
} = callLogControlNumbersSlice.actions

export const loadControlNumbersAsync = () => async (dispatch) => {
  dispatch(beginApiCall())
  return await getUnreferencedCallLogControlNumbersForIncident('2020-01-01')
    .then((controlNumbers) => {
      getUnreferencedCallLogControlNumbersForUnusualIncident('2020-01-01').then(
        (unusualIncidentControlNumbers) => {
          dispatch(
            loadControlNumbersSuccess({
              controlNumbers,
              unusualIncidentControlNumbers,
            })
          )
        }
      )
    })
    .catch((error) => {
      console.log(error)
      dispatch(apiCallError())
      throw error
    })
}

export const selectControlNumbers = (state) =>
  state.availableCallLogControlNumbers

export default callLogControlNumbersSlice.reducer
